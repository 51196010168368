import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Limits } from 'src/app/shared/utils';

export class CreditOpinionExerciseDialogViewModel {
  private _form: FormGroup;

  readonly C_ID = 'id';
  readonly C_CREDIT_OPINION_ID = 'creditOpinionId';
  readonly C_EXERCISE = 'exercise';
  readonly C_BILLING = 'billing';
  readonly C_RESULT = 'result';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, []),
      [this.C_CREDIT_OPINION_ID]: new FormControl(null, [Validators.required]),
      [this.C_EXERCISE]: new FormControl(null, [Validators.required, Validators.min(1900), Validators.max(9999)]),
      [this.C_BILLING]: new FormControl(null, [Validators.required, Validators.min(Limits.minDecimal), Validators.max(Limits.maxDecimal)]),
      [this.C_RESULT]: new FormControl(null, [Validators.required, Validators.min(Limits.minDecimal), Validators.max(Limits.maxDecimal)])
    });
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get creditOpinionId(): number | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_ID)?.value;
  }
  set creditOpinionId(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_ID)?.setValue(value);
  }

  get exercise(): number | null | undefined {
    return this._form.get(this.C_EXERCISE)?.value;
  }
  set exercise(value: number | null | undefined) {
    this._form.get(this.C_EXERCISE)?.setValue(value);
  }

  get billing(): number | null | undefined {
    return this._form.get(this.C_BILLING)?.value;
  }
  set billing(value: number | null | undefined) {
    this._form.get(this.C_BILLING)?.setValue(value);
  }

  get result(): number | null | undefined {
    return this._form.get(this.C_RESULT)?.value;
  }
  set result(value: number | null | undefined) {
    this._form.get(this.C_RESULT)?.setValue(value);
  }
}
