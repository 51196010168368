import { Component } from '@angular/core';

@Component({
  selector: 'howden-report-issues',
  templateUrl: './report-issues.component.html',
  styleUrls: ['./report-issues.component.scss']
})
export class ReportIssuesComponent {
  get emailAddress() { return 'serviciodeinformacion@howdengroup.com'; }
}
