import { ICreditOpinionExercise } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionExerciseDialogViewModel } from '../dialogs/credit-opinion-exercise-dialog/models';
import { ICreditOpinionExerciseViewModel } from '../models';

export abstract class CreditOpinionExerciseMapper {
  static mapForEdit(source: ICreditOpinionExercise, target: CreditOpinionExerciseDialogViewModel): void {
    target.id = source.id;
    target.creditOpinionId = source.creditOpinionId;
    target.exercise = source.exercise;
    target.billing = source.billing;
    target.result = source.result;
  }

  static mapForUpdate(source: CreditOpinionExerciseDialogViewModel): ICreditOpinionExercise {
    return {
      id: source.id,
      creditOpinionId: source.creditOpinionId,
      exercise: source.exercise,
      billing: source.billing,
      result: source.result
    } as ICreditOpinionExercise;
  }

  static mapForSearch(source: Array<ICreditOpinionExercise>): Array<ICreditOpinionExerciseViewModel> {
    return source.map(t => ({
      id: t.id,
      creditOpinionId: t.creditOpinionId,
      exercise: t.exercise,
      billing: t.billing,
      result: t.result
    } as ICreditOpinionExerciseViewModel));
  }
}
