import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HowdenAlertService, HowdenTableComponent, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IDebtorCreditGranted, IDebtorCreditGrantedUpdated, IMonitoringUserReduced } from 'src/app/core/models/api/monitoring';
import { MonitoringContract } from 'src/app/core/services/api/monitoring';
import { LocalStorageContract } from 'src/app/core/services/storage';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { DebtorCreditGrantedEditDialogComponent } from '../../dialogs/pages/debtor-credit-granted-edit-dialog/debtor-credit-granted-edit-dialog.component';
import { DebtorCreditGrantedMapper } from '../../mappers';
import { DebtorCreditGrantedSearchViewModel, IDebtorCreditGrantedViewModel } from '../../models';

@Component({
  selector: 'howden-debtor-credit-granted-search',
  templateUrl: './debtor-credit-granted-search.component.html',
  styleUrls: ['./debtor-credit-granted-search.component.scss']
})
export class DebtorCreditGrantedSearchComponent implements OnInit, OnDestroy {
  model = new DebtorCreditGrantedSearchViewModel();
  lastMonitorableClient = null;

  @ViewChild('clientsTable') clientsTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private monitoringSrv: MonitoringContract,
    private uiBlockerSrv: UIBlockerContract,
    private alertSrv: HowdenAlertService,
    private localStorageSrv: LocalStorageContract
  ) { }

  ngOnInit(): void {
    this.monitoringSrv.getMonitorableClients().subscribe((data) => {
      this.model.dataClients = data;
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onUserSelectChange(monitorableClient: IMonitoringUserReduced) {
    if (this.model.userId as number > 0) { //Si ya se buscó otro usuario
      //Hay que recargar la lista de clientes activos para actualizar los que ya no tienen nada pendiente
      this.monitoringSrv.getMonitorableClients().subscribe((data) => {
        this.model.dataClients = data;
        //Seleccionamos la fila que acaban de marcar
        const selectedClient = this.model.dataClients.find(x => x.userId === monitorableClient.userId);
        this.clientsTable.setHighlightRow(selectedClient, undefined);
        //Cargamos la información del cliente seleccionado
        this.refreshMonitorableClient(monitorableClient);
        this.checkUpdatedDebtorsToNotify();
      });
    } else {
      this.refreshMonitorableClient(monitorableClient);
    }
  }


  onFilterChanges(): void {
    this.model.searchRequest.pageNumber = 0;
    this.model.updateServerSideFilters();
    this.refresh();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onNew(): void {
    const inputData = { id: null };
    this.showEditDialog(inputData);
  }

  onRowClicked(event: IDebtorCreditGrantedViewModel): void {
    const inputData = { id: event.id };
    this.showEditDialog(inputData);
  }

  checkUpdatedDebtorsToNotify() {
    if (this.localStorageSrv.hasDebtorCreditGrantedUpdateIds()) {
      this.uiBlockerSrv.block();
      const request = {
        debtorIds: this.localStorageSrv.getDebtorCreditGrantedUpdateIds()
      } as IDebtorCreditGrantedUpdated;

      this.monitoringSrv
        .notifyDebtorCreditGrantedChanges(request)
        .pipe(
          tap((response) => {
            const result = response.result as boolean;
            if (result) {
              const title: string = $localize`: @@app.debtor-credit-granted.notify.result.ok.title:Éxito!`;
              const message: string = $localize`: @@app.debtor-credit-granted.notify.result.ok.message:Informado el usuario de los cambios en los créditos establecidos`;
              this.alertSrv.success(title, message);
            } else {
              const title: string = $localize`: @@app.debtor-credit-granted.notify.result.warning.title:¡Advertencia!`;
              const message: string = $localize`: @@app.debtor-credit-granted.notify.result.warning.message:No se ha informado al usuario de los cambios en los créditos establecidos`;
              this.alertSrv.warning(title, message);
            }
            this.localStorageSrv.cleanDebtorCreditGrantedUpdateIds();
            this.uiBlockerSrv.unblock();
          })
        )
        .subscribe();
    }
  }

  private refreshMonitorableClient(monitorableClient: IMonitoringUserReduced) {
    this.model.userId = monitorableClient.userId;

    this.model.filterForm.get(this.model.C_FISCAL_CODE)?.setValue(null);
    this.model.filterForm.get(this.model.C_BUSINESS_NAME)?.setValue(null);
    this.model.filterForm.get(this.model.C_OWN_IDENTIFIER)?.setValue(null);

    this.onFilterChanges();
  }

  private refresh(): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.monitoringSrv.searchDebtorCreditGranted(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IDebtorCreditGranted>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) =>
          DebtorCreditGrantedMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }

  private showEditDialog(inputData: any) {
    const dialogRef = this.dialog.open(DebtorCreditGrantedEditDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }
}
