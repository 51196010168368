import { KeyValue } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FISCAL_CODE_TYPE_VALUES, SIGNATURE_STATUS_VALUES, USER_REQUEST_STATUS_VALUES } from 'src/app/core/constants';
import { FiscalCodeType, SignatureStatus, UserRequestStatus } from 'src/app/core/enums';
import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { fiscalCodeValidator } from 'src/app/shared/validators';

export class UserRequestEditViewModel {
  private _form: FormGroup;
  private _fiscalCodeTypes: KeyValue<FiscalCodeType, string>[] = FISCAL_CODE_TYPE_VALUES;
  private _signatureStatusValues: KeyValue<SignatureStatus, string>[] = SIGNATURE_STATUS_VALUES;
  private _statusValues: KeyValue<UserRequestStatus, string>[] = USER_REQUEST_STATUS_VALUES;
  private _recruitmentPlans: Array<IRecruitmentPlan> = [];

  readonly C_ID = 'id';
  readonly C_NAME = 'name';
  readonly C_SURNAME = 'surname';
  readonly C_SIGNATORY_FISCAL_CODE_TYPE = 'fiscalCodeType';
  readonly C_SIGNATORY_FISCAL_CODE = 'fiscalCode';
  readonly C_COMPANY_NAME = 'companyName';
  readonly C_COMPANY_ADDRESS = 'companyAddress';
  readonly C_COMPANY_FISCAL_CODE_TYPE = 'companyFiscalCodeType';
  readonly C_COMPANY_FISCAL_CODE = 'companyFiscalCode';
  readonly C_COMPANY_ACTIVITY = 'companyActivity';
  readonly C_RECRUITMENT_PLAN = 'recruitmentPlanId';
  readonly C_EMAIL = 'email';
  readonly C_ASSOCIATION_ID = 'associationId';
  readonly C_ASSOCIATION_DESC = 'associationDesc';
  readonly C_RECRUITMENT_MANAGER_USER_NAME = 'recruitmentManagerUsername';
  readonly C_STATUS = 'status';
  readonly C_SIGNATURE_IDENTIFIER = 'signatureIdentifier';
  readonly C_SIGNATURE_STATUS = 'signatureStatus';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, []),
      [this.C_NAME]: new FormControl('', [Validators.required]),
      [this.C_SURNAME]: new FormControl('', [Validators.required]),
      [this.C_SIGNATORY_FISCAL_CODE_TYPE]: new FormControl('', [Validators.required]),
      [this.C_SIGNATORY_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_NAME]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_ADDRESS]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_FISCAL_CODE_TYPE]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_ACTIVITY]: new FormControl('', [Validators.required]),
      [this.C_RECRUITMENT_PLAN]: new FormControl('', [Validators.required]),
      [this.C_EMAIL]: new FormControl('', [Validators.required, Validators.email]),
      [this.C_ASSOCIATION_ID]: new FormControl(null),
      [this.C_ASSOCIATION_DESC]: new FormControl(''),
      [this.C_RECRUITMENT_MANAGER_USER_NAME]: new FormControl(''),
      [this.C_STATUS]: new FormControl(null),
      [this.C_SIGNATURE_IDENTIFIER]: new FormControl(''),
      [this.C_SIGNATURE_STATUS]: new FormControl(null)
    }, {
      validators: [
        fiscalCodeValidator(this.C_SIGNATORY_FISCAL_CODE_TYPE, this.C_SIGNATORY_FISCAL_CODE),
        fiscalCodeValidator(this.C_COMPANY_FISCAL_CODE_TYPE, this.C_COMPANY_FISCAL_CODE)
      ]
    });
  }

  get fiscalCodeTypes(): KeyValue<FiscalCodeType, string>[] {
    return this._fiscalCodeTypes;
  }
  set fiscalCodeTypes(fiscalCodeTypes: KeyValue<FiscalCodeType, string>[]) {
    this._fiscalCodeTypes = fiscalCodeTypes;
  }

  get signatureStatusValues(): KeyValue<SignatureStatus, string>[] {
    return this._signatureStatusValues;
  }
  set signatureStatusValues(value: KeyValue<SignatureStatus, string>[]) {
    this._signatureStatusValues = value;
  }

  get statusValues(): KeyValue<UserRequestStatus, string>[] {
    return this._statusValues;
  }
  set statusValues(value: KeyValue<UserRequestStatus, string>[]) {
    this._statusValues = value;
  }

  get recruitmentPlans(): Array<IRecruitmentPlan> {
    return this._recruitmentPlans;
  }

  set recruitmentPlans(recruitmentPlans: Array<IRecruitmentPlan>) {
    this._recruitmentPlans = recruitmentPlans;
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }

  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get name(): string | null | undefined {
    return this._form.get(this.C_NAME)?.value;
  }

  set name(value: string | null | undefined) {
    this._form.get(this.C_NAME)?.setValue(value);
  }

  get surname(): string | null | undefined {
    return this._form.get(this.C_SURNAME)?.value;
  }

  set surname(value: string | null | undefined) {
    this._form.get(this.C_SURNAME)?.setValue(value);
  }

  get fiscalCodeType(): FiscalCodeType | null | undefined {
    return this._form.get(this.C_SIGNATORY_FISCAL_CODE_TYPE)?.value;
  }

  set fiscalCodeType(value: FiscalCodeType | null | undefined) {
    this._form.get(this.C_SIGNATORY_FISCAL_CODE_TYPE)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this._form.get(this.C_SIGNATORY_FISCAL_CODE)?.value;
  }

  set fiscalCode(value: string | null | undefined) {
    this._form.get(this.C_SIGNATORY_FISCAL_CODE)?.setValue(value);
  }

  get companyName(): string | null | undefined {
    return this._form.get(this.C_COMPANY_NAME)?.value;
  }

  set companyName(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_NAME)?.setValue(value);
  }

  get companyAddress(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ADDRESS)?.value;
  }

  set companyAddress(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ADDRESS)?.setValue(value);
  }

  get companyFiscalCodeType(): FiscalCodeType | null | undefined {
    return this._form.get(this.C_COMPANY_FISCAL_CODE_TYPE)?.value;
  }

  set companyFiscalCodeType(value: FiscalCodeType | null | undefined) {
    this._form.get(this.C_COMPANY_FISCAL_CODE_TYPE)?.setValue(value);
  }

  get companyFiscalCode(): string | null | undefined {
    return this._form.get(this.C_COMPANY_FISCAL_CODE)?.value;
  }

  set companyFiscalCode(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_FISCAL_CODE)?.setValue(value);
  }

  get companyActivity(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ACTIVITY)?.value;
  }

  set companyActivity(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ACTIVITY)?.setValue(value);
  }

  get email(): string | null | undefined {
    return this._form.get(this.C_EMAIL)?.value;
  }

  set email(value: string | null | undefined) {
    this._form.get(this.C_EMAIL)?.setValue(value);
  }

  get recruitmentPlanId(): number | null | undefined {
    return this._form.get(this.C_RECRUITMENT_PLAN)?.value;
  }

  set recruitmentPlanId(value: number | null | undefined) {
    this._form.get(this.C_RECRUITMENT_PLAN)?.setValue(value);
  }

  get associationId(): string | null | undefined {
    return this._form.get(this.C_ASSOCIATION_ID)?.value;
  }
  set associationId(value: string | null | undefined) {
    this._form.get(this.C_ASSOCIATION_ID)?.setValue(value);
  }

  get associationDesc(): string | null | undefined {
    return this._form.get(this.C_ASSOCIATION_DESC)?.value;
  }
  set associationDesc(value: string | null | undefined) {
    this._form.get(this.C_ASSOCIATION_DESC)?.setValue(value);
  }

  get recruitmentManagerUsername(): string | null | undefined {
    return this._form.get(this.C_RECRUITMENT_MANAGER_USER_NAME)?.value;
  }

  set recruitmentManagerUsername(value: string | null | undefined) {
    this._form.get(this.C_RECRUITMENT_MANAGER_USER_NAME)?.setValue(value);
  }

  get status(): UserRequestStatus | null | undefined {
    return this._form.get(this.C_STATUS)?.value;
  }

  set status(value: UserRequestStatus | null | undefined) {
    this._form.get(this.C_STATUS)?.setValue(value);
  }

  get signatureIdentifier(): string | null | undefined {
    return this._form.get(this.C_SIGNATURE_IDENTIFIER)?.value;
  }

  set signatureIdentifier(value: string | null | undefined) {
    this._form.get(this.C_SIGNATURE_IDENTIFIER)?.setValue(value);
  }

  get signatureStatus(): SignatureStatus | null | undefined {
    return this._form.get(this.C_SIGNATURE_STATUS)?.value;
  }
  set signatureStatus(value: SignatureStatus | null | undefined) {
    this._form.get(this.C_SIGNATURE_STATUS)?.setValue(value);
  }
}
