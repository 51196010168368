<div class="flex">
  <div class="f-basis--6 ">
    <h1 i18n="@@app.credit-opinion.edit.attachments">
      Documentación adjunta
      <button mat-icon-button
        color="primary"
        [title]="'Añadir nuevo adjunto'"
        i18n-title="@@app.credit-opinion.edit.attachments.add"
        [disabled]="!model.isEditable"
        (click)="onNew()"
      >
        <mat-icon>attach_file</mat-icon>
      </button>
    </h1>
  </div>
</div>
<div>
  <howden-table
    class="small-font"
    keyField="id"
    sortable="local"
    [clickableRow]="true"
    [cols]="model.columns"
    [data]="model.data"
    [buttons]="model.buttons"
    [clickableRow]="model.isEditable"
    (rowClicked)="onRowClicked($event)"
    (buttonClicked)="onAction($event)"
  >
  </howden-table>
</div>
