import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig } from '@howdeniberia/core-front';
import { DashboardPeriod } from 'src/app/core/enums';
import { IDashboardCompanyAssess } from 'src/app/core/models/api/dashboard';

export class CreditOpinionHistoryViewModel {
  private _filterForm: FormGroup;

  readonly C_PERIOD_SELECTED = 'periodSelected';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_PERIOD_SELECTED]: new FormControl(DashboardPeriod.SixMonths, [Validators.required])
    });
  }

  dashboardData: IDashboardCompanyAssess[] = [];
  dashboardColumns: IHowdenColumnConfig[] = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.dashboard.credit-opinion-history.company.fiscalCode.column:ID Fiscal`,
      canSort: true,
      width: '25%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.dashboard.credit-opinion-history.company.businessName.column:Razón social solicitante`,
      canSort: true,
      width: '65%'
    }
  ];

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._filterForm.get(controlName)!;
  }

  public get filterForm(): FormGroup {
    return this._filterForm;
  }
  public set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get periodSelected(): number | null | undefined {
    return this.getControl(this.C_PERIOD_SELECTED)?.value;
  }
  set periodSelected(value: number | null | undefined) {
    this.getControl(this.C_PERIOD_SELECTED)?.setValue(value);
  }

  get OneMonth_Period() {
    return DashboardPeriod.OneMonth;
  }
  get SixMonths_Period() {
    return DashboardPeriod.SixMonths;
  }
  get OneYear_Period() {
    return DashboardPeriod.OneYear;
  }
  get FiveYears_Period() {
    return DashboardPeriod.FiveYears;
  }
  get Max_Period() {
    return DashboardPeriod.Max;
  }
}
