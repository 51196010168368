import { FormControl, FormGroup } from '@angular/forms';
import { CreditOpinionOperation } from 'src/app/core/enums';

export class CreditOpinionOperationDialogViewModel {
  private _form: FormGroup;

  readonly C_OPERATION = 'operation';
  readonly C_CREDIT_OPINION_IDS = 'creditOpinionIds';

  constructor() {
    this._form = new FormGroup({
      [this.C_OPERATION]: new FormControl(null, []),
      [this.C_CREDIT_OPINION_IDS]: new FormControl(null, [])
    });
  }

  get form(): FormGroup {
    return this._form;
  }

  get operation(): CreditOpinionOperation | null | undefined {
    return this._form.get(this.C_OPERATION)?.value;
  }
  set operation(value: CreditOpinionOperation | null | undefined) {
    this._form.get(this.C_OPERATION)?.setValue(value);
  }

  get creditOpinionIds(): any | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_IDS)?.value;
  }
  set creditOpinionIds(value: any | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_IDS)?.setValue(value);
  }
}
