import { NgModule } from '@angular/core';
import { ContractCheckContract, ContractCheckService } from 'src/app/core/services/api/contract-check';
import { RecruitmentPlanContract, RecruitmentPlanService } from 'src/app/core/services/api/recruitment-plan';
import { UserDataContract, UserDataService } from 'src/app/core/services/api/user-data';
import { UserDataHistoryContract, UserDataHistoryService } from 'src/app/core/services/api/user-data-history';
import { UserRequestContract, UserRequestService } from 'src/app/core/services/api/user-request';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContractCheckComponent } from './contract-check/pages/contract-check.component';
import { ManagementRoutingModule } from './management-routing.module';
import { RecruitmentPlanEditDialogComponent } from './recruitment-plan/dialogs/recruitment-plan-edit-dialog/pages/recruitment-plan-edit-dialog.component';
import { RecruitmentPlanSearchComponent } from './recruitment-plan/pages/recruitment-plan-search/recruitment-plan-search.component';
import { UserDataHistoryDialogComponent } from './user-data/dialogs/user-data-history-dialog/pages/user-data-history-dialog/user-data-history-dialog.component';
import { UserDataEditComponent } from './user-data/pages/user-data-edit/user-data-edit.component';
import { UserDataHistoryComponent } from './user-data/pages/user-data-history/user-data-history.component';
import { UserDataSearchComponent } from './user-data/pages/user-data-search/user-data-search.component';
import { UserRequestEditComponent } from './user-request/pages/user-request-edit/user-request-edit.component';
import { UserRequestSearchComponent } from './user-request/pages/user-request-search/user-request-search.component';


@NgModule({
  imports: [ManagementRoutingModule, SharedModule],
  declarations: [
    RecruitmentPlanEditDialogComponent,
    RecruitmentPlanSearchComponent,
    ContractCheckComponent,
    UserDataEditComponent,
    UserDataSearchComponent,
    UserRequestEditComponent,
    UserRequestSearchComponent,
    UserDataHistoryComponent,
    UserDataHistoryDialogComponent
  ],
  providers: [
    { provide: RecruitmentPlanContract, useClass: RecruitmentPlanService },
    { provide: ContractCheckContract, useClass: ContractCheckService },
    { provide: UserRequestContract, useClass: UserRequestService },
    { provide: UserDataContract, useClass: UserDataService },
    { provide: UserDataHistoryContract, useClass: UserDataHistoryService }
  ]
})
export class ManagementModule {
}
