import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function creditGrantedLimitMatchValidator(grantedFieldName: string, limitFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const grantedAmount = control.get(grantedFieldName)?.value as number;
    const limitAmount = control.get(limitFieldName)?.value as number;

    if (!grantedAmount || !limitAmount) {
      return null;
    }

    return grantedAmount <= limitAmount ? null : { 'grantedLimitInvalid': true };
  };
}

export function creditGrantedRequestedMatchValidator(grantedFieldName: string, requestedFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const grantedAmount = control.get(grantedFieldName)?.value as number;
    const requestedAmount = control.get(requestedFieldName)?.value as number;

    if (!grantedAmount || !requestedAmount) {
      return null;
    }

    return grantedAmount <= requestedAmount ? null : { 'grantedRequestedInvalid': true };
  };
}
