
 <form [formGroup]="model.form">
  <div class="flex">
    <div class="f-basis--6">
      <h1 i18n="@@app.credit-opinion.edit.lastExercises">
        Información financiera
        <button mat-icon-button color="primary"
          [title]="'Añadir información financiera'"
          i18n-title="@@app.credit-opinion.edit.lastExercises.add"
          [disabled]="!model.isEditable || model.lackExerciseInfo"
          (click)="onNew()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </h1>
    </div>
    <div class="flex f-basis--6 f-jc--end margin-top-20">
      <mat-slide-toggle
        color="primary"
        [disabled]="!model.isEditable"
        [formControlName]="model.C_LACK_EXERCISE_INFO"
        i18n="@@app.credit-opinion.edit.lackExercicioInfo"
      >
        Sin información financiera
      </mat-slide-toggle>
    </div>
  </div>
</form>
<div>
  <howden-table
    class="small-font"
    keyField="id"
    sortable="local"
    [clickableRow]="true"
    [cols]="model.columns"
    [data]="model.data"
    [buttons]="model.buttons"
    [clickableRow]="model.isEditable"
    (rowClicked)="onRowClicked($event)"
    (buttonClicked)="onAction($event)"
  >
  </howden-table>
</div>
