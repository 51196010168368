import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import {
  IDebtorCreditGranted,
  IDebtorCreditGrantedFilter,
  IDebtorCreditGrantedUpdated,
  IMonitoringUserComplete,
  IMonitoringUserReduced
} from 'src/app/core/models/api/monitoring';

export abstract class MonitoringContract {
  abstract getMonitorableClients(): Observable<Array<IMonitoringUserReduced>>;
  abstract searchDebtorCreditGranted(request: IDebtorCreditGrantedFilter): Observable<IPageOf<IDebtorCreditGranted>>;
  abstract getDebtorCreditGranted(id: string): Observable<IDebtorCreditGranted>;
  abstract saveDebtorCreditGranted(request: IDebtorCreditGranted): Observable<IResponse<boolean>>;
  abstract notifyDebtorCreditGrantedChanges(request: IDebtorCreditGrantedUpdated): Observable<IResponse<boolean>>;
  abstract getCompleteMonitoring(userId: number): Observable<IMonitoringUserComplete>;
  abstract downloadMonitoringHistorical(userId: number): Observable<Blob>;
}
