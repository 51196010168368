import { KeyValue } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IHowdenColumnConfig,
  ITableButtonConfig,
  ITableEventData
} from '@howdeniberia/core-front';
import { USER_REQUEST_STATUS_VALUES } from 'src/app/core/constants';
import { UserRequestStatus } from 'src/app/core/enums';
import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { IUserRequestFilter } from 'src/app/core/models/api/user-request';
import { IUserRequestViewModel } from './user-request.viewmodel';

export class UserRequestSearchViewModel {
  private _filterForm: FormGroup;
  private _statusValues: KeyValue<UserRequestStatus, string>[] = USER_REQUEST_STATUS_VALUES;
  private _recruitmentPlans: Array<IRecruitmentPlan> = [];
  private _searchRequest: IUserRequestFilter;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_COMPANY_FISCAL_CODE = 'companyFiscalCode';
  readonly C_COMPANY_NAME = 'companyName';
  readonly C_EMAIL = 'email';
  readonly C_RECRUITMENT_PLAN_ID = 'recruitmentPlanId';
  readonly C_STATUS = 'status';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_COMPANY_FISCAL_CODE]: new FormControl(null, []),
      [this.C_COMPANY_NAME]: new FormControl(null, []),
      [this.C_EMAIL]: new FormControl(null, []),
      [this.C_RECRUITMENT_PLAN_ID]: new FormControl(null, []),
      [this.C_STATUS]: new FormControl(UserRequestStatus.Pending, [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IUserRequestFilter;
  }

  data: IUserRequestViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'companyFiscalCode',
      fieldHeader: $localize`:@@app.user-request.search.companyFiscalCode.column:ID Fiscal empresa`,
      canSort: true
    },
    {
      fieldName: 'companyName',
      fieldHeader: $localize`:@@app.user-request.search.companyName.column:Nombre empresa`,
      canSort: true
    },
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.user-request.search.fiscalCode.column:ID Fiscal`,
      canSort: true
    },
    {
      fieldName: 'name',
      fieldHeader: $localize`:@@app.user-request.search.name.column:Nombre`,
      canSort: true
    },
    {
      fieldName: 'surname',
      fieldHeader: $localize`:@@app.user-request.search.surname.column:Apellidos`,
      canSort: true
    },
    {
      fieldName: 'email',
      fieldHeader: $localize`:@@app.user-request.search.email.column:Email`,
      canSort: true
    },
    {
      fieldName: 'recruitmentPlanDesc',
      fieldHeader: $localize`:@@app.user-request.search.recruitmentPlan.column:Plan contratado`,
      canSort: false
    },
    {
      fieldName: 'recruitmentManager',
      fieldHeader: $localize`:@@app.user-request.search.recruitmentManager.column:Gestor contratación`,
      canSort: false
    },
    {
      fieldName: 'signatureStatus',
      fieldHeader: $localize`:@@app.user-request.search.signatureStatus.column:Estado firma`,
      canSort: true
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.user-request.search.status.column:Estado`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get length(): number {
    return this._length;
  }

  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get statusValues(): KeyValue<UserRequestStatus, string>[] {
    return this._statusValues;
  }

  get recruitmentPlans(): Array<IRecruitmentPlan> {
    return this._recruitmentPlans;
  }

  set recruitmentPlans(value: Array<IRecruitmentPlan>) {
    this._recruitmentPlans = value;
  }

  get searchRequest(): IUserRequestFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get companyFiscalCode(): string | null | undefined {
    return this._filterForm.get(this.C_COMPANY_FISCAL_CODE)?.value;
  }
  set companyFiscalCode(value: string | null | undefined) {
    this._filterForm.get(this.C_COMPANY_FISCAL_CODE)?.setValue(value);
  }

  get companyName(): string | null | undefined {
    return this._filterForm.get(this.C_COMPANY_NAME)?.value;
  }
  set companyName(value: string | null | undefined) {
    this._filterForm.get(this.C_COMPANY_NAME)?.setValue(value);
  }

  get email(): string | null | undefined {
    return this._filterForm.get(this.C_EMAIL)?.value;
  }
  set email(value: string | null | undefined) {
    this._filterForm.get(this.C_EMAIL)?.setValue(value);
  }

  get recruitmentPlanId(): number | null | undefined {
    return this._filterForm.get(this.C_RECRUITMENT_PLAN_ID)?.value;
  }
  set recruitmentPlanId(value: number | null | undefined) {
    this._filterForm.get(this.C_RECRUITMENT_PLAN_ID)?.setValue(value);
  }

  get status(): number | null | undefined {
    return this._filterForm.get(this.C_STATUS)?.value;
  }
  set status(value: number | null | undefined) {
    this._filterForm.get(this.C_STATUS)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.companyFiscalCode = this.companyFiscalCode;
    this.searchRequest.companyName = this.companyName;
    this.searchRequest.email = this.email;
    this.searchRequest.recruitmentPlanId = this.recruitmentPlanId;
    this.searchRequest.status = this.status;
  }
}
