import { FormControl, FormGroup } from '@angular/forms';
import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { ICreditOpinionExerciseViewModel } from '.';


export class CreditOpinionEditExercisesViewModel {
  private _form: FormGroup;

  readonly C_LACK_EXERCISE_INFO = 'lackExerciseInfo';

  isEditable = true;

  constructor() {
    this._form = new FormGroup({
      [this.C_LACK_EXERCISE_INFO]: new FormControl(false, [])
    });
  }

  data: ICreditOpinionExerciseViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'exercise',
      fieldHeader: $localize`:@@app.credit-opinion.edit.exercises.exercise.column:Año`,
      canSort: true,
      aligment: ColumnAligment.CENTER,
      width: '15%'
    },
    {
      fieldName: 'billing',
      fieldHeader: $localize`:@@app.credit-opinion.edit.exercises.billing.column:Ventas`,
      pipeToApply: ColumnPipeTypes.CURRENCY,
      aligment: ColumnAligment.CENTER,
      canSort: true,
      width: '35%'
    },
    {
      fieldName: 'result',
      fieldHeader: $localize`:@@app.credit-opinion.edit.exercises.result.column:Resultado`,
      pipeToApply: ColumnPipeTypes.CURRENCY,
      aligment: ColumnAligment.CENTER,
      canSort: true,
      width: '35%'
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete_forever',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.credit-opinion.edit.exercises.actions.delete.tooltip:Eliminar Ejercicio`,
    isDisabled: (): boolean => !this.isEditable
  }];

  get form(): FormGroup {
    return this._form;
  }

  get lackExerciseInfo(): boolean | null | undefined {
    return this._form.get(this.C_LACK_EXERCISE_INFO)?.value;
  }
  set lackExerciseInfo(value: boolean | null | undefined) {
    this._form.get(this.C_LACK_EXERCISE_INFO)?.setValue(value);
  }
}
