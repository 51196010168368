<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.form" enctype="multipart/form-data">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.edit.userRole">Rol</mat-label>
        <mat-select
          (selectionChange)="onChangeRole($event.value)"
          [formControlName]="model.C_ROLE"
          [howdenControlError]="userRoleError"
          #roleSelected
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.roles | howdenArraySort : 'description'"
            [value]="item.name"
          >
            {{ item.description }}
          </mat-option>
        </mat-select>
        <mat-error #userRoleError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.edit.userName">Nombre</mat-label>
        <input
          matInput
          [formControlName]="model.C_NAME"
          [howdenControlError]="userNameError"
          maxlength="100"
        />
        <mat-error #userNameError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-data.edit.userSurname">Apellidos</mat-label>
        <input
          matInput
          [formControlName]="model.C_SURNAME"
          [howdenControlError]="userSurnameError"
          maxlength="250"
        />
        <mat-error #userSurnameError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-data.edit.userEmail">Email</mat-label>
        <input
          matInput
          [formControlName]="model.C_EMAIL"
          [howdenControlError]="userEmailError"
          maxlength="256"
        />
        <mat-error #userEmailError></mat-error>
      </mat-form-field>
      <div class="f-basis--2" *ngIf="isNew"></div>
      <mat-form-field class="f-basis--2" *ngIf="!isNew">
        <mat-label i18n="@@app.user.edit.status">Estado</mat-label>
        <mat-select
          [formControlName]="model.C_STATUS"
          [howdenControlError]="statusError"
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.statusValues | howdenArraySort : 'value'"
            [value]="item.key"
          >
            {{ item.value }}
          </mat-option>
        </mat-select>
        <mat-error #statusError></mat-error>
      </mat-form-field>
    </div>
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2" *ngIf="isNew">
        <mat-label i18n="@@app.user-data.edit.userPassword">Contraseña</mat-label>
        <input
          matInput
          [formControlName]="model.C_PASSWORD"
          [howdenControlError]="userPasswordError"
          type="password"
        />
        <mat-error #userPasswordError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--2" *ngIf="isNew">
        <mat-label i18n="@@app.user-data.edit.userPasswordValidation">Confirme la contraseña</mat-label>
        <input
          matInput
          [formControlName]="model.C_PASSWORD_VALIDATION"
          [howdenControlError]="userPasswordValidationError"
          type="password"
        />
        <mat-error #userPasswordValidationError></mat-error>
      </mat-form-field>
      <div class="f-basis--2" *ngIf="!isNew"></div>
      <div class="f-basis--2" *ngIf="!isNew"></div>
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-data.edit.userContactName">Persona de contacto</mat-label>
        <input
          matInput
          [formControlName]="model.C_CONTACT_NAME"
          [howdenControlError]="userContactNameError"
          maxlength="256"
        />
        <mat-error #userContactNameError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-data.edit.userContactEmail">Email persona de contacto</mat-label>
        <input
          matInput
          [formControlName]="model.C_CONTACT_EMAIL"
          [howdenControlError]="userContactEmailError"
          maxlength="256"
        />
        <mat-error #userContactEmailError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.edit.phoneNumber">Teléfono</mat-label>
        <input
          matInput
          [formControlName]="model.C_PHONE_NUMBER"
          [howdenControlError]="phoneNumberError"
          maxlength="250"
        />
        <mat-error #phoneNumberError></mat-error>
      </mat-form-field>
    </div>
    <div class="flex f-gap--10px margin-bottom-10 small-letter" *ngIf="this.model.form.errors?.['passwordMatchInvalid']">
      <mat-error i18n="@@app.validators.password-match-invalid">
        Las contraseñas no coinciden
      </mat-error>
    </div>
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2" *ngIf="isClient">
        <mat-label i18n="@@app.user-data.edit.userFiscalCodeType">Tipo ID Fiscal</mat-label>
        <mat-select
          [formControlName]="model.C_FISCAL_CODE_TYPE"
          [howdenControlError]="userFiscalCodeTypeError"
          #roleSelected
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.fiscalCodeTypeValues | howdenArraySort : 'value' "
            [value]="item.key"
          >
            {{ item.value }}
          </mat-option>
        </mat-select>
        <mat-error #userFiscalCodeTypeError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--2" *ngIf="isClient">
        <mat-label i18n="@@app.user-data.edit.userFiscalCode">ID Fiscal</mat-label>
        <input
          matInput
          [formControlName]="model.C_FISCAL_CODE"
          [howdenControlError]="userFiscalCodeError"
          maxlength="20"
        />
        <mat-error #userFiscalCodeError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--3" *ngIf="isClient">
        <mat-label i18n="@@app.user-data.edit.userName">Razón social</mat-label>
        <input
          matInput
          [formControlName]="model.C_BUSINESS_NAME"
          [howdenControlError]="userNameError"
          maxlength="256"
        />
        <mat-error #userNameError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--3" *ngIf="isAssociationMember">
        <mat-label i18n="@@app.user-data.edit.association">Asociación</mat-label>
        <mat-select
          (selectionChange)="onChangeAssociation()"
          [formControlName]="model.C_ASSOCIATION"
          [howdenControlError]="associationError"
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.associations | howdenArraySort : 'businessName'"
            [value]="item.userId"
          >
            {{ item.businessName + " [" + item.fiscalCode + "]" }}
          </mat-option>
        </mat-select>
        <mat-error #associationError></mat-error>
      </mat-form-field>
      <div class="f-basis--3" *ngIf="isClient && !isAssociationMember">
        <mat-icon color="warn"
          class="ico-not-download"
          *ngIf="!isNew && !model.secondaryLogo"
          [title]="'No hay logo secundario cargado'" i18n-title="@@app.user-data.edit.secondary-logo.not-loaded">
          broken_image
        </mat-icon>
        <mat-icon color="primary"
          class="ico-download"
          *ngIf="!isNew && model.secondaryLogo"
          (click)="downloadSecondaryLogo()"
          [title]="'Descargar logo secundario anterior'" i18n-title="@@app.user-data.edit.secondary-logo.loaded">
          image
        </mat-icon>
        <mat-label i18n="@@app.user-data.edit.secondary-logo">Logo (.png)</mat-label>
        <howden-file-upload
          #secondaryLogo
          (filesSelected)="onSecondaryLogoSelected()">
        </howden-file-upload>
      </div>
      <div class="f-basis--2" *ngIf="isClient && !isAssociationMember">
        <mat-icon color="warn"
          class="ico-not-download"
          *ngIf="!isNew && !model.reportLogo"
          [title]="'No hay logo para los informes cargado'" i18n-title="@@app.user-data.edit.report-logo.not-loaded">
          broken_image
        </mat-icon>
        <mat-icon color="primary"
          class="ico-download"
          *ngIf="!isNew && model.reportLogo"
          (click)="downloadReportLogo()"
          [title]="'Descargar logo para informes anterior'" i18n-title="@@app.user-data.edit.report-logo.loaded">
          image
        </mat-icon>
        <mat-label i18n="@@app.user-data.edit.report-logo">Logo informes (.png)</mat-label>
        <howden-file-upload
          #reportLogo
          (filesSelected)="onReportLogoSelected()"
        >
        </howden-file-upload>
      </div>
      <div class="f-basis--2" *ngIf="isAssociationMember"></div>
    </div>
    <div class="flex f-gap--10px margin-bottom-10 small-letter" *ngIf="this.model.form.errors?.['fiscalCodeInvalid']">
      <mat-error i18n="@@app.validators.fiscal-code-invalid">
        El identificador fiscal no es válido
      </mat-error>
    </div>
    <div class="flex f-gap--10px margin-bottom-10" *ngIf="isClient && isNew">
      <mat-form-field class="f-basis--2" >
        <mat-label i18n="@@app.user-data.edit.recruitmentPlan">Plan contratado</mat-label>
        <mat-select
          (selectionChange)="onChangeRecruitmentPlan()"
          [formControlName]="model.C_RECRUITMENT_PLAN"
          [howdenControlError]="recruitmentPlanError"
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.recruitmentPlans | howdenArraySort : 'description'"
            [value]="item.id"
          >
            {{ item.description }}
          </mat-option>
        </mat-select>
        <mat-error #recruitmentPlanError></mat-error>
      </mat-form-field>
      <div class="f-basis--2" *ngIf="!hasMonitoring"></div>
      <mat-form-field class="f-basis--2" *ngIf="hasMonitoring" >
        <mat-label i18n="@@app.user-data.edit.monitorigPeriod">Periodo monitorización</mat-label>
        <mat-select
          [formControlName]="model.C_MONITORING_PERIOD"
          [howdenControlError]="monitoringPeriodError"
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.monitoringPeriodValues | howdenArraySort : 'value'"
            [value]="item.key"
          >
            {{ item.value }}
          </mat-option>
        </mat-select>
        <mat-error #monitoringPeriodError></mat-error>
      </mat-form-field>
      <div class="f-basis--2" *ngIf="!hasFlexPlan || isAssociationMember"></div>
      <howden-decimal
        *ngIf="hasFlexPlan && !isAssociationMember"
        class="f-basis--2"
        [formControlName]="model.C_CREDIT_OPINION_PRICE"
        i18n-label="@@app.user-data.edit.creditOpinionPrice"
        label="Precio opinión crédito"
      ></howden-decimal>
      <div class="f-basis--6"></div>
    </div>
    <div class="flex f-gap--10px margin-bottom-10" *ngIf="isClient && !isNew">
      <div class="f-basis--12">
        <howden-user-data-history [userId]="this.userId"></howden-user-data-history>
      </div>
    </div>
    <div class="flex f-gap--10px margin-top-10 margin-bottom-20"  *ngIf="isAssociation">
      <mat-slide-toggle
        color="primary"
        class="f-basis--2"
        [formControlName]="model.C_PARTICULAR_STYLES"
        i18n="@@app.user-data.edit.particularStyles"
        (change)="onChangeParticularStyles($event.checked)"
        >Estilos Particulares
      </mat-slide-toggle>
      <mat-form-field class="f-basis--2" *ngIf="model.particularStyles">
        <mat-label i18n="@@app.user.edit.hexPrimaryColor">Color primario en hexadecimal (#FFFF)</mat-label>
        <input
          matInput
          [formControlName]="model.C_HEX_PRIMARY_COLOR"
          [howdenControlError]="hexPrimaryColorError"
          maxlength="7"
        />
        <mat-error #hexPrimaryColorError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--2" *ngIf="model.particularStyles">
        <mat-label i18n="@@app.user.edit.hexSecondaryColor">Color secundario en hexadecimal (#FFFF)</mat-label>
        <input
          matInput
          [formControlName]="model.C_HEX_SECONDARY_COLOR"
          [howdenControlError]="hexSecondaryColorError"
          maxlength="7"
        />
        <mat-error #hexSecondaryColorError></mat-error>
      </mat-form-field>
      <div class="f-basis--6" *ngIf="model.particularStyles">
      </div>
    </div>
    <div class="flex f-gap--10px f-ai--center margin-bottom-20">
      <div class="f-basis--6">
        <button
          mat-raised-button
          (click)="goToSearch()"
        >
          <span i18n="@@app.actions.cancelar">Atrás</span>
        </button>
      </div>
      <div class="flex f-basis--6 f-jc--end">
        <button
          mat-raised-button
          color="primary"
          [disabled]="model.form.invalid"
          (click)="onSave()"
        >
          <span i18n="@@app.actions.save">Guardar</span>
        </button>
      </div>
    </div>
  </form>
</div>
