import { Injectable } from '@angular/core';
import { HowdenAuthService } from '@howdeniberia/core-front';
import { firstValueFrom } from 'rxjs';
import { UserRole } from '../enums';
import { UserDataContract } from '../services/api/user-data';

@Injectable({
  providedIn: 'root'
})
export class MenuHelperService {
  private readonly CREDIT_OPINION_OPTION = 'creditOpinion';
  private readonly MONITORING_OPTION = 'monitoring';

  constructor(
    private authSrv: HowdenAuthService,
    private userDataSrv: UserDataContract
  ) { }

  async checkCreditOpinionOption(): Promise<boolean> {
    return await this.checkOption(this.CREDIT_OPINION_OPTION);
  }

  async checkMonitoringOption(): Promise<boolean> {
    return await this.checkOption(this.MONITORING_OPTION);
  }

  private async checkOption(option: string): Promise<boolean> {
    const isClientProfile = this.authSrv.isUserAuthorized([
      UserRole.Association,
      UserRole.AssociationMember,
      UserRole.IndependentClient
    ]) && !this.authSrv.isSuperAdmin;

    if (!isClientProfile) {
      return true;
    }

    const userData = await firstValueFrom(this.userDataSrv.getLoggedClientInfo());

    if (option === this.CREDIT_OPINION_OPTION
      && userData.onlyMonitoring !== null
      && userData.onlyMonitoring !== undefined
      && userData.onlyMonitoring === true) {
      return false;
    } else if (option === this.MONITORING_OPTION
      && userData.onlyCreditOpinion !== null
      && userData.onlyCreditOpinion !== undefined
      && userData.onlyCreditOpinion === true) {
      return false;
    }
    return true;
  }
}
