import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from 'src/app/core/enums';
import { ContractCheckComponent } from './contract-check/pages/contract-check.component';
import { RecruitmentPlanSearchComponent } from './recruitment-plan/pages/recruitment-plan-search/recruitment-plan-search.component';
import { UserDataEditComponent } from './user-data/pages/user-data-edit/user-data-edit.component';
import { UserDataSearchComponent } from './user-data/pages/user-data-search/user-data-search.component';
import { UserRequestEditComponent } from './user-request/pages/user-request-edit/user-request-edit.component';
import { UserRequestSearchComponent } from './user-request/pages/user-request-search/user-request-search.component';


export const ManagementRoutes = [
  {
    path: 'management',
    title: $localize`:@@app.route.management:Gestión`,
    data: {
      icon: 'manage_accounts',
      linkText: $localize`:@@app.route.management:Gestión`,
      roles: [UserRole.SuperAdmin, UserRole.Administrator]
    },
    children: [
      //Contratación
      {
        path: 'user-request/search',
        component: UserRequestSearchComponent,
        title: $localize`:@@app.route.user-request.search.title:Contratos`,
        data: {
          linkText: $localize`:@@app.route.user-request.search:Contratos`
        }
      },
      {
        path: 'user-request/edit/:id',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.edit:Edición Contratación`
      },
      {
        path: 'user-request/new',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.title:Nueva Contratación`,
        data: {
          linkText: $localize`:@@app.route.user-request.new:Nueva Contratación`,
          roles: [UserRole.SuperAdmin]
        }
      },
      {
        path: 'user-request/new/basicPlan',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.basicPlan.manage.title:Contratación Plan Básico`,
        data: {
          linkText: $localize`:@@app.route.user-request.new.basicPlan.manage:Contratación Plan Básico`,
          roles: [UserRole.SuperAdmin]
        }
      },
      {
        path: 'user-request/new/premiumPlan',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.premiumPlan.manage.title:Contratación Plan Premium`,
        data: {
          linkText: $localize`:@@app.route.user-request.new.premiumPlan.manage:Contratación Plan Premium`,
          roles: [UserRole.SuperAdmin]
        }
      },
      {
        path: 'user-request/new/flexPlan',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.flexPlan.manage.title:Contratación Plan Flex`,
        data: {
          linkText: $localize`:@@app.route.user-request.new.flexPlan.manage:Contratación Plan Flex`,
          roles: [UserRole.SuperAdmin]
        }
      },
      {
        path: 'user-request/asocmember/new/:associationId',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new-association-member.manage.title:Nueva Contratación (Asociación)`,
        data: {
          linkText: $localize`:@@app.route.user-request.new-association-member.manage:Nueva Contratación (Asociación)`,
          roles: [UserRole.SuperAdmin]
        }
      },
      //Usuarios
      {
        path: 'user-data/search',
        component: UserDataSearchComponent,
        title: $localize`:@@app.route.user-data.search.title:Usuarios`,
        data: {
          linkText: $localize`:@@app.route.user-data.search:Usuarios`
        }
      },
      {
        path: 'user-data/edit/:id',
        component: UserDataEditComponent,
        title: $localize`:@@app.route.user-data.edit:Edición Usuario`
      },
      {
        path: 'user-data/new',
        component: UserDataEditComponent,
        title: $localize`:@@app.route.user-data.new.title:Nuevo Usuario`,
        data: {
          linkText: $localize`:@@app.route.user-data.new:Nuevo Usuario`
        }
      },
      //Planes
      {
        path: 'recruitment-plan',
        component: RecruitmentPlanSearchComponent,
        title: $localize`:@@app.route.recruitment-plan:Planes`,
        data: {
          linkText: $localize`:@@app.route.recruitment-plan:Planes`
        }
      },
      {
        path: 'contract-check',
        component: ContractCheckComponent,
        title: $localize`:@@app.route.contract-check.title:Comprobar Contrato (pdf)`,
        data: {
          linkText: $localize`:@@app.route.contract-check:Comprobar Contrato`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ManagementRoutes)],
  exports: [RouterModule]
})
export class ManagementRoutingModule { }
