import moment from 'moment';
import { MONITORING_PERIOD_VALUES } from 'src/app/core/constants';
import { MonitoringPeriod } from 'src/app/core/enums';
import { IUserDataHistory } from 'src/app/core/models/api/user-data';
import { UserDataHistoryDialogViewModel } from '../dialogs/user-data-history-dialog/models';
import { IUserDataHistoryViewModel } from '../models';


export abstract class UserDataHistoryMapper {
  static mapForEdit(source: IUserDataHistory, target: UserDataHistoryDialogViewModel): void {
    target.id = source.id;
    target.userId = source.userId;
    target.starDate = source.startDate;
    target.endDate = source.endDate;
    target.recruitmentPlan = source.recruitmentPlanId;
    target.monitoringPeriodicity = source.monitoringPeriodicity;
    target.creditOpinionPrice = source.creditOpinionPrice;
    target.isLow = source.isLow;
  }

  static mapForUpdate(source: UserDataHistoryDialogViewModel): IUserDataHistory {
    return {
      id: source.id,
      userId: source.userId,
      startDate: source.starDate,
      endDate: source.endDate,
      recruitmentPlanId: source.recruitmentPlan,
      monitoringPeriodicity: source.monitoringPeriodicity,
      creditOpinionPrice: source.creditOpinionPrice,
      isLow: source.isLow
    } as IUserDataHistory;
  }

  static mapForSearch(source: Array<IUserDataHistory>): Array<IUserDataHistoryViewModel> {
    return source.map(t => ({
      id: t.id,
      userId: t.userId,
      startDate: t.startDate,
      endDate: t.endDate,
      recruitmentPlanId: t.recruitmentPlanId,
      recruitmentPlanDesc: t.recruitmentPlanDesc,
      monitoringPeriodicity: this.mapMonitoringPeriodicity(t.monitoringPeriodicity),
      creditOpinionPrice: t.creditOpinionPrice,
      isLow: t.isLow,
      deleteIsDisabled: this.mapDeleteIsDisabled(t.isLow, t.startDate)
    } as IUserDataHistoryViewModel));
  }

  private static mapMonitoringPeriodicity(period?: MonitoringPeriod | null) {
    if (typeof period === 'undefined' || period === null) {
      return '';
    }

    return MONITORING_PERIOD_VALUES.find((x) => x.key === period)?.value;
  }

  private static mapDeleteIsDisabled(isLow?: boolean | null, startDate?: Date | null) {
    if (typeof isLow !== 'undefined' && isLow !== null && isLow === true) {
      return true;
    }

    if (typeof startDate !== 'undefined' && startDate !== null) {
      const startDateMom = moment(startDate).toDate();
      const actualDate = new Date();
      actualDate.setHours(0, 0, 0, 0);
      if (startDateMom < actualDate) {
        return true;
      }
     }
    return false;
  }
}
