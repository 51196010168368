import { IDebtor } from 'src/app/core/models/api/monitoring';
import { IDebtorViewModel } from '../models';


export abstract class DebtorMapper {
  static mapForSearch(
    source: IDebtor
  ): IDebtorViewModel {
    return {
      id: source.id,
      fiscalCode: source.fiscalCode,
      businessName: source.businessName,
      ownIdentifier: source.ownIdentifier,
      requestedAmount: source.requestedAmount,
      monitorable: !source.notMonitorable
    } as IDebtorViewModel;
  }
}
