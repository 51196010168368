import { Component, ViewChild } from '@angular/core';
import { FileUploadComponent, HowdenAlertService } from '@howdeniberia/core-front';
import { ContractCheckContract } from 'src/app/core/services/api/contract-check';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { FileUtils, FormGroupUtils } from 'src/app/shared/utils';
import { ContractCheckViewModel } from '../models/contract-check.viewmodel';


@Component({
  selector: 'howden-contract-check',
  templateUrl: './contract-check.component.html',
  styleUrls: ['./contract-check.component.scss']
})
export class ContractCheckComponent {
  model = new ContractCheckViewModel();

  @ViewChild('contractTemplate') contractTemplateRef!: FileUploadComponent;

  constructor(
    private uiBlockerSrv: UIBlockerContract,
    private alertSrv: HowdenAlertService,
    private contractCheckSrv: ContractCheckContract

  ) { }

  onContractTemplateSelected(): void {
    if (this.contractTemplateRef.file?.type === 'application/pdf') {
      this.model.form.patchValue({
        [this.model.C_CONTRACT_FILE]: this.contractTemplateRef.file,
        [this.model.C_CONTRACT_NAME]: this.contractTemplateRef.fileName
      });
    } else {
      const title: string = $localize`: @@app.user-data.edit.contract-template-file.title:Plantilla de Contrato`;
      const message: string = $localize`: @@app.user-data.edit.contract-template-file.message:Debe seleccionar un archivo pdf`;
      this.alertSrv.error(title, message);
      this.model.form.get(this.model.C_CONTRACT_FILE)?.setValue(null);
      this.contractTemplateRef?.clearFiles();
    }
  }

  onAccept(): void {
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.contractCheckSrv.check(request).subscribe(
      {
        next: (blob: Blob) => {
          FileUtils.downloadFile(
            this.model.contractName as string,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }
}
