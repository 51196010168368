import { KeyValue } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IHowdenColumnConfig,
  ITableButtonConfig,
  ITableEventData
} from '@howdeniberia/core-front';
import { DATA_STATUS_VALUES } from 'src/app/core/constants';
import { DataStatus } from 'src/app/core/enums';
import { IUserRole } from 'src/app/core/models/api/master';
import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { IUserDataFilter } from 'src/app/core/models/api/user-data';
import { IUserDataViewModel } from '.';
export class UserDataSearchViewModel {
  private _filterForm: FormGroup;
  private _roles: Array<IUserRole> = [];
  private _recruitmentPlans: Array<IRecruitmentPlan> = [];
  private _statusValues: KeyValue<DataStatus, string>[] = DATA_STATUS_VALUES;
  private _searchRequest: IUserDataFilter;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_USER_EMAIL = 'email';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_ROLE_NAME = 'roleName';
  readonly C_RECRUITMENT_PLAN = 'recruitmentPlan';
  readonly C_STATUS = 'status';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_FISCAL_CODE]: new FormControl(null, []),
      [this.C_BUSINESS_NAME]: new FormControl(null, []),
      [this.C_USER_EMAIL]: new FormControl(null, []),
      [this.C_ROLE_NAME]: new FormControl(null, []),
      [this.C_RECRUITMENT_PLAN]: new FormControl(null, []),
      [this.C_STATUS]: new FormControl(null, [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IUserDataFilter;
  }

  data: IUserDataViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'email',
      fieldHeader: $localize`:@@app.user-data.search.email.column:Email`,
      canSort: true
    },
    {
      fieldName: 'roleDescription',
      fieldHeader: $localize`:@@app.user-data.search.roleName.column:Rol`
    },
    {
      fieldName: 'userInfo',
      fieldHeader: $localize`:@@app.user-data.search.userInfo.column:Razón social / Nombre`
    },
    {
      fieldName: 'association',
      fieldHeader: $localize`:@@app.user-data.search.association.column:Asociación`
    },
    {
      fieldName: 'contact',
      fieldHeader: $localize`:@@app.user-data.search.contact.column:Contacto`
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.user-data.search.status.column:Estado`,
      canSort: true,
      width: '5%'
    }
  ];

  buttons: Array<ITableButtonConfig> = [];

  get roles(): Array<IUserRole> {
    return this._roles;
  }

  set roles(value: Array<IUserRole>) {
    this._roles = value;
  }

  get recruitmentPlans(): Array<IRecruitmentPlan> {
    return this._recruitmentPlans;
  }

  set recruitmentPlans(value: Array<IRecruitmentPlan>) {
    this._recruitmentPlans = value;
  }

  get length(): number {
    return this._length;
  }

  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get statusValues(): KeyValue<DataStatus, string>[] {
    return this._statusValues;
  }

  get searchRequest(): IUserDataFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get userEmail(): string | null | undefined {
    return this.filterForm.get(this.C_USER_EMAIL)?.value;
  }

  set userEmail(value: string | null | undefined) {
    this.filterForm.get(this.C_USER_EMAIL)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this.filterForm.get(this.C_FISCAL_CODE)?.value;
  }

  set fiscalCode(value: string | null | undefined) {
    this.filterForm.get(this.C_FISCAL_CODE)?.setValue(value);
  }

  get businessName(): string | null | undefined {
    return this.filterForm.get(this.C_BUSINESS_NAME)?.value;
  }

  set businessName(value: string | null | undefined) {
    this.filterForm.get(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get roleName(): string | null | undefined {
    return this.filterForm.get(this.C_ROLE_NAME)?.value;
  }

  set roleName(value: string | null | undefined) {
    this.filterForm.get(this.C_ROLE_NAME)?.setValue(value);
  }

  get recruitmentPlan(): number | null | undefined {
    return this._filterForm.get(this.C_RECRUITMENT_PLAN)?.value;
  }

  set recruitmentPlan(value: number | null | undefined) {
    this._filterForm.get(this.C_RECRUITMENT_PLAN)?.setValue(value);
  }

  get status(): number | null | undefined {
    return this._filterForm.get(this.C_STATUS)?.value;
  }

  set status(value: number | null | undefined) {
    this._filterForm.get(this.C_STATUS)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.roleName = this.roleName;
    this.searchRequest.email = this.userEmail;
    this.searchRequest.fiscalCode = this.fiscalCode;
    this.searchRequest.businessName = this.businessName;
    this.searchRequest.recruitmentPlanId = this.recruitmentPlan;
    this.searchRequest.status = this.status;
  }
}
