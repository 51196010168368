import { formatDate } from '@angular/common';

export class FileUtils {
  static formatFilename(prefix: string, ext = 'xlsx'): string {
    const datetime = formatDate(new Date(), 'yyyyMMddHHmmss', 'es');
    const filename = `${prefix}_${datetime}.${ext}`;

    return filename;
  }

  static downloadFile(filename: string, data: Blob) {
    const a = document.createElement('a');
    a.setAttribute('tabindex', '-1');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = filename;
    a.href = URL.createObjectURL(data);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
}
