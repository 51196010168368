import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HowdenAuthService } from '@howdeniberia/core-front';
import { MenuHelperService } from 'src/app/core/helpers';

@Component({
  selector: 'howden-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private menuHelper: MenuHelperService,
    private authSrv: HowdenAuthService) {
  }

  async ngOnInit() {
    if (this.authSrv.isLogued()) {
      const hasCreditOpinion = await this.menuHelper.checkCreditOpinionOption();
      const hasMonitoring = await this.menuHelper.checkMonitoringOption();
      if (hasCreditOpinion) {
        this.router.navigate(['/credit-opinion']);
      } else if (hasMonitoring) {
        this.router.navigate(['/monitoring/debtor-monitoring']);
      } else {
        // stays on home
      }
    }
  }
}
