import { Component, OnDestroy, OnInit } from '@angular/core';
import { IDashboardBusinessEvolution, IDashboardBusinessUser } from 'src/app/core/models/api/dashboard';
import { DashboardContract } from 'src/app/core/services/api/dashboard';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { BusinessEvolutionViewModel } from '../../models/business-evolution.viewmodel';

@Component({
  selector: 'howden-business-evolution',
  templateUrl: './business-evolution.component.html',
  styleUrls: ['./business-evolution.component.scss']
})
export class BusinessEvolutionComponent implements OnInit, OnDestroy {
  model = new BusinessEvolutionViewModel();

  userSelected = 0;

  private _subscriptions = new SubSink();

  get minYear(): number { return Limits.minYear; }

  constructor(
    private dashboardSrv: DashboardContract,
    private uiBlockerSrv: UIBlockerContract
  ) { }

  ngOnInit(): void {
    this.refresh();
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onRowClicked(event: IDashboardBusinessUser): void {
    const userId = event.userId as number;
    if (userId === this.userSelected) {
      this.userSelected = 0;
      this.model.actualKpis = this.model.totalKpis;
    } else {
      this.userSelected = userId;
      this.model.actualKpis = event.kpi;
    }
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.refresh();
    });
  }

  private refresh(): void {
    if (this.model.filterForm.valid) {
      this.uiBlockerSrv.block();
      this.model.updateServerSideFilters();

      this.dashboardSrv.getBusinessEvolution(this.model.searchRequest).subscribe({
        next: (result: IDashboardBusinessEvolution) => {
          this.model.totalKpis = result.kpi;
          this.model.actualKpis = result.kpi;
          this.model.dataDashboardBusinessUser = result.usersData;
          this.userSelected = 0;
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      });
    } else {
      this.model.totalKpis = this.model.resetTotalKpis;
      this.model.actualKpis = this.model.resetActualKpis;
      this.model.dataDashboardBusinessUser = [];
      this.userSelected = 0;
    }
  }
}
