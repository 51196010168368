import { FormGroup } from '@angular/forms';
import moment from 'moment';

export class FormGroupUtils {
  static convertToFormData(group: FormGroup) {
    const formData = new FormData();
    for (const key of Object.keys(group.value)) {
      const value: any = group.value[key];

      if (typeof value !== 'undefined' && value !== null) {
        if (moment.isMoment(value) || value instanceof Date) {
          formData.append(key, moment(value).toISOString());
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  }
}
