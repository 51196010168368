import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FiscalCodeType } from 'src/app/core/enums';
import { ICountry } from 'src/app/core/models/api/country';

import { Limits } from 'src/app/shared/utils';

export class DebtorCreditGrantedEditViewModel {
  private _form: FormGroup;
  private _countries: Array<ICountry> = [];

  readonly C_ID = 'id';
  readonly C_DEBTOR_ID = 'debtorId';
  readonly C_USER_ID = 'userId';
  readonly C_FISCAL_CODE_TYPE = 'fiscalCodeType';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_COUNTRY_ID = 'countryId';
  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_REQUESTED_AMOUNT = 'requestedAmount';
  readonly C_MONITORING_DATE = 'monitoringDate';
  readonly C_LAST_GRANTED_AMOUNT = 'lastGrantedAmount';
  readonly C_GRANTED_AMOUNT = 'grantedAmount';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(),
      [this.C_DEBTOR_ID]: new FormControl(),
      [this.C_USER_ID]: new FormControl(),
      [this.C_FISCAL_CODE_TYPE]: new FormControl(null, [Validators.required]),
      [this.C_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COUNTRY_ID]: new FormControl(null, []),
      [this.C_BUSINESS_NAME]: new FormControl('', [Validators.required]),
      [this.C_REQUESTED_AMOUNT]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]),
      [this.C_MONITORING_DATE]: new FormControl(),
      [this.C_LAST_GRANTED_AMOUNT]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]),
      [this.C_GRANTED_AMOUNT]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)])
    });
  }

  get countries(): Array<ICountry> {
    return this._countries;
  }
  set countries(value: Array<ICountry>) {
    this._countries = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get debtorId(): number | null | undefined {
    return this._form.get(this.C_DEBTOR_ID)?.value;
  }
  set debtorId(value: number | null | undefined) {
    this._form.get(this.C_DEBTOR_ID)?.setValue(value);
  }

  get userId(): number | null | undefined {
    return this._form.get(this.C_USER_ID)?.value;
  }
  set userId(value: number | null | undefined) {
    this._form.get(this.C_USER_ID)?.setValue(value);
  }

  get fiscalCodeType(): FiscalCodeType | null | undefined {
    return this._form.get(this.C_FISCAL_CODE_TYPE)?.value;
  }
  set fiscalCodeType(value: FiscalCodeType | null | undefined) {
    this._form.get(this.C_FISCAL_CODE_TYPE)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this._form.get(this.C_FISCAL_CODE)?.value;
  }
  set fiscalCode(value: string | null | undefined) {
    this._form.get(this.C_FISCAL_CODE)?.setValue(value);
  }

  get countryId(): number | null | undefined {
    return this._form.get(this.C_COUNTRY_ID)?.value;
  }
  set countryId(value: number | null | undefined) {
    this._form.get(this.C_COUNTRY_ID)?.setValue(value);
  }

  get businessName(): string | null | undefined {
    return this._form.get(this.C_BUSINESS_NAME)?.value;
  }
  set businessName(value: string | null | undefined) {
    this._form.get(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get requestedAmount(): number | null | undefined {
    return this._form.get(this.C_REQUESTED_AMOUNT)?.value;
  }
  set requestedAmount(value: number | null | undefined) {
    this._form.get(this.C_REQUESTED_AMOUNT)?.setValue(value);
  }

  get lastGrantedAmount(): string | null | undefined {
    return this._form.get(this.C_LAST_GRANTED_AMOUNT)?.value;
  }
  set lastGrantedAmount(value: string | null | undefined) {
    this._form.get(this.C_LAST_GRANTED_AMOUNT)?.setValue(value);
  }

  get grantedAmount(): number | null | undefined {
    return this._form.get(this.C_GRANTED_AMOUNT)?.value;
  }
  set grantedAmount(value: number | null | undefined) {
    this._form.get(this.C_GRANTED_AMOUNT)?.setValue(value);
  }

  get monitoringDate(): Date | null | undefined {
    return this._form.get(this.C_MONITORING_DATE)?.value;
  }
  set monitoringDate(value: Date | null | undefined) {
    this._form.get(this.C_MONITORING_DATE)?.setValue(value);
  }
}
