import { CREDIT_OPINION_SITUATION_VALUES } from 'src/app/core/constants';
import { CreditOpinionSituation } from 'src/app/core/enums';
import { ICreditOpinion } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionEditViewModel } from '../models';
import { ICreditOpinionViewModel } from '../models/credit-opinion.viewmodel';


export abstract class CreditOpinionMapper {
  static mapForEdit(source: ICreditOpinion, target: CreditOpinionEditViewModel): void {
    let requestUserInfo = `Usuario Administrador (id ${source.requestUserId})`;
    if (source.requestUserFiscalCode) {
      requestUserInfo = source.requestUserBussinessName as string;
    }
    let requestUserContact = '';
    if (source.requestUserContactName) {
      requestUserContact = source.requestUserContactName;
    }
    if (source.requestUserContactEmail) {
      requestUserContact = `${requestUserContact} (${source.requestUserContactEmail})`;
    }

    let resquestUserAssociation = '';
    if (source.associationBussinessName) {
      resquestUserAssociation = source.associationBussinessName as string;
    }

    let responseUserNameSurname = '';
    if (source.responseUserName) {
      responseUserNameSurname = source.responseUserName;
    }
    if (source.responseUserSurname) {
      responseUserNameSurname = `${responseUserNameSurname} ${source.responseUserSurname}`;
    }

    target.id = source.id;
    target.userRequestId = source.requestUserId;
    target.userRequestBussinessName = requestUserInfo;
    target.userRequestEmail = source.requestUserEmail;
    target.userRequestContact = requestUserContact;
    target.userRequestAssociation = resquestUserAssociation;
    target.companyAssessFiscalCodeType = source.companyAssessFiscalCodeType;
    target.companyAssessFiscalCode = source.companyAssessFiscalCode;
    target.companyAssessCountryId = source.companyAssessCountryId;
    target.companyAssessName = source.companyAssessName;
    target.requestDate = source.requestDate;
    target.responseDate = source.responseDate;
    target.userResponseNameSurname = responseUserNameSurname;
    target.userResponseEmail = source.responseUserEmail;
    target.requestedAmount = source.requestedAmount;
    target.grantedAmount = source.grantedAmount;
    target.lastGrantedAmount = source.lastGrantedAmount;
    target.companyAssessConstYear = source.companyAssessConstYear;
    target.companyAssessAddress = source.companyAssessAddress;
    target.creditLimit = source.creditLimit;
    target.rai = source.rai;
    target.unpaid = source.unpaid;
    target.judicialIncidents = source.judicialIncidents;
    target.insolvencyProceduresOthers = source.insolvencyProceduresOthers;
    target.administrativeClaims = source.administrativeClaims;
    target.observations = source.observations;
    target.evaluationActivity = source.evaluationActivityId;
    target.annualAccounts = source.annualAccountsId;
    target.longTermDebtEvaluation = source.longTermDebtEvaluationId;
    target.shortTermDebtEvaluation = source.shortTermDebtEvaluationId;
    target.situation = source.situation;
    target.grade = source.grade;
  }

  static mapForSave(source: CreditOpinionEditViewModel): ICreditOpinion {
    return {
      id: source.id,
      requestUserId: source.userRequestId,
      companyAssessFiscalCodeType: source.companyAssessFiscalCodeType,
      companyAssessFiscalCode: source.companyAssessFiscalCode,
      companyAssessCountryId: source.companyAssessCountryId,
      companyAssessName: source.companyAssessName,
      companyAssessConstYear: source.companyAssessConstYear,
      companyAssessAddress: source.companyAssessAddress,
      requestDate: source.requestDate,
      responseDate: source.responseDate,
      requestedAmount: source.requestedAmount,
      grantedAmount: source.grantedAmount,
      creditLimit: source.creditLimit,
      grade: source.grade,
      rai: source.rai,
      unpaid: source.unpaid,
      judicialIncidents: source.judicialIncidents,
      administrativeClaims: source.administrativeClaims,
      insolvencyProceduresOthers: source.insolvencyProceduresOthers,
      observations: source.observations,
      evaluationActivityId: source.evaluationActivity,
      annualAccountsId: source.annualAccounts,
      longTermDebtEvaluationId: source.longTermDebtEvaluation,
      shortTermDebtEvaluationId: source.shortTermDebtEvaluation,
      situation: source.situation
    } as ICreditOpinion;
  }

  static mapForSearch(
    source: ICreditOpinion,
    isClientProfile: boolean,
    situation: CreditOpinionSituation | null | undefined
  ): ICreditOpinionViewModel {
    let requestUserInfo = `Usuario Administrador (id ${source.requestUserId})`;
    if (source.requestUserFiscalCode) {
      requestUserInfo = `${source.requestUserBussinessName} [${source.requestUserFiscalCode}]`;
    }
    let association = '-';
    if (source.associationFiscalCode && source.associationBussinessName) {
      association = `${source.associationBussinessName} [${source.associationFiscalCode} ]`;
    }

    let showCheck = false;
    if (isClientProfile && situation
      && (situation === CreditOpinionSituation.Answered)
      && situation === source.situation) {
      showCheck = true;
    }

    return {
      showCheck: showCheck,
      id: source.id,
      requester: requestUserInfo,
      companyAssessFiscalCode: source.companyAssessFiscalCode,
      companyAssessDesc: `${source.companyAssessName} [${source.companyAssessFiscalCode}]`,
      association: association,
      requestDate: source.requestDate,
      responseDate: source.responseDate,
      requestedAmount: source.requestedAmount,
      grantedAmount: source.grantedAmount,
      situationId: source.situation,
      situation: CreditOpinionMapper.mapSituation(source.situation)
    } as ICreditOpinionViewModel;
  }

  private static mapSituation(situation?: CreditOpinionSituation | null) {
    if (typeof situation === 'undefined' || situation === null) {
      return '';
    }

    return CREDIT_OPINION_SITUATION_VALUES.find((x) => x.key === situation)?.value;
  }
}
