export class Limits {
  static get zero(): number {
    return 0;
  }
  static get greaterThanZero(): number {
    return 1;
  }
  static get minDecimal(): number {
    return -922337203685477;
  }
  static get maxDecimal(): number {
    return 922337203685477;
  }
  static get maxInt16(): number {
    return 32767;
  }
  static get maxInt32(): number {
    return 2147483647;
  }
  static get minDate(): Date {
    return new Date(1900, 0, 1);
  }
  static get minYear(): number {
    return 1900;
  }
}
