import { EventEmitter, Injectable } from '@angular/core';
import { EventEmitterContract } from '.';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService implements EventEmitterContract {
  editableCreditOpinionEmit = new EventEmitter<boolean>;

  setEditableCreditOpinion(editable: boolean) {
    this.editableCreditOpinionEmit.emit(editable);
  }
}
