import { KeyValue } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MONITORING_PERIOD_VALUES } from 'src/app/core/constants';
import { MonitoringPeriod } from 'src/app/core/enums';
import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { dateRangeValidator, endDateMonthMatchValidator, startDateMonthMatchValidator } from 'src/app/shared/validators';

export class UserDataHistoryDialogViewModel {
  private _form: FormGroup;
  private _recruitmentPlans: Array<IRecruitmentPlan> = [];
  private _monitoringPeriodValues: KeyValue<MonitoringPeriod, string>[] = MONITORING_PERIOD_VALUES;

  readonly C_ID = 'id';
  readonly C_USER_ID = 'userId';
  readonly C_START_DATE = 'starDate';
  readonly C_END_DATE = 'endDate';
  readonly C_RECRUITMENT_PLAN = 'recruitmentPlanId';
  readonly C_MONITORING_PERIODICITY = 'monitoringPeriodicity';
  readonly C_CREDIT_OPINION_PRICE = 'creditOpinionPrice';
  readonly C_IS_LOW = 'isLow';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, []),
      [this.C_USER_ID]: new FormControl(null, [Validators.required]),
      [this.C_START_DATE]: new FormControl(null, [Validators.required]),
      [this.C_END_DATE]: new FormControl(null, []),
      [this.C_RECRUITMENT_PLAN]: new FormControl(null, [Validators.required]),
      [this.C_MONITORING_PERIODICITY]: new FormControl(null, []),
      [this.C_CREDIT_OPINION_PRICE]: new FormControl(null, []),
      [this.C_IS_LOW]: new FormControl(null, [])
    }, {
      validators: [
        startDateMonthMatchValidator(this.C_START_DATE),
        endDateMonthMatchValidator(this.C_END_DATE),
        dateRangeValidator(this.C_START_DATE, this.C_END_DATE)
      ]
    });
  }

  get recruitmentPlans(): Array<IRecruitmentPlan> {
    return this._recruitmentPlans;
  }
  set recruitmentPlans(recruitmentPlans: Array<IRecruitmentPlan>) {
    this._recruitmentPlans = recruitmentPlans;
  }

  get monitoringPeriodValues(): KeyValue<MonitoringPeriod, string>[] {
    return this._monitoringPeriodValues;
  }
  set monitoringPeriodValues(monitoringPeriodValues: KeyValue<MonitoringPeriod, string>[]) {
    this._monitoringPeriodValues = monitoringPeriodValues;
  }


  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get userId(): number | null | undefined {
    return this._form.get(this.C_USER_ID)?.value;
  }
  set userId(value: number | null | undefined) {
    this._form.get(this.C_USER_ID)?.setValue(value);
  }

  get starDate(): Date | null | undefined {
    return this._form.get(this.C_START_DATE)?.value;
  }
  set starDate(value: Date | null | undefined) {
    this._form.get(this.C_START_DATE)?.setValue(value);
  }

  get endDate(): Date | null | undefined {
    return this._form.get(this.C_END_DATE)?.value;
  }
  set endDate(value: Date | null | undefined) {
    this._form.get(this.C_END_DATE)?.setValue(value);
  }

  get recruitmentPlan(): number | null | undefined {
    return this._form.get(this.C_RECRUITMENT_PLAN)?.value;
  }
  set recruitmentPlan(value: number | null | undefined) {
    this._form.get(this.C_RECRUITMENT_PLAN)?.setValue(value);
  }

  get monitoringPeriodicity(): MonitoringPeriod | null | undefined {
    return this._form.get(this.C_MONITORING_PERIODICITY)?.value;
  }
  set monitoringPeriodicity(value: MonitoringPeriod | null | undefined) {
    this._form.get(this.C_MONITORING_PERIODICITY)?.setValue(value);
  }

  get creditOpinionPrice(): number | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_PRICE)?.value;
  }
  set creditOpinionPrice(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_PRICE)?.setValue(value);
  }

  get isLow(): boolean | null | undefined {
    return this._form.get(this.C_IS_LOW)?.value;
  }
  set isLow(value: boolean | null | undefined) {
    this._form.get(this.C_IS_LOW)?.setValue(value);
  }
}
