import { EnvironmentInjector, NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from 'src/app/core/enums';
import { CreditOpinionOptionGuard } from 'src/app/core/guards/can-activate';
import { MenuHelperService } from 'src/app/core/helpers';
import { CreditOpinionEditComponent } from './pages/credit-opinion-edit/credit-opinion-edit.component';
import { CreditOpinionSearchComponent } from './pages/credit-opinion-search/credit-opinion-search.component';

export const CreditOpinionRoutes = [
  {
    canActivate: [CreditOpinionOptionGuard],
    path: 'credit-opinion',
    component: CreditOpinionSearchComponent,
    title: $localize`:@@app.route.credit-opinion:Solicitudes Opinión Crédito`,
    data: {
      isVisible: async function (ei: EnvironmentInjector) {
        return await ei.runInContext(async () => {
          const menuHelper = inject(MenuHelperService);
          const isVisible = await menuHelper.checkCreditOpinionOption();
          return isVisible;
        });
      },
      icon: 'account_balance',
      linkText: $localize`:@@app.route.credit-opinion:Opinión Crédito`
    }
  },
  {
    path: 'credit-opinion/edit/:id',
    component: CreditOpinionEditComponent,
    title: $localize`:@@app.route.credit-opinion.edit:Valoración de Opinión de Crédito`,
    data: {
      roles: [UserRole.SuperAdmin, UserRole.Administrator, UserRole.Evaluator]
    }
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(CreditOpinionRoutes)],
  exports: [RouterModule]
})
export class CreditOpinionRoutingModule { }
