import { NgModule } from '@angular/core';
import { DashboardContract } from 'src/app/core/services/api/dashboard/dashboard.contract';
import { DashboardService } from 'src/app/core/services/api/dashboard/dashboard.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { BusinessEvolutionComponent } from './pages/business-evolution/business-evolution.component';
import { CreditOpinionHistoryComponent } from './pages/credit-opinion-history/credit-opinion-history.component';


@NgModule({
  imports: [DashboardRoutingModule, SharedModule],
  declarations: [
    CreditOpinionHistoryComponent,
    BusinessEvolutionComponent
  ],
  providers: [
    { provide: DashboardContract, useClass: DashboardService }
  ]
})
export class DashboardModule { }
