<div class="flex f-fd--column">
  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-top-20">
      <div class="f-basis--3">
        <howden-page-title></howden-page-title>
      </div>
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.dashboard.business-evolution.filter.month">Mes</mat-label>
        <mat-select
          [formControlName]="model.C_MONTH"
          [howdenControlError]="monthError">
          <mat-option></mat-option>
          <mat-option *ngFor="let item of model.monthsValues" [value]="item.key">
            {{ item.value }}
          </mat-option>
        </mat-select>
        <mat-error #monthError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.dashboard.business-evolution.filter.year">Año</mat-label>
        <input matInput
          [formControlName]="model.C_YEAR"
          [howdenControlError]="yearError"
          [min]="minYear"
          type="number"/>
        <mat-error #yearError></mat-error>
      </mat-form-field>
    </div>
    <mat-divider class="flex f-gap--10px margin-top-10"></mat-divider>
  </form>

<div class="flex f-fd--column">
  <div class="flex f-gap--10px">
    <div class="f-basis--6">
      <div class="flex f-gap--10px margin-left-30">
        <div class="f-basis--12">
          <h2 i18n="@@app.dashboard.business-evolution.requests.title">
            Solicitudes
          </h2>
        </div>
      </div>
      <div class="flex f-gap--10px margin-bottom-10">
        <div class="f-basis--3">
          <howden-kpi-indicator
            [value]='model.actualKpis.requestsMonthly'
            title="Mensuales"
            dataType="numeric">
          </howden-kpi-indicator>
        </div>
        <div class="f-basis--3">
          <howden-kpi-indicator
            [value]='model.actualKpis.requestsAccumulated'
            title="Acumuladas"
            dataType="numeric">
          </howden-kpi-indicator>
        </div>
        <div class="f-basis--3">
          <howden-kpi-indicator
            [value]='model.actualKpis.requestsExercise'
            title="Total ejercicio"
            dataType="numeric">
          </howden-kpi-indicator>
        </div>
        <div class="f-basis--3">
          <howden-kpi-indicator
            [value]='model.actualKpis.requestsYear'
            title="Total año"
            dataType="numeric">
          </howden-kpi-indicator>
        </div>
      </div>
    </div>
    <mat-divider [vertical]="true" class="margin-top-10"></mat-divider>
    <div  class="f-basis--6">
      <div class="flex f-gap--10px margin-left-30">
        <div  class="f-basis--12">
          <h2 i18n="@@app.dashboard.business-evolution.income.title">
            Ingresos
          </h2>
        </div>
      </div>
      <div class="flex f-gap--10px margin-bottom-10">
        <div class="f-basis--4">
          <howden-kpi-indicator
            [value]='model.actualKpis.incomeMonthly'
            title="Mensuales"
            dataType="currency">
          </howden-kpi-indicator>
        </div>
        <div class="f-basis--4">
          <howden-kpi-indicator
            [value]='model.actualKpis.incomeAccumulated'
            title="Acumulados"
            dataType="currency">
          </howden-kpi-indicator>
        </div>
        <div class="f-basis--4">
          <howden-kpi-indicator
            [value]='model.actualKpis.incomeExercise'
            title="Total ejercicio"
            dataType="currency">
          </howden-kpi-indicator>
        </div>
      </div>
    </div>
  </div>

  <mat-divider class="flex f-gap--10px margin-top-10"></mat-divider>
  <div class="flex f-gap--10px margin-left-30">
    <div class="f-basis--12">
      <h2 i18n="@@app.dashboard.business-evolution.applicants.filter.title">
        Solicitantes <span style="font-size: 0.7em;">(para el mes y año seleccionados)</span>
      </h2>
    </div>
  </div>
  <div class="flex f-gap--10px">
    <div class="f-basis--12">
      <howden-table
        class="small-font"
        paginable="local"
        sortable="local"
        filterable="global-local"
        tableFixedHeight="40vh"
        [clickableRow]="true"
        [highlightRow]="true"
        [cols]="model.columnsDashboardBusinessUser"
        [data]="model.dataDashboardBusinessUser"
        [pageSize]="10"
        (rowClicked)="onRowClicked($event)">
      </howden-table>
    </div>
  </div>
</div>
