import { SIGNATURE_STATUS_VALUES, USER_REQUEST_STATUS_VALUES } from 'src/app/core/constants';
import { SignatureStatus, UserRequestStatus } from 'src/app/core/enums';
import { IUserRequest } from 'src/app/core/models/api/user-request';
import { IUserRequestViewModel, UserRequestEditViewModel } from '../models';

export abstract class UserRequestMapper {
  static mapForEdit(source: IUserRequest, target: UserRequestEditViewModel): void {
    target.id = source.id;
    target.fiscalCodeType = source.fiscalCodeType;
    target.fiscalCode = source.fiscalCode;
    target.name = source.name;
    target.surname = source.surname;
    target.email = source.email;
    target.companyFiscalCodeType = source.companyFiscalCodeType;
    target.companyFiscalCode = source.companyFiscalCode;
    target.companyName = source.companyName;
    target.companyAddress = source.companyAddress;
    target.companyActivity = source.companyActivity;
    target.recruitmentPlanId = source.recruitmentPlanId;
    target.associationId = source.associationId;
    target.associationDesc = source.associationDesc;
    target.recruitmentManagerUsername = source.recruitmentManagerUsername;
    target.status = source.status;
    target.signatureIdentifier = source.signatureIdentifier;
    target.signatureStatus = source.signatureStatus;
  }

  static mapForSave(source: UserRequestEditViewModel): IUserRequest {
    return {
      id: source.id,
      fiscalCodeType: source.fiscalCodeType,
      fiscalCode: source.fiscalCode,
      name: source.name,
      surname: source.surname,
      email: source.email,
      companyFiscalCodeType: source.companyFiscalCodeType,
      companyFiscalCode: source.companyFiscalCode,
      companyName: source.companyName,
      companyAddress: source.companyAddress,
      companyActivity: source.companyActivity,
      recruitmentPlanId: source.recruitmentPlanId,
      associationId: source.associationId,
      recruitmentManagerUsername: source.recruitmentManagerUsername,
      status: source.status,
      signatureIdentifier: source.signatureIdentifier,
      signatureStatus: source.signatureStatus
    } as IUserRequest;
  }

  static mapForSearch(source: IUserRequest): IUserRequestViewModel {
    return {
      id: source.id,
      fiscalCode: source.fiscalCode,
      name: source.name,
      surname: source.surname,
      email: source.email,
      companyFiscalCode: source.companyFiscalCode,
      companyName: source.companyName,
      recruitmentPlanDesc: source.recruitmentPlanDesc,
      recruitmentManager: source.recruitmentManagerUsername,
      signatureStatus: UserRequestMapper.mapSignatureStatus(source.signatureStatus),
      status: UserRequestMapper.mapStatus(source.status)
    } as IUserRequestViewModel;
  }

  private static mapSignatureStatus(status?: SignatureStatus | null) {
    if (typeof status === 'undefined' || status === null) {
      return '';
    }
    return SIGNATURE_STATUS_VALUES.find((x) => x.key === status)?.value;
  }

  private static mapStatus(status?: UserRequestStatus | null) {
    if (typeof status === 'undefined' || status === null) {
      return '';
    }
    return USER_REQUEST_STATUS_VALUES.find((x) => x.key === status)?.value;
  }
}
