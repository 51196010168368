import { KeyValue } from '@angular/common';
import { SignatureStatus } from '../enums';

export const SIGNATURE_STATUS_VALUES: KeyValue<SignatureStatus, string>[] = [
  {
    key: SignatureStatus.SignPending,
    value: $localize`:@@app.route.signature.status.enums.pending:Pendiente Firma`
  },
  {
    key: SignatureStatus.SignDone,
    value: $localize`:@@app.route.signature.status.enums.done:Firma Realizada`
  },
  {
    key: SignatureStatus.SignDeclined,
    value: $localize`:@@app.route.signature.status.enums.declined:Firma Declinada`
  },
  {
    key: SignatureStatus.SignExpired,
    value: $localize`:@@app.route.signature.status.enums.expired:Firma Caducada`
  },
  {
    key: SignatureStatus.SignRejected,
    value: $localize`:@@app.route.signature.status.enums.rejected:Firma Rechazada`
  }
] as KeyValue<SignatureStatus, string>[];
