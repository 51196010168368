import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { NgxEchartsModule } from 'ngx-echarts';
import { LocalStorageContract, LocalStorageService } from '../core/services/storage';
import { ConfirmationDialogContract, ConfirmationDialogService, EventEmitterContract, EventEmitterService, UIBlockerContract, UIBlockerService } from '../core/services/ui';
import { DashboardBarChartComponent, DashboardLineChartComponent, KpiIndicatorComponent } from './components';

@NgModule({
  declarations: [
    DashboardBarChartComponent,
    DashboardLineChartComponent,
    KpiIndicatorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatGridListModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    NgxEchartsModule,
    HowdenLabelValueModule,
    HowdenTableModule,
    HowdenPageTitleModule,
    HowdenSharedModule,
    HowdenAutocompleteModule,
    HowdenFileUploadModule,
    HowdenDecimalModule,
    HowdenAutocompleteModule
  ],
  providers: [
    { provide: LocalStorageContract, useClass: LocalStorageService },
    { provide: UIBlockerContract, useClass: UIBlockerService },
    { provide: ConfirmationDialogContract, useClass: ConfirmationDialogService },
    { provide: EventEmitterContract, useClass: EventEmitterService }
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatGridListModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    NgxEchartsModule,
    HowdenLabelValueModule,
    HowdenTableModule,
    HowdenPageTitleModule,
    HowdenSharedModule,
    HowdenFileUploadModule,
    HowdenDecimalModule,
    HowdenAutocompleteModule,
    DashboardBarChartComponent,
    DashboardLineChartComponent,
    KpiIndicatorComponent
  ]
})
export class SharedModule { }
