import { IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IRecruitmentPlan, IRecruitmentPlanFilter } from 'src/app/core/models/api/recruitment-plan';

export abstract class RecruitmentPlanContract {
  abstract search(request: IRecruitmentPlanFilter): Observable<Array<IRecruitmentPlan>>;
  abstract get(id: string): Observable<IRecruitmentPlan>;
  abstract save(request: FormData): Observable<IResponse<string>>;
  abstract downloadContractTemplate(id: string): Observable<Blob>;
}
