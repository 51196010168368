import { ColumnAligment, ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { ICreditOpinionAttachmentViewModel } from '.';

export class CreditOpinionEditAttachmentsViewModel {
  isEditable = true;
  data: ICreditOpinionAttachmentViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.credit-opinion.edit.attachments.description.column:Descripción`,
      canSort: true,
      width: '30%'
    },
    {
      fieldName: 'fileName',
      fieldHeader: $localize`:@@app.credit-opinion.edit.attachments.fileName.column:Nombre archivo`,
      canSort: true,
      width: '35%'
    },
    {
      fieldName: 'uploadDate',
      fieldHeader: $localize`:@@app.credit-opinion.edit.attachments.result.uploadDate:Fecha subida`,
      pipeToApply: ColumnPipeTypes.DATE,
      aligment: ColumnAligment.CENTER,
      canSort: true,
      width: '15%'
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'download',
    icon: 'download',
    color: TableButtonColors.INFO,
    tooltip: $localize`:@@app.credit-opinion.edit.attachments.actions.download.tooltip:Descargar Adjunto`
  },
  {
    name: 'delete',
    icon: 'delete_forever',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.credit-opinion.edit.attachments.actions.delete.tooltip:Eliminar Adjunto`,
    isDisabled: (): boolean => !this.isEditable
  }];
}
