import { GENERAL_CONSTANTS, HowdenApiEnviroment, IHowdenApiInfo } from '@howdeniberia/core-front';
import { API_PATH_APPS } from 'src/app/core/constants/api-path-apps.constants';

export const ApiConfigurations: IHowdenApiInfo[] = [
  {
    name: API_PATH_APPS.API_CREDIT_PORTAL,
    paths: {
      local: 'https://localhost:7198/',
      develop: 'https://localhost:7198/',
      staging: 'https://how-ar-apiserviciosinformacion-pre.azurewebsites.net/',
      production: 'https://how-ar-apiserviciosinformacion-pro.azurewebsites.net/',
      devHowdenArtai: 'https://localhost:7198/',
      preHowdenArtai: 'https://how-ar-apiserviciosinformacion-pre.azurewebsites.net/',
      proHowdenArtai: 'https://how-ar-apiserviciosinformacion-pro.azurewebsites.net/',
      devHowdenGroup: 'https://wizard-world-api.herokuapp.com/',
      preHowdenGroup: 'https://wizard-world-api.herokuapp.com/',
      proHowdenGroup: 'https://wizard-world-api.herokuapp.com/'
    },
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
    needsToken: true
  },
  {
    name: GENERAL_CONSTANTS.ApiSecurityName,
    paths: {
      local: 'https://how-ar-apisecurity-pre.azurewebsites.net',
      develop: 'https://how-ar-apisecurity-pre.azurewebsites.net',
      staging: 'https://how-ar-apisecurity-pre.azurewebsites.net',
      production: 'https://how-ar-apisecurity-pro.azurewebsites.net',
      devHowdenArtai: 'https://how-ar-apisecurity-pre.azurewebsites.net',
      preHowdenArtai: 'https://how-ar-apisecurity-pre.azurewebsites.net',
      proHowdenArtai: 'https://how-ar-apisecurity-pro.azurewebsites.net',
      devHowdenGroup: 'https://wizard-world-api.herokuapp.com',
      preHowdenGroup: 'https://wizard-world-api.herokuapp.com',
      proHowdenGroup: 'https://wizard-world-api.herokuapp.com'
    },
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
    needsToken: true
  }
] as IHowdenApiInfo[];
