import { KeyValue } from '@angular/common';

import { DataStatus } from '../enums';

export const DATA_STATUS_VALUES: KeyValue<DataStatus, string>[] = [
  {
    key: DataStatus.Active,
    value: $localize`:@@app.route.credit-opinion.user-status.enums.pending:Activo`
  },
  {
    key: DataStatus.Inactive,
    value: $localize`:@@app.route.credit-opinion.user-status.enums.accepted:Inactivo`
  }
] as KeyValue<DataStatus, string>[];
