import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, HowdenAlertService, HowdenAuthService, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { FiscalCodeType, UserRole } from 'src/app/core/enums';
import { CountryContract } from 'src/app/core/services/api/country';
import { DebtorContract } from 'src/app/core/services/api/debtor';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { DebtorEditMapper } from '../mappers';
import { DebtorEditViewModel } from '../models';

@Component({
  selector: 'howden-debtor-edit-dialog',
  templateUrl: './debtor-edit-dialog.component.html',
  styleUrls: ['./debtor-edit-dialog.component.scss']
})
export class DebtorEditDialogComponent implements OnInit, OnDestroy {
  model = new DebtorEditViewModel();
  isClientProfile = false;
  needCountry = false;

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<DebtorEditViewModel>,
    private authSrv: HowdenAuthService,
    private uiBlockerSrv: UIBlockerContract,
    private debtorSrv: DebtorContract,
    private countrySrv: CountryContract,
    private alertSrv: HowdenAlertService
  ) { }

  ngOnInit(): void {
    this.isClientProfile = this.authSrv.isUserAuthorized([
      UserRole.Association,
      UserRole.AssociationMember,
      UserRole.IndependentClient
    ]) && !this.authSrv.isSuperAdmin;

    if (this.debtorId !== 'undefined' && this.debtorId !== null) {
      this.uiBlockerSrv.block();
      forkJoin([
        this.debtorSrv.get(this.debtorId),
        this.countrySrv.getCountries()
      ]).subscribe(([debtor, countries]) => {
        DebtorEditMapper.mapForEdit(debtor, this.model);
        this.model.countries = countries;
        this.onEdit();
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.uiBlockerSrv.block();
      forkJoin([
        this.countrySrv.getCountries()
      ]).subscribe(([countries]) => {
        this.model.countries = countries;
        this.onFiscalCodeTypeChanges();
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    const request = DebtorEditMapper.mapForUpdate(this.model);

    this.debtorSrv
      .save(request)
      .pipe(
        tap((response) => {
          const msgResult = response.result as string;
          if (msgResult) {
            const title: string = $localize`: @@app.debtor.save.result.warning.title:¡Advertencia!`;
            const message: string = $localize`: @@app.debtor.save.result.warning.message:Deudor no modificado:`;
            this.alertSrv.warning(title, `${message} ${msgResult}`);
          } else {
            const title: string = $localize`: @@app.debtor.save.result.ok.title:Éxito!`;
            const message: string = $localize`: @@app.debtor.save.result.ok.message:Deudor guardado correctamente`;
            this.alertSrv.success(title, message);
          }
          this.uiBlockerSrv.unblock();
          this.closeDialog(DialogResultTypes.OK);
        })
      )
      .subscribe();
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private get debtorId(): string | null {
    return this.inputData.id as string | null;
  }

  private onEdit() {
    if (!this.isClientProfile) {
      this.model.form.get(this.model.C_FISCAL_CODE_TYPE)?.disable();
      this.model.form.get(this.model.C_FISCAL_CODE)?.disable();
      this.model.form.get(this.model.C_COUNTRY_ID)?.disable();
      this.model.form.get(this.model.C_BUSINESS_NAME)?.disable();
      this.model.form.get(this.model.C_OWN_IDENTIFIER)?.disable();
      this.model.form.get(this.model.C_REQUESTED_AMOUNT)?.disable();
      this.model.form.get(this.model.C_NOT_MONITORABLE)?.disable();
    }
    this.adaptCountry(this.model.fiscalCodeType);
  }

  private onFiscalCodeTypeChanges() {
    this._subscriptions.sink = this.model.form.get(this.model.C_FISCAL_CODE_TYPE)?.valueChanges.subscribe((value) => {
      this.adaptCountry(value);
    });
  }

  private adaptCountry(value: FiscalCodeType | null | undefined) {
    if (value === FiscalCodeType.Free) {
      this.model.form.get(this.model.C_COUNTRY_ID)?.enable();
      this.model.form.get(this.model.C_COUNTRY_ID)?.setValidators([Validators.required]);
      this.needCountry = true;
    } else {
      this.model.form.get(this.model.C_COUNTRY_ID)?.setValue(null);
      this.model.form.get(this.model.C_COUNTRY_ID)?.disable();
      this.model.form.get(this.model.C_COUNTRY_ID)?.setValidators([]);
      this.needCountry = false;
    }

    this.model.form.get(this.model.C_COUNTRY_ID)?.updateValueAndValidity();
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
