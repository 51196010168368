import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, FileUploadComponent, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { CreditOpinionAttachmentContract } from 'src/app/core/services/api/credit-opinion-attachment';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { CreditOpinionAttachmentMapper } from 'src/app/features/credit-opinion/mappers';
import { FormGroupUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { CreditOpinionAttachmentDialogViewModel } from '../../models';

@Component({
  selector: 'howden-credit-opinion-attachment-dialog',
  templateUrl: './credit-opinion-attachment-dialog.component.html',
  styleUrls: ['./credit-opinion-attachment-dialog.component.scss']
})
export class CreditOpinionAttachmentDialogComponent implements OnInit, OnDestroy {
  model = new CreditOpinionAttachmentDialogViewModel();

  private _subscriptions = new SubSink();

  @ViewChild('file') fileRef!: FileUploadComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<CreditOpinionAttachmentDialogViewModel>,
    private uiBlockerSrv: UIBlockerContract,
    private creditOpinionAttachSrv: CreditOpinionAttachmentContract
  ) { }

  ngOnInit(): void {
    if (this.creditOpinionAttachId !== 'undefined' && this.creditOpinionAttachId !== null) {
      this.uiBlockerSrv.block();
      forkJoin([
        this.creditOpinionAttachSrv.get(this.creditOpinionAttachId)
      ]).subscribe(([attach]) => {
        CreditOpinionAttachmentMapper.mapForEdit(attach, this.model);
        this.onEdit();
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.model.creditOpinionId = this.creditOpinionId;
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onFileSelected() {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef.file
    });
  }

  onAccept(): void {
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();
    this.creditOpinionAttachSrv.save(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  get creditOpinionAttachId(): string | null {
    return this.inputData.id as string | null;
  }
  private get creditOpinionId(): number | null {
    return this.inputData.creditOpinionId as number | null;
  }

  private onEdit() {
    this.model.form.get(this.model.C_FILENAME)?.disable();
    this.model.form.get(this.model.C_FILE)?.clearValidators();
    this.model.form.get(this.model.C_FILE)?.updateValueAndValidity();
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
