import { KeyValue } from '@angular/common';

import { UserRequestStatus } from '../enums';

export const USER_REQUEST_STATUS_VALUES: KeyValue<UserRequestStatus, string>[] = [
  {
    key: UserRequestStatus.Pending,
    value: $localize`:@@app.route.user-request.status.enums.pending:Pendiente`
  },
  {
    key: UserRequestStatus.Accepted,
    value: $localize`:@@app.route.user-request.status.enums.accepted:Aceptado`
  },
  {
    key: UserRequestStatus.Rejected,
    value: $localize`:@@app.route.user-request.status.enums.rejected:Rechazado`
  }
] as KeyValue<UserRequestStatus, string>[];
