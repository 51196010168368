import { DATA_STATUS_VALUES } from 'src/app/core/constants';
import { DataStatus } from 'src/app/core/enums';
import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { IRecruitmentPlanViewModel } from '../models';

export abstract class RecruitmentPlanMapper {
  static mapForSearch(source: IRecruitmentPlan): IRecruitmentPlanViewModel {
    return {
      id: source.id,
      description: source.description,
      startDate: source.startDate,
      endDate: source.endDate,
      contractName: source.contractName,
      creditOpinionLimit: source.creditOpinionLimit,
      onlyCreditOpinion: source.onlyCreditOpinion,
      monitoringLimit: source.monitoringLimit,
      onlyMonitoring: source.onlyMonitoring,
      monthlyPrice: source.monthlyPrice,
      creditOpinionPrice: source.creditOpinionPrice,
      association: source.associationDesc,
      observations: source.observations,
      status: RecruitmentPlanMapper.mapStatus(source.status)
    } as IRecruitmentPlanViewModel;
  }

  private static mapStatus(status?: DataStatus | null) {
    if (typeof status === 'undefined' || status === null) {
      return '';
    }
    return DATA_STATUS_VALUES.find((x) => x.key === status)?.value;
  }
}
