export enum Grade {
  NA = 0,
  Excelent = 1,
  VeryGood = 2,
  Good = 3,
  Acceptable = 4,
  Regular = 5,
  Adequate = 6,
  Inadequate = 7,
  Deficient = 8,
  VeryDeficient = 9,
  Catastrofic = 10,
}
