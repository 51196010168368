import { KeyValue } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CREDIT_OPINION_SITUATION_VALUES, FISCAL_CODE_TYPE_VALUES } from 'src/app/core/constants';
import { CreditOpinionSituation, FiscalCodeType } from 'src/app/core/enums';
import { ICountry } from 'src/app/core/models/api/country';
import { Limits } from 'src/app/shared/utils';
import { fiscalCodeValidator } from 'src/app/shared/validators';

export class CreditOpinionEditReducedViewModel {
  private _form: FormGroup;
  private _fiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[] = FISCAL_CODE_TYPE_VALUES;
  private _creditOpinionStatusValues: KeyValue<CreditOpinionSituation, string>[] = CREDIT_OPINION_SITUATION_VALUES;
  private _countries: Array<ICountry> = [];

  readonly C_ID = 'id';
  readonly C_COMPANY_ASSESS_FISCAL_CODE_TYPE = 'companyAssessFiscalCodeType';
  readonly C_COMPANY_ASSESS_FISCAL_CODE = 'companyAssessFiscalCode';
  readonly C_COMPANY_ASSESS_COUNTRY_ID = 'companyAssessCountryId';
  readonly C_COMPANY_ASSESS_NAME = 'companyAssessName';
  readonly C_REQUESTED_AMOUNT = 'requestedAmount';
  readonly C_SITUATION = 'situation';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(),
      [this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE]: new FormControl(null, [Validators.required]),
      [this.C_COMPANY_ASSESS_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_ASSESS_COUNTRY_ID]: new FormControl(null, []),
      [this.C_COMPANY_ASSESS_NAME]: new FormControl('', [Validators.required]),
      [this.C_REQUESTED_AMOUNT]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]),
      [this.C_SITUATION]: new FormControl()
    }, { validators: [fiscalCodeValidator(this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE, this.C_COMPANY_ASSESS_FISCAL_CODE)] });
  }

  get countries(): Array<ICountry> {
    return this._countries;
  }
  set countries(value: Array<ICountry>) {
    this._countries = value;
  }

  get fiscalCodeTypeValues(): KeyValue<FiscalCodeType, string>[] {
    return this._fiscalCodeTypeValues;
  }
  set fiscalCodeTypeValues(
    fiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[]
  ) {
    this._fiscalCodeTypeValues = fiscalCodeTypeValues;
  }

  get creditOpinionStatusValues(): KeyValue<CreditOpinionSituation, string>[] {
    return this._creditOpinionStatusValues;
  }
  set creditOpinionStatusValues(
    creditOpinionStatusValues: KeyValue<CreditOpinionSituation, string>[]
  ) {
    this._creditOpinionStatusValues = creditOpinionStatusValues;
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get companyAssessFiscalCodeType(): FiscalCodeType | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE)?.value;
  }
  set companyAssessFiscalCodeType(value: FiscalCodeType | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE)?.setValue(value);
  }

  get companyAssessFiscalCode(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE)?.value;
  }
  set companyAssessFiscalCode(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE)?.setValue(value);
  }

  get companyAssessCountryId(): number | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_COUNTRY_ID)?.value;
  }
  set companyAssessCountryId(value: number | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_COUNTRY_ID)?.setValue(value);
  }

  get companyAssessName(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_NAME)?.value;
  }
  set companyAssessName(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_NAME)?.setValue(value);
  }

  get requestedAmount(): number | null | undefined {
    return this._form.get(this.C_REQUESTED_AMOUNT)?.value;
  }
  set requestedAmount(value: number | null | undefined) {
    this._form.get(this.C_REQUESTED_AMOUNT)?.setValue(value);
  }

  get situation(): CreditOpinionSituation | null | undefined {
    return this._form.get(this.C_SITUATION)?.value;
  }
  set situation(value: CreditOpinionSituation | null | undefined) {
    this._form.get(this.C_SITUATION)?.setValue(value);
  }
}
