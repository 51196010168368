import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { CreditOpinionOperation } from 'src/app/core/enums';
import { ICreditOpinionSelection } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionContract } from 'src/app/core/services/api/credit-opinion';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { CreditOpinionOperationDialogViewModel } from '../models';

@Component({
  selector: 'howden-credit-opinion-operation-dialog',
  templateUrl: './credit-opinion-operation-dialog.component.html',
  styleUrls: ['./credit-opinion-operation-dialog.component.scss']
})
export class CreditOpinionOperationDialogComponent implements OnInit, OnDestroy {
  model = new CreditOpinionOperationDialogViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<CreditOpinionOperationDialogViewModel>,
    private uiBlockerSrv: UIBlockerContract,
    private creditOpinionSrv: CreditOpinionContract
  ) { }

  ngOnInit(): void {
    this.model.operation = this.operation;
    this.model.creditOpinionIds = this.creditOpinionIds;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    this.uiBlockerSrv.block();

    const request = {
      operation: this.model.operation,
      creditOpinionIds: this.model.creditOpinionIds
    } as ICreditOpinionSelection;

    if (request.operation === CreditOpinionOperation.Unit4) {
      this.creditOpinionSrv.executeOperationBlob(request).subscribe(
        {
          next: (blob: Blob) => {
            const attachName = FileUtils.formatFilename('Unit4', 'csv');
            FileUtils.downloadFile(
              attachName,
              blob
            );
          },
          complete: () => {
            this.uiBlockerSrv.unblock();
            this.closeDialog(DialogResultTypes.OK);
          }
        }
      );
    }
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  get isUnit4() {
    return this.operation === CreditOpinionOperation.Unit4;
  }

  private get operation(): CreditOpinionOperation | null {
    return this.inputData.operation as CreditOpinionOperation | null;
  }
  private get downloadUnit4(): boolean | null {
    return this.inputData.downloadUnit4 as boolean | null;
  }
  private get creditOpinionIds(): Array<string> | null {
    return this.inputData.creditOpinionIds as Array<string> | null;
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
