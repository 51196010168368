import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportIssuesComponent } from './pages/report-issues/report-issues.component';
import { SupportRoutingModule } from './support-routing.module';

@NgModule({
  imports: [SupportRoutingModule, SharedModule, MatMenuModule],
  declarations: [
    ReportIssuesComponent
  ],
  providers: [
  ]
})
export class SupportModule { }
