<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.search.plateNumber.label">ID Fiscal empresa</mat-label>
        <input #plateNumberInput  matInput [formControlName]="model.C_COMPANY_FISCAL_CODE" maxlength="20"/>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-request.search.plateNumber.label">Nombre empresa</mat-label>
        <input #plateNumberInput  matInput [formControlName]="model.C_COMPANY_NAME" maxlength="256"/>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-request.search.plateNumber.label">Email</mat-label>
        <input #plateNumberInput  matInput [formControlName]="model.C_EMAIL" maxlength="256" />
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.search.recruitmentPlanId">Plan contratado</mat-label>
        <mat-select [formControlName]="model.C_RECRUITMENT_PLAN_ID" [howdenControlError]="recruitmentPlanIdError">
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.recruitmentPlans | howdenArraySort : 'description' "
            [value]="item.id"
          >
            {{ item.description }}
          </mat-option>
        </mat-select>
        <mat-error #recruitmentPlanIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.search.status">Estado</mat-label>
        <mat-select [formControlName]="model.C_STATUS" [howdenControlError]="statusValuesError">
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.statusValues | howdenArraySort : 'value'"
            [value]="item.key"
          >
            {{ item.value }}
          </mat-option>
        </mat-select>
        <mat-error #statusValuesError></mat-error>
      </mat-form-field>
    </div>
  </form>
  <div>
    <howden-table
      class="small-font"
      keyField="id"
      paginable="server"
      sortable="server"
      filterable="server"
      [clickableRow]="true"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (rowClicked)="onRowClicked($event)"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
    >
    </howden-table>
  </div>
</div>
