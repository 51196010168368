import { KeyValue } from '@angular/common';

import { MonitoringPeriod } from '../enums';

export const MONITORING_PERIOD_VALUES: KeyValue<MonitoringPeriod, string>[] = [
  {
    key: MonitoringPeriod.Nothing,
    value: $localize`:@@app.route.monitoring-perdiod.enums.nothing:No monitorizar`
  },
  {
    key: MonitoringPeriod.OneMonth,
    value: $localize`:@@app.route.monitoring-period.enums.onemonth:Un mes`
  },
  {
    key: MonitoringPeriod.TenDays,
    value: $localize`:@@app.route.monitoring-period.enums.tendays:Diez días`
  }
] as KeyValue<MonitoringPeriod, string>[];
