import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { IRecruitmentPlanFilter } from 'src/app/core/models/api/recruitment-plan';
import { IUserRequest } from 'src/app/core/models/api/user-request';
import { RecruitmentPlanContract } from 'src/app/core/services/api/recruitment-plan';
import { UserRequestContract } from 'src/app/core/services/api/user-request';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UserRequestMapper } from '../../mappers';
import { IUserRequestViewModel, UserRequestSearchViewModel } from '../../models';

@Component({
  selector: 'howden-user-request-search',
  templateUrl: './user-request-search.component.html',
  styleUrls: ['./user-request-search.component.scss']
})
export class UserRequestSearchComponent implements OnInit, OnDestroy {
  model = new UserRequestSearchViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private uiBlockerSrv: UIBlockerContract,
    private recruitmentPlanSrv: RecruitmentPlanContract,
    private userRequestSrv: UserRequestContract
  ) {
  }

  ngOnInit(): void {
    this.recruitmentPlanSrv.search({} as IRecruitmentPlanFilter).subscribe((data) => {
      this.model.recruitmentPlans = data ?? [];
    });
    this.refresh();
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onRowClicked(event: IUserRequestViewModel): void {
    this.router.navigate(['management/user-request/edit', event.id]);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }

  private refresh(): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.userRequestSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IUserRequest>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) => UserRequestMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }
}
