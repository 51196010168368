import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { ICreditOpinionAttachment } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionAttachmentContract } from '.';


@Injectable({
  providedIn: 'root'
})
export class CreditOpinionAttachmentService implements CreditOpinionAttachmentContract {
  apiName = API_PATH_APPS.API_CREDIT_PORTAL;
  basePath = 'api/credit-opinion-attachment';

  constructor(
    private httpClient: HowdenHttpClientService
  ) { }

  search(creditOpinionId: string): Observable<Array<ICreditOpinionAttachment>> {
    return this.httpClient.get<Array<ICreditOpinionAttachment>>(this.apiName, `${this.basePath}/search/${creditOpinionId}`);
  }
  get(id: string): Observable<ICreditOpinionAttachment> {
    return this.httpClient.get<ICreditOpinionAttachment>(this.apiName, `${this.basePath}/${id}`);
  }
  save(request: FormData): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(this.apiName, `${this.basePath}`, request);
  }
  delete(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.delete<IResponse<boolean>>(this.apiName, `${this.basePath}/${id}`);
  }
  download(id: string): Observable<Blob> {
    return this.httpClient.getBlob(this.apiName, `${this.basePath}/download/${id}`, {
      reportProgress: true,
      responseType: 'blob'
    });
  }
}
