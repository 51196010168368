import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadComponent, HowdenAlertService } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { DataStatus, RecruitmentPlan, UserRole } from 'src/app/core/enums';
import { IRecruitmentPlanFilter } from 'src/app/core/models/api/recruitment-plan';
import { RecruitmentPlanContract } from 'src/app/core/services/api/recruitment-plan';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { FileUtils, FormGroupUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { UserDataMapper } from '../../mappers/user-data.mapper';
import { UserDataEditViewModel } from '../../models/user-data-edit.viewmodel';

@Component({
  selector: 'howden-user-data-edit',
  templateUrl: './user-data-edit.component.html',
  styleUrls: ['./user-data-edit.component.scss']
})
export class UserDataEditComponent implements OnInit, OnDestroy {
  model = new UserDataEditViewModel();
  isNew = true;
  isClient = false;
  isAssociation = false;
  isAssociationMember = false;
  hasFlexPlan = false;
  hasMonitoring = false;
  userId = '';

  private _subscriptions = new SubSink();

  @ViewChild('secondaryLogo') secondaryLogoRef!: FileUploadComponent;
  @ViewChild('reportLogo') reportLogoRef!: FileUploadComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private uiBlockerSrv: UIBlockerContract,
    private alertSrv: HowdenAlertService,
    private userDataSrv: UserDataContract,
    private recruitmentPlanSrv: RecruitmentPlanContract
  ) { }

  ngOnInit(): void {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        this.userId = paramMap.get('id') as string;

        this.uiBlockerSrv.block();

        forkJoin([
          this.userDataSrv.getRoles()
        ]).pipe(
          tap(([roles]) => {
            this.model.roles = roles;
            this.uiBlockerSrv.unblock();

            if (typeof this.userId !== 'undefined' && this.userId !== null) {
              this.isNew = false;
              this.userDataSrv.get(this.userId).subscribe(data => {
                this.onChangeRole(data.roleName, this.userId, data.particularStyles);
                UserDataMapper.mapForEdit(data, this.model);
                this.onEdit();
                this.uiBlockerSrv.unblock();
              });
            } else {
              this.onNew();
              this.uiBlockerSrv.unblock();
            }
          })
        ).subscribe();
      })
    ).subscribe();
  }

  private onNew() {
    this.model.status = DataStatus.Active;
  }

  private onEdit() {
    this.model.form.get(this.model.C_EMAIL)?.disable();
    this.model.form.get(this.model.C_PASSWORD)?.clearValidators();
    this.model.form.get(this.model.C_PASSWORD)?.updateValueAndValidity();
    this.model.form.get(this.model.C_PASSWORD_VALIDATION)?.clearValidators();
    this.model.form.get(this.model.C_PASSWORD_VALIDATION)?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onChangeRole(roleName: string, userId = '', particularStyles = false) {
    this.cleanDependentRoleValidations(particularStyles);

    if (roleName === UserRole.Association
      || roleName === UserRole.AssociationMember
      || roleName === UserRole.IndependentClient) {
      this.isClient = true;

      this.model.form.get(this.model.C_FISCAL_CODE_TYPE)?.setValidators([Validators.required]);
      this.model.form.get(this.model.C_FISCAL_CODE_TYPE)?.updateValueAndValidity();

      this.model.form.get(this.model.C_FISCAL_CODE)?.setValidators([Validators.required]);
      this.model.form.get(this.model.C_FISCAL_CODE)?.updateValueAndValidity();

      this.model.form.get(this.model.C_BUSINESS_NAME)?.setValidators([Validators.required]);
      this.model.form.get(this.model.C_BUSINESS_NAME)?.updateValueAndValidity();

      if (this.isNew) {
        this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.setValidators([Validators.required]);
        this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.updateValueAndValidity();
      }

      if (roleName === UserRole.Association) {
        this.isAssociation = true;
        //Cargamos los planes de contratación comunes y los que pueda tener dados de alta la asociación
        //(los de la asociación sólo podrán cargarse al editar la asociación tras darla de alta y asignarle el plan)
        const filter = { onlyCommon: true } as IRecruitmentPlanFilter;

        if (userId) {
          filter.associationId = userId;
          filter.onlyCommon = false;
        }

        this.recruitmentPlanSrv.search(filter).subscribe((recruitmentPlans) => {
          this.model.recruitmentPlans = recruitmentPlans;
          this.onChangeRecruitmentPlan();
        });
      } else if (roleName === UserRole.AssociationMember) {
        this.isAssociationMember = true;
        this.model.form.get(this.model.C_ASSOCIATION)?.setValidators([Validators.required]);
        this.model.form.get(this.model.C_ASSOCIATION)?.updateValueAndValidity();

        this.userDataSrv.getAssociations().subscribe((associations) => {
          this.model.associations = associations;
          this.onChangeAssociation(); //Para que cargue los planes de contratación si hay un asociación seleccionada
        });
      } else {
        //Cargamos los planes de contratación comunes
        this.recruitmentPlanSrv.search(
          { onlyCommon: true } as IRecruitmentPlanFilter
        ).subscribe((recruitmentPlans) => {
          this.model.recruitmentPlans = recruitmentPlans;
          this.onChangeRecruitmentPlan(); //Para que se cargue el plan si ya hay uno seleccionado
        });
      }
    }
  }

  private cleanDependentRoleValidations(particularStyles: boolean) {
    this.isAssociation = false;
    this.isClient = false;
    this.isAssociationMember = false;
    this.hasFlexPlan = false;
    this.hasMonitoring = false;

    this.model.form.get(this.model.C_FISCAL_CODE_TYPE)?.setValue(null);
    this.model.form.get(this.model.C_FISCAL_CODE_TYPE)?.clearValidators();
    this.model.form.get(this.model.C_FISCAL_CODE_TYPE)?.updateValueAndValidity();

    this.model.form.get(this.model.C_FISCAL_CODE)?.setValue(null);
    this.model.form.get(this.model.C_FISCAL_CODE)?.clearValidators();
    this.model.form.get(this.model.C_FISCAL_CODE)?.updateValueAndValidity();

    this.model.form.get(this.model.C_BUSINESS_NAME)?.setValue(null);
    this.model.form.get(this.model.C_BUSINESS_NAME)?.clearValidators();
    this.model.form.get(this.model.C_BUSINESS_NAME)?.updateValueAndValidity();

    this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.setValue(null);
    this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.clearValidators();
    this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.updateValueAndValidity();

    this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.setValue(null);
    this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.clearValidators();
    this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.updateValueAndValidity();

    this.model.form.get(this.model.C_ASSOCIATION)?.setValue(null);
    this.model.form.get(this.model.C_ASSOCIATION)?.clearValidators();
    this.model.form.get(this.model.C_ASSOCIATION)?.updateValueAndValidity();

    this.model.form.get(this.model.C_SECONDARY_LOGO)?.setValue(null);
    this.model.form.get(this.model.C_SECONDARY_LOGO_FILE)?.setValue(null);
    this.secondaryLogoRef?.clearFiles();
    this.model.form.get(this.model.C_REPORT_LOGO)?.setValue(null);
    this.model.form.get(this.model.C_REPORT_LOGO_FILE)?.setValue(null);
    this.reportLogoRef?.clearFiles();
    this.model.form.get(this.model.C_PARTICULAR_STYLES)?.setValue(particularStyles);
    this.onChangeParticularStyles(particularStyles);
  }

  onChangeRecruitmentPlan() {
    if (this.model.recruitmentPlan) {
      const planSelected = this.model.recruitmentPlans.find(x => x.id === this.model.recruitmentPlan);

      if (!planSelected?.onlyMonitoring && planSelected?.id === RecruitmentPlan.FlexPlan) {
        this.hasFlexPlan = true;
      } else {
        this.hasFlexPlan = false;
      }

      if (!planSelected?.onlyCreditOpinion) {
        this.hasMonitoring = true;
      } else {
        this.hasMonitoring = false;
      }
    } else {
      this.hasFlexPlan = false;
      this.hasMonitoring = false;
    }

    if (!this.hasFlexPlan) {
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.setValue(null);
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.clearValidators();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.updateValueAndValidity();
      this.model.form.get(this.model.C_PARTICULAR_STYLES)?.setValue(false);
      this.onChangeParticularStyles(false);
    }

    if (this.hasMonitoring) {
      this.model.form.get(this.model.C_MONITORING_PERIOD)?.setValidators([Validators.required]);
    } else {
      this.model.form.get(this.model.C_MONITORING_PERIOD)?.setValue(null);
      this.model.form.get(this.model.C_MONITORING_PERIOD)?.clearValidators();
    }
    this.model.form.get(this.model.C_MONITORING_PERIOD)?.updateValueAndValidity();
  }

  onChangeAssociation() {
    if (this.model.association) {
      //Cargamos los planes de contratación de la asociación
      this.recruitmentPlanSrv.search(
        {
          associationId: this.model.association.toString()
        } as IRecruitmentPlanFilter
      ).subscribe((recruitmentPlans) => {
        this.model.recruitmentPlans = recruitmentPlans;
        this.onChangeRecruitmentPlan();
      });
    } else {
      this.model.recruitmentPlans = [];
      this.onChangeRecruitmentPlan();
    }
  }

  onChangeParticularStyles(toogleSelect: boolean) {
    if (!toogleSelect) {
      //Limpiamos todos los campos que dependen de este
      this.model.form.get(this.model.C_HEX_PRIMARY_COLOR)?.setValue(null);
      this.model.form.get(this.model.C_HEX_PRIMARY_COLOR)?.updateValueAndValidity();
      this.model.form.get(this.model.C_HEX_SECONDARY_COLOR)?.setValue(null);
      this.model.form.get(this.model.C_HEX_SECONDARY_COLOR)?.updateValueAndValidity();
    }
  }

  onSecondaryLogoSelected(): void {
    if (this.secondaryLogoRef.file?.type === 'image/png') {
      this.model.form.patchValue({
        [this.model.C_SECONDARY_LOGO_FILE]: this.secondaryLogoRef.file
      });
    } else {
      const title: string = $localize`: @@app.user-data.edit.secondary-logo-file.title:Logo Secundario`;
      const message: string = $localize`: @@app.user-data.edit.secondary-logo-file.message:Debe seleccionar una imagen .png`;
      this.alertSrv.error(title, message);
      this.model.form.get(this.model.C_SECONDARY_LOGO_FILE)?.setValue(null);
      this.secondaryLogoRef?.clearFiles();
    }
  }

  onReportLogoSelected(): void {
    if (this.reportLogoRef.file?.type === 'image/png') {
      this.model.form.patchValue({
        [this.model.C_REPORT_LOGO_FILE]: this.reportLogoRef.file
      });
    } else {
      const title: string = $localize`: @@app.user-data.edit.report-logo-file.title:Logo Informes`;
      const message: string = $localize`: @@app.user-data.edit.report-logo-file.message:Debe seleccionar una imagen .png`;
      this.alertSrv.error(title, message);
      this.model.form.get(this.model.C_REPORT_LOGO_FILE)?.setValue(null);
      this.reportLogoRef?.clearFiles();
    }
  }

  onSave() {
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.userDataSrv
      .save(request)
      .pipe(
        tap((response) => {
          const msgResult = response.result as string;
          if (msgResult) {
            const title: string = $localize`: @@app.user-data.save.result.warning.title:¡Advertencia!`;
            this.alertSrv.warning(title, msgResult);
          } else {
            const title: string = $localize`: @@app.user-data.save.result.ok.title:Éxito!`;
            const message: string = $localize`: @@app.user-data.save.result.ok.message:Usuario guardado correctamente`;
            this.alertSrv.success(title, message);
          }
          this.uiBlockerSrv.unblock();
          this.goToSearch();
        })
      )
      .subscribe();
  }

  goToSearch(): void {
    this.router.navigate(['management/user-data/search']);
  }

  downloadSecondaryLogo() {
    const userId = this.model.id as number;
    this.uiBlockerSrv.block();
    this.userDataSrv.downloadSecondaryLogo(userId).subscribe(
      {
        next: (blob: Blob) => {
          const attachName = FileUtils.formatFilename(`SecLogo_${this.model.userFiscalCode}`, 'png');
          FileUtils.downloadFile(
            attachName,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }

  downloadReportLogo() {
    const userId = this.model.id as number;
    this.uiBlockerSrv.block();
    this.userDataSrv.downloadReportLogo(userId).subscribe(
      {
        next: (blob: Blob) => {
          const attachName = FileUtils.formatFilename(`ReportLogo_${this.model.userFiscalCode}`, 'png');
          FileUtils.downloadFile(
            attachName,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }
}
