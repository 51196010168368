<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.form" enctype="multipart/form-data">
    <h2>Datos cliente</h2>
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.contract-check.clientName">Nombre (NombreCliente)</mat-label>
        <input
          matInput
          [formControlName]="model.C_CLIENT_NAME"
          [howdenControlError]="userNameError"
          maxlength="100"
        />
        <mat-error #userNameError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.contract-check.fiscalCode">DNI/NIE (DNI)</mat-label>
        <input
          matInput
          [formControlName]="model.C_FISCAL_CODE"
          [howdenControlError]="fiscalCodeError"
          maxlength="25"
        />
        <mat-error #fiscalCodeError></mat-error>
      </mat-form-field>
    </div>

    <h2>Datos empresa</h2>
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.contract-check.companyName">Razón social (RazonSocialEmpresa)</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_NAME"
          [howdenControlError]="companyNameError"
          maxlength="100"
        />
        <mat-error #companyNameError></mat-error>
      </mat-form-field>

        <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.contract-check.companyAddress">Domicilio (DomicilioEmpresa)</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_ADDRESS"
          [howdenControlError]="companyAddressError"
          maxlength="100"
        />
        <mat-error #companyAddressError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.contract-check.companyFiscalCode">CIF (CIF)</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_FISCAL_CODE"
          [howdenControlError]="companyFiscalCodeError"
          maxlength="100"
        />
        <mat-error #companyFiscalCodeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.contract-check.companyActivity">Actividad (ActividadEmpresa)</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_ACTIVITY"
          [howdenControlError]="companyActivityError"
          maxlength="100"
        />
        <mat-error #companyActivityError></mat-error>
      </mat-form-field>
    </div>

    <h2>Contrato</h2>
    <div class="flex f-gap--10px margin-bottom-10">
      <howden-decimal
        class="f-basis--2 mat-form-field"
        [formControlName]="model.C_PRICE_NUMBER"
        i18n-label="@@app.contract-check.price"
        label="Precio opinión crédito (PrecioNumero)"
      ></howden-decimal>
      <mat-form-field class="f-basis--3">
      <mat-label i18n="@@app.contract-check.piceNumber">Precio opinión crédito en letra (PrecioLetra)</mat-label>
        <input
          matInput
          [formControlName]="model.C_PRICE_LETTER"
          [howdenControlError]="priceLetterError"
          maxlength="100"
        />
        <mat-error #priceLetterError></mat-error>
      </mat-form-field>

       <div class="f-basis--6">
          <mat-label i18n="@@contract-check.contractName">Plantilla Contrato (.pdf)</mat-label>
          <howden-file-upload
            #contractTemplate
            (filesSelected)="onContractTemplateSelected()"
          >
          </howden-file-upload>
        </div>
    </div>

    <div class="flex f-gap--10px f-ai--center margin-bottom-20">
      <div class="flex f-basis--12 f-jc--end">
        <button
          mat-raised-button
          color="primary"
          [disabled]="model.form.invalid"
        (click)="onAccept()"
        >
          <span i18n="@@app.actions.save">Guardar</span>
        </button>
      </div>
    </div>
  </form>
</div>
