import { KeyValue } from '@angular/common';
import { Month } from '../enums/month.enum';

export const MONTH_VALUES: KeyValue<Month, string>[] = [
  { key: Month.January, value: $localize`:@@app.constants.month.january:Enero` },
  { key: Month.February, value: $localize`:@@app.constants.month.february:Febrero` },
  { key: Month.March, value: $localize`:@@app.constants.month.march:Marzo` },
  { key: Month.April, value: $localize`:@@app.constants.month.april:Abril` },
  { key: Month.May, value: $localize`:@@app.constants.month.may:Mayo` },
  { key: Month.June, value: $localize`:@@app.constants.month.june:Junio` },
  { key: Month.July, value: $localize`:@@app.constants.month.july:Julio` },
  { key: Month.August, value: $localize`:@@app.constants.month.august:Agosto` },
  { key: Month.September, value: $localize`:@@app.constants.month.september:Septiembre` },
  { key: Month.October, value: $localize`:@@app.constants.month.october:Octubre` },
  { key: Month.November, value: $localize`:@@app.constants.month.november:Noviembre` },
  { key: Month.December, value: $localize`:@@app.constants.month.december:Diciembre` }
] as KeyValue<Month, string>[];
