import { Component, Input, OnChanges } from '@angular/core';
import { HowdenUserService } from '@howdeniberia/core-front';
import { KpiOptions } from 'src/app/core/models/api/kpi-indicator/kpi-indicator-options';

@Component({
  selector: 'howden-kpi-indicator',
  templateUrl: './kpi-indicator.component.html',
  styleUrls: ['./kpi-indicator.component.scss']
})
export class KpiIndicatorComponent implements OnChanges {
  @Input() title = '';
  @Input() value = 0;
  @Input() options: KpiOptions;
  @Input() dataType: 'numeric' | 'text' | 'currency' = 'numeric';
  formattedValue = '';
  constructor(private userSrv: HowdenUserService) {
    this.options = {
      textColor: this.userSrv.selectedTheme === 'dark' ? '#ffffff' : '#173f35'
    };
  }

  ngOnChanges(): void {
    const textColor = this.userSrv.selectedTheme === 'dark' ? '#ffffff' : '#173f35';
    this.options.textColor = textColor;
    this.formatValue();
  }

  private formatValue() {
    const numericValue = this.valueAsNumber;

    if (this.dataType === 'currency') {
      if (numericValue >= 1000) {
        this.formattedValue = (numericValue / 1000).toFixed(1) + 'K €';
      } else if (numericValue > 100) {
        this.formattedValue = Math.round(numericValue / 10) * 10 + '€';
      } else {
        this.formattedValue = numericValue.toFixed(0) + ' €';
      }
    } else {
      this.formattedValue = String(numericValue);
    }
  }

  get valueAsNumber(): number {
    return typeof this.value === 'number' ? this.value : parseFloat(this.value);
  }
}
