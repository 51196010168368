import { IDebtor } from 'src/app/core/models/api/monitoring';
import { DebtorEditViewModel } from '../models';

export abstract class DebtorEditMapper {
  static mapForEdit(source: IDebtor, target: DebtorEditViewModel): void {
    target.id = source.id;
    target.userId = source.userId;
    target.fiscalCodeType = source.fiscalCodeType;
    target.fiscalCode = source.fiscalCode;
    target.countryId = source.countryId;
    target.businessName = source.businessName;
    target.ownIdentifier = source.ownIdentifier;
    target.requestedAmount = source.requestedAmount;
    target.notMonitorable = source.notMonitorable;
  }

  static mapForUpdate(source: DebtorEditViewModel): IDebtor {
    return {
      id: source.id,
      userId: source.userId,
      fiscalCodeType: source.fiscalCodeType,
      fiscalCode: source.fiscalCode,
      countryId: source.countryId,
      businessName: source.businessName,
      ownIdentifier: source.ownIdentifier,
      requestedAmount: source.requestedAmount,
      notMonitorable: source.notMonitorable
    } as IDebtor;
  }
}
