import { IMonitoringUserComplete } from 'src/app/core/models/api/monitoring';
import { DebtorMonitoringViewModel } from '../models';

export abstract class DebtorMonitoringMapper {
  static mapForVisualize(source: IMonitoringUserComplete, target: DebtorMonitoringViewModel): void {
    target.userId = source.userId;
    target.monitoringDebtorsData = source.debtors;

    //Actualizamos cabeceras para el histórico
    let i = 1;
    let item;
    source.monitoringDates.forEach(date => {
      item = target.columnsHistoricalMonitoring.find(x => x.fieldName === `grantedAmount${i}`);
      if (item) {
        item.fieldHeader = date;
      }
      i++;
    });

    target.dataActualMonitoring = [];
    target.dataHistoricalMonitoring = [];
    //La información ya nos viene ordernada del api
    source.debtors.forEach(debtor => {
      target.dataActualMonitoring.push({
        debtorId: debtor.debtorId,
        fiscalCode: debtor.fiscalCode,
        businessName: debtor.businessName,
        ownIdentifier: debtor.ownIdentifier,
        requestedAmount: debtor.requestedAmount,
        grantedAmount: debtor.creditsGranted.length > 0 ? debtor.creditsGranted[0].grantedAmount : null
      });

      target.dataHistoricalMonitoring.push({
        debtorId: debtor.debtorId,
        fiscalCode: debtor.fiscalCode,
        businessName: debtor.businessName,
        ownIdentifier: debtor.ownIdentifier,
        requestedAmount: debtor.requestedAmount,
        grantedAmount1: debtor.creditsGranted.length > 0 ? debtor.creditsGranted[0].grantedAmount : null,
        grantedAmount2: debtor.creditsGranted.length > 1 ? debtor.creditsGranted[1].grantedAmount : null,
        grantedAmount3: debtor.creditsGranted.length > 2 ? debtor.creditsGranted[2].grantedAmount : null,
        grantedAmount4: debtor.creditsGranted.length > 3 ? debtor.creditsGranted[3].grantedAmount : null,
        grantedAmount5: debtor.creditsGranted.length > 4 ? debtor.creditsGranted[4].grantedAmount : null,
        grantedAmount6: debtor.creditsGranted.length > 5 ? debtor.creditsGranted[5].grantedAmount : null,
        grantedAmount7: debtor.creditsGranted.length > 6 ? debtor.creditsGranted[6].grantedAmount : null,
        grantedAmount8: debtor.creditsGranted.length > 7 ? debtor.creditsGranted[7].grantedAmount : null,
        grantedAmount9: debtor.creditsGranted.length > 8 ? debtor.creditsGranted[8].grantedAmount : null,
        grantedAmount10: debtor.creditsGranted.length > 9 ? debtor.creditsGranted[9].grantedAmount : null,
        grantedAmount11: debtor.creditsGranted.length > 10 ? debtor.creditsGranted[10].grantedAmount : null,
        grantedAmount12: debtor.creditsGranted.length > 11 ? debtor.creditsGranted[11].grantedAmount : null
      });
    });
  }
}
