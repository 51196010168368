import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserRole } from 'src/app/core/enums';
import { BusinessEvolutionComponent } from './pages/business-evolution/business-evolution.component';
import { CreditOpinionHistoryComponent } from './pages/credit-opinion-history/credit-opinion-history.component';

export const DashboardRoutes = [
  {
    path: 'dashboard',
    title: $localize`:@@app.route.dashboard:Dashboard`,
    data: {
      icon: 'bar_chart',
      linkText: $localize`:@@app.route.dashboard:Dashboard`,
      roles: [UserRole.SuperAdmin, UserRole.Administrator]
    },
    children: [
      {
        path: 'credit-opinion-history',
        component: CreditOpinionHistoryComponent,
        title: $localize`:@@app.route.dashboard.credit-opinion-history.title:Histórico Solicitudes`,
        data: {
          linkText: $localize`:@@app.route.dashboard.credit-opinion-history:Histórico`
        }
      },
      {
        path: 'business-evolution',
        component: BusinessEvolutionComponent,
        title: $localize`:@@app.route.dashboard.business-evolution.title:Evolución Negocio`,
        data: {
          linkText: $localize`:@@app.route.dashboard.business-evolution:Negocio`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(DashboardRoutes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
