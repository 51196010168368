import { ICreditOpinionReduced } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionEditReducedViewModel } from '../models';

export abstract class CreditOpinionEditReducedMapper {
  static mapForEdit(source: ICreditOpinionReduced, target: CreditOpinionEditReducedViewModel): void {
    target.id = source.id;
    target.companyAssessFiscalCodeType = source.companyAssessFiscalCodeType;
    target.companyAssessFiscalCode = source.companyAssessFiscalCode;
    target.companyAssessName = source.companyAssessName;
    target.requestedAmount = source.requestedAmount;
    target.situation = source.situation;
    target.companyAssessCountryId = source.companyAssessCountryId;
  }

  static mapForUpdate(source: CreditOpinionEditReducedViewModel): ICreditOpinionReduced {
    return {
      id: source.id,
      companyAssessFiscalCodeType: source.companyAssessFiscalCodeType,
      companyAssessFiscalCode: source.companyAssessFiscalCode,
      companyAssessName: source.companyAssessName,
      requestedAmount: source.requestedAmount,
      situation: source.situation,
      companyAssessCountryId: source.companyAssessCountryId
    } as ICreditOpinionReduced;
  }
}
