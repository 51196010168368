import { IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { ICreditOpinionAttachment } from 'src/app/core/models/api/credit-opinion';

export abstract class CreditOpinionAttachmentContract {
  abstract search(creditOpinionId: string): Observable<Array<ICreditOpinionAttachment>>
  abstract get(id: string): Observable<ICreditOpinionAttachment>;
  abstract save(request: FormData): Observable<IResponse<boolean>>;
  abstract delete(id: string): Observable<IResponse<boolean>>;
  abstract download(id: string): Observable<Blob>;
}
