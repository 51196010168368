import { Injectable } from '@angular/core';
import { HowdenLoaderService } from '@howdeniberia/core-front';
import { UIBlockerContract } from './ui-blocker.contract';

@Injectable({
  providedIn: 'root'
})
export class UIBlockerService implements UIBlockerContract {
  constructor(private loaderSrv: HowdenLoaderService) {
  }

  block(): void {
    this.loaderSrv.isSpinner.next({ active: true });
  }

  unblock(): void {
    this.loaderSrv.isSpinner.next({ active: false });
  }
}
