import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import {
  ICreditOpinion,
  ICreditOpinionCounter,
  ICreditOpinionFilter,
  ICreditOpinionReduced,
  ICreditOpinionSelection
} from 'src/app/core/models/api/credit-opinion';
export abstract class CreditOpinionContract {
  abstract saveReduced(request: ICreditOpinionReduced): Observable<IResponse<boolean>>;
  abstract save(request: ICreditOpinion): Observable<IResponse<boolean>>;
  abstract getReduced(id: string): Observable<ICreditOpinionReduced>;
  abstract get(id: string): Observable<ICreditOpinion>;
  abstract getCounter(): Observable<ICreditOpinionCounter>;
  abstract search(request: ICreditOpinionFilter): Observable<IPageOf<ICreditOpinion>>;
  abstract delete(creditOpinionId: string): Observable<IResponse<boolean>>;
  abstract reject(creditOpinionId: string): Observable<ICreditOpinion>;
  abstract reactivate(creditOpinionId: string): Observable<ICreditOpinion>;
  abstract downloadPdf(id: string): Observable<Blob>;
  abstract executeOperationBlob(request: ICreditOpinionSelection): Observable<Blob>;
}
