import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HowdenAuthService, HowdenTableComponent } from '@howdeniberia/core-front';
import { UserRole } from 'src/app/core/enums';
import { IStatistic } from 'src/app/core/models/api/core/statistic';
import { IMonitoringUserComplete } from 'src/app/core/models/api/monitoring';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { MonitoringContract } from 'src/app/core/services/api/monitoring/monitoring.contract';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { DebtorMonitoringMapper } from '../mappers';
import { IDebtorMonitoringActualViewModel } from '../models';
import { DebtorMonitoringViewModel } from '../models/debtor-monitoring.viewmodel';

@Component({
  selector: 'howden-debtor-monitoring',
  templateUrl: './debtor-monitoring.component.html',
  styleUrls: ['./debtor-monitoring.component.scss']
})
export class DebtorMonitoringComponent implements OnInit, OnDestroy {
  model = new DebtorMonitoringViewModel();

  isClientProfile = false;
  infoLoaded = false;
  debtorSelected = '';
  creditGrantedByDateToDashboard: Array<IStatistic> = [];

  @ViewChild('debtorsTable') debtorsTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private authSrv: HowdenAuthService,
    private uiBlockerSrv: UIBlockerContract,
    private monitoringSrv: MonitoringContract,
    private userDataSrv: UserDataContract
  ) {
  }

  ngOnInit(): void {
    this.isClientProfile = this.authSrv.isUserAuthorized([
      UserRole.Association,
      UserRole.AssociationMember,
      UserRole.IndependentClient
    ]) && !this.authSrv.isSuperAdmin;

    if (!this.isClientProfile) {
      this.uiBlockerSrv.block();
      this.userDataSrv.getClients(true).subscribe((data) => {
        this.model.clients = data;
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.loadMonitoringData();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onInputUser() {
    //Si están escribiendo en el filtro, es porque no hay un usuario seleccionado
    if (this.model.userId as number > 0) {
      this.model.userId = 0;
      this.loadMonitoringData();
    }
  }

  onUserSelectChange(monitorableClient: IUserDataReduced) {
    this.model.userId = monitorableClient.userId;
    this.loadMonitoringData();
  }

  getUserDescription(monitorableClient: IUserDataReduced): string {
    let valor = '';
    if (monitorableClient !== null) {
      valor = `${monitorableClient.businessName} [${monitorableClient.fiscalCode}]`;
    }
    return valor;
  }

  onRowClicked(event: IDebtorMonitoringActualViewModel): void {
    const debtorId = event.debtorId as number;

    this.debtorSelected = event.businessName as string;

    const grantedAmounts: IStatistic[] = [];

    //Recuperamos el los créditos concedidos de este deudor
    const data = this.model.monitoringDebtorsData.filter(x => x.debtorId === debtorId)[0];
    data.creditsGranted.forEach(x => {
      grantedAmounts.push(
        {
          id: x.monitoringDateDesc,
          label: x.monitoringDateDesc,
          description: x.monitoringDateDesc,
          value: x.grantedAmount
        }
      );
    });

    this.creditGrantedByDateToDashboard = grantedAmounts;
  }

  onDownloadHistory(): void {
    this.uiBlockerSrv.block();

    this.monitoringSrv.downloadMonitoringHistorical(this.model.userId as number).subscribe(
      {
        next: (blob: Blob) => {
          const attachName = FileUtils.formatFilename('Monitoring', 'xlsx');
          FileUtils.downloadFile(
            attachName,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }

  private loadMonitoringData(): void {
    const userId = this.model.userId ?? 0;
    this.infoLoaded = false;
    this.creditGrantedByDateToDashboard = [];

    if (this.isClientProfile || userId > 0) {
      this.uiBlockerSrv.block();

      this.monitoringSrv.getCompleteMonitoring(userId).subscribe({
        next: (result: IMonitoringUserComplete) => {
          DebtorMonitoringMapper.mapForVisualize(result, this.model);
          if (result.debtors.length > 0) {
            this.infoLoaded = true;
            const firstRow = this.model.dataActualMonitoring[0];
            //Seleccionamos la primera fila
            this.debtorsTable.setHighlightRow(firstRow, undefined);
            //Cargamos gráfico de primera fila
            this.onRowClicked(firstRow);
          }
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      });
    }
  }
}
