import { KeyValue } from '@angular/common';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig,
  ITableButtonConfig,
  ITableEventData,
  TableButtonColors
} from '@howdeniberia/core-front';
import { CreditOpinionSituation } from 'src/app/core/enums';

import { Observable, map, startWith } from 'rxjs';
import { CREDIT_OPINION_SITUATION_VALUES } from 'src/app/core/constants';
import { ICreditOpinionCounter, ICreditOpinionFilter } from 'src/app/core/models/api/credit-opinion';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { ICreditOpinionViewModel } from './credit-opinion.viewmodel';

export class CreditOpinionSearchViewModel {
  private _filterForm: FormGroup;
  private _clients: Array<IUserDataReduced> = [];
  private _filteredClients: Observable<Array<IUserDataReduced>>;
  private _statusValues: KeyValue<CreditOpinionSituation, string>[] = CREDIT_OPINION_SITUATION_VALUES;
  private _searchRequest: ICreditOpinionFilter;
  private _creditOpinionCounter: ICreditOpinionCounter;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_COUNTER_INFO = 'counterInfo';
  readonly C_COUNTER_MSG = 'counterMsg';
  readonly C_REQUEST_USER = 'requestUser';
  readonly C_REQUEST_USER_ID = 'requestUserId';
  readonly C_COMPANY_ASSESS_FISCAL_CODE = 'companyAssessedFiscalCode';
  readonly C_COMPANY_ASSESS_NAME = 'companyAssessedName';
  readonly C_DATE_FROM = 'dateFrom';
  readonly C_DATE_TO = 'dateTo';
  readonly C_STATUS = 'situation';

  constructor() {
    //Por defecto mostramos las opiniones del último mes
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 1);

    this._filterForm = new FormGroup({
      [this.C_COUNTER_INFO]: new FormControl('', []),
      [this.C_COUNTER_MSG]: new FormControl('', []),
      [this.C_REQUEST_USER]: new FormControl(null, []),
      [this.C_REQUEST_USER_ID]: new FormControl(null, []),
      [this.C_COMPANY_ASSESS_FISCAL_CODE]: new FormControl('', []),
      [this.C_COMPANY_ASSESS_NAME]: new FormControl('', []),
      [this.C_DATE_FROM]: new FormControl(fromDate, []),
      [this.C_DATE_TO]: new FormControl(null, []),
      [this.C_STATUS]: new FormControl(null, [])
    });

    this._filteredClients = this.getControl(this.C_REQUEST_USER)?.valueChanges.pipe(
      startWith(''),
      map(value => this.filterClients(value || ''))
    );

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as ICreditOpinionFilter;

    this._creditOpinionCounter = {
      maxNumber: 0,
      actualNumber: 0,
      priceExceeded: 0,
      unlimited: false,
      message: ''
    } as ICreditOpinionCounter;
  }

  data: ICreditOpinionViewModel[] = [];
  columns: IHowdenColumnConfig[] = [
    {
      fieldName: 'requester',
      fieldHeader: $localize`:@@app.credit-opinion.search.requestingUser.column:Cliente`,
      canSort: false,
      width: '16%'
    },
    {
      fieldName: 'association',
      fieldHeader: $localize`:@@app.credit-opinion.search.association.column:Asociación`,
      canSort: false,
      width: '14%'
    },
    {
      fieldName: 'companyAssessDesc',
      fieldHeader: $localize`:@@app.credit-opinion.search.companyAssessed.column:Sociedad`,
      canSort: false,
      width: '16%'
    },
    {
      fieldName: 'requestDate',
      fieldHeader: $localize`:@@app.credit-opinion.search.requestDate.column:F. Solicitud`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      aligment: ColumnAligment.CENTER,
      width: '8%'
    },
    {
      fieldName: 'responseDate',
      fieldHeader: $localize`:@@app.credit-opinion.search.responseDate.column:F. Valoración`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      aligment: ColumnAligment.CENTER,
      width: '8%'
    },
    {
      fieldName: 'requestedAmount',
      fieldHeader: $localize`:@@app.credit-opinion.search.requestedAmount.column:Solicitado`,
      canSort: true,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.CURRENCY,
      width: '8%'
    },
    {
      fieldName: 'grantedAmount',
      fieldHeader: $localize`:@@app.credit-opinion.search.grantedAmount.column:Concedido`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.CURRENCY,
      aligment: ColumnAligment.RIGHT,
      width: '8%'
    },
    {
      fieldName: 'situation',
      fieldHeader: $localize`:@@app.credit-opinion.search.status.column:Estado`,
      canSort: true,
      aligment: ColumnAligment.CENTER,
      width: '8%'
    }
  ];

  buttons: Array<ITableButtonConfig> = [
    {
      name: 'download',
      color: TableButtonColors.INFO,
      icon: 'download',
      tooltip: $localize`:@@app.credit-opinion.search.actions.download.tooltip:Descargar Informe`,
      isDisabled: (row: any): boolean =>
        (row.situationId !== CreditOpinionSituation.Answered)
    },
    {
      name: 'delete',
      color: TableButtonColors.PRIMARY,
      icon: 'delete_forever',
      tooltip: $localize`:@@app.credit-opinion.search.actions.delete.tooltip:Eliminar Solicitud`,
      isDisabled: (row: any): boolean =>
        row.situationId !== CreditOpinionSituation.Pending
    }
  ];

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._filterForm.get(controlName)!;
  }

  get clients(): Array<IUserDataReduced> {
    return this._clients;
  }
  set clients(value: Array<IUserDataReduced>) {
    this._clients = value;
  }

  get filteredClients(): Observable<Array<IUserDataReduced>> {
    return this._filteredClients;
  }

  private filterClients(value: string): Array<IUserDataReduced> {
    return value !== ''
      ? this.clients.filter(c =>
        c.businessName?.toLowerCase().includes(value.toString().toLowerCase())
        || c.fiscalCode?.toLowerCase().includes(value.toString().toLowerCase()))
      : this.clients;
  }

  get length(): number {
    return this._length;
  }
  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get statusValues(): KeyValue<CreditOpinionSituation, string>[] {
    return this._statusValues;
  }

  get searchRequest(): ICreditOpinionFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }
  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get creditOpinionCounter(): ICreditOpinionCounter {
    return this._creditOpinionCounter;
  }
  set creditOpinionCounter(value: ICreditOpinionCounter) {
    this._creditOpinionCounter = value;
  }

  get counterInfo(): string | null | undefined {
    return this._filterForm.get(this.C_COUNTER_INFO)?.value;
  }
  set counterInfo(value: string | null | undefined) {
    this._filterForm.get(this.C_COUNTER_INFO)?.setValue(value);
  }

  get counterMsg(): string | null | undefined {
    return this._filterForm.get(this.C_COUNTER_MSG)?.value;
  }
  set counterMsg(value: string | null | undefined) {
    this._filterForm.get(this.C_COUNTER_MSG)?.setValue(value);
  }

  get requestUserId(): number | null | undefined {
    return this.getControl(this.C_REQUEST_USER_ID)?.value;
  }
  set requestUserId(value: number | null | undefined) {
    this.getControl(this.C_REQUEST_USER_ID)?.setValue(value);
  }

  get companyAssessedFiscalCode(): string | null | undefined {
    return this._filterForm.get(this.C_COMPANY_ASSESS_FISCAL_CODE)?.value;
  }
  set companyAssessedFiscalCode(value: string | null | undefined) {
    this._filterForm.get(this.C_COMPANY_ASSESS_FISCAL_CODE)?.setValue(value);
  }

  get companyAssessedName(): string | null | undefined {
    return this._filterForm.get(this.C_COMPANY_ASSESS_NAME)?.value;
  }

  set companyAssessedName(value: string | null | undefined) {
    this._filterForm.get(this.C_COMPANY_ASSESS_NAME)?.setValue(value);
  }

  get dateFrom(): Date | null | undefined {
    return this._filterForm.get(this.C_DATE_FROM)?.value;
  }
  set dateFrom(value: Date | null | undefined) {
    this._filterForm.get(this.C_DATE_FROM)?.setValue(value);
  }

  get dateTo(): Date | null | undefined {
    return this._filterForm.get(this.C_DATE_TO)?.value;
  }
  set dateTo(value: Date | null | undefined) {
    this._filterForm.get(this.C_DATE_TO)?.setValue(value);
  }

  get situation(): CreditOpinionSituation | null | undefined {
    return this._filterForm.get(this.C_STATUS)?.value;
  }
  set situation(value: CreditOpinionSituation | null | undefined) {
    this._filterForm.get(this.C_STATUS)?.setValue(value);
  }

  hideCheck(row: any): boolean {
    return !row.showCheck;
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.requestUserId = this.requestUserId;
    this.searchRequest.companyAssessedFiscalCode = this.companyAssessedFiscalCode;
    this.searchRequest.companyAssessedName = this.companyAssessedName;
    this.searchRequest.dateFrom = this.dateFrom;
    this.searchRequest.dateTo = this.dateTo;
    this.searchRequest.situation = this.situation;
  }
}
