import { Injectable } from '@angular/core';

import { LocalStorageContract } from './local-storage.contract';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements LocalStorageContract {
  debtorCreditGrantedUpdateIds: Array<number> = [];

  hasDebtorCreditGrantedUpdateIds(): boolean {
    if (this.debtorCreditGrantedUpdateIds.length > 0) {
      return true;
    }
    return false;
  }

  addDebtorCreditGrantedUpdateId(debtorId: number) {
    if (this.debtorCreditGrantedUpdateIds.filter(x => x === debtorId).length === 0) {
      this.debtorCreditGrantedUpdateIds.push(debtorId);
    }
  }

  getDebtorCreditGrantedUpdateIds(): Array<number> {
    return this.debtorCreditGrantedUpdateIds;
  }

  cleanDebtorCreditGrantedUpdateIds() {
    this.debtorCreditGrantedUpdateIds = [];
  }
}
