import { IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IUserDataHistory } from 'src/app/core/models/api/user-data';

export abstract class UserDataHistoryContract {
  abstract search(userId: string): Observable<Array<IUserDataHistory>>
  abstract get(id: string): Observable<IUserDataHistory>;
  abstract save(request: IUserDataHistory): Observable<IResponse<string>>;
  abstract delete(id: string): Observable<IResponse<boolean>>;
}
