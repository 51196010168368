import { KeyValue } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FISCAL_CODE_TYPE_VALUES } from 'src/app/core/constants';
import { FiscalCodeType } from 'src/app/core/enums';
import { ICountry } from 'src/app/core/models/api/country';
import { Limits } from 'src/app/shared/utils';
import { fiscalCodeValidator } from 'src/app/shared/validators';

export class DebtorEditViewModel {
  private _form: FormGroup;
  private _fiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[] = FISCAL_CODE_TYPE_VALUES;
  private _countries: Array<ICountry> = [];

  readonly C_ID = 'id';
  readonly C_USER_ID = 'userId';
  readonly C_FISCAL_CODE_TYPE = 'fiscalCodeType';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_COUNTRY_ID = 'countryId';
  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_OWN_IDENTIFIER = 'ownIdentifier';
  readonly C_REQUESTED_AMOUNT = 'requestedAmount';
  readonly C_NOT_MONITORABLE = 'notMonitorable';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(),
      [this.C_USER_ID]: new FormControl(),
      [this.C_FISCAL_CODE_TYPE]: new FormControl(null, [Validators.required]),
      [this.C_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COUNTRY_ID]: new FormControl(null, []),
      [this.C_BUSINESS_NAME]: new FormControl('', [Validators.required]),
      [this.C_OWN_IDENTIFIER]: new FormControl(''),
      [this.C_REQUESTED_AMOUNT]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]),
      [this.C_NOT_MONITORABLE]: new FormControl()
    }, { validators: [fiscalCodeValidator(this.C_FISCAL_CODE_TYPE, this.C_FISCAL_CODE)] });
  }

  get fiscalCodeTypeValues(): KeyValue<FiscalCodeType, string>[] {
    return this._fiscalCodeTypeValues;
  }
  set fiscalCodeTypeValues(
    fiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[]
  ) {
    this._fiscalCodeTypeValues = fiscalCodeTypeValues;
  }

  get countries(): Array<ICountry> {
    return this._countries;
  }
  set countries(value: Array<ICountry>) {
    this._countries = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get userId(): number | null | undefined {
    return this._form.get(this.C_USER_ID)?.value;
  }
  set userId(value: number | null | undefined) {
    this._form.get(this.C_USER_ID)?.setValue(value);
  }

  get fiscalCodeType(): FiscalCodeType | null | undefined {
    return this._form.get(this.C_FISCAL_CODE_TYPE)?.value;
  }
  set fiscalCodeType(value: FiscalCodeType | null | undefined) {
    this._form.get(this.C_FISCAL_CODE_TYPE)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this._form.get(this.C_FISCAL_CODE)?.value;
  }
  set fiscalCode(value: string | null | undefined) {
    this._form.get(this.C_FISCAL_CODE)?.setValue(value);
  }

  get countryId(): number | null | undefined {
    return this._form.get(this.C_COUNTRY_ID)?.value;
  }
  set countryId(value: number | null | undefined) {
    this._form.get(this.C_COUNTRY_ID)?.setValue(value);
  }

  get businessName(): string | null | undefined {
    return this._form.get(this.C_BUSINESS_NAME)?.value;
  }
  set businessName(value: string | null | undefined) {
    this._form.get(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get ownIdentifier(): string | null | undefined {
    return this._form.get(this.C_OWN_IDENTIFIER)?.value;
  }
  set ownIdentifier(value: string | null | undefined) {
    this._form.get(this.C_OWN_IDENTIFIER)?.setValue(value);
  }

  get requestedAmount(): number | null | undefined {
    return this._form.get(this.C_REQUESTED_AMOUNT)?.value;
  }
  set requestedAmount(value: number | null | undefined) {
    this._form.get(this.C_REQUESTED_AMOUNT)?.setValue(value);
  }

  get notMonitorable(): boolean | null | undefined {
    return this._form.get(this.C_NOT_MONITORABLE)?.value;
  }
  set notMonitorable(value: boolean | null | undefined) {
    this._form.get(this.C_NOT_MONITORABLE)?.setValue(value);
  }
}
