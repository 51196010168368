import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { ContractCheckContract } from './contract-check.contract';


@Injectable({
  providedIn: 'root'
})

export class ContractCheckService implements ContractCheckContract {
  apiName = API_PATH_APPS.API_CREDIT_PORTAL;
  basePath = 'api/contract-check';

  constructor(private httpClient: HowdenHttpClientService) { }
  check(request: FormData): Observable<Blob> {
    return this.httpClient.postRespBlob(this.apiName, this.basePath, request, {
      reportProgress: true,
      responseType: 'blob'
    });
  }
}
