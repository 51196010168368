import { KeyValue } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidators } from '@howdeniberia/core-front';
import { DATA_STATUS_VALUES, FISCAL_CODE_TYPE_VALUES, MONITORING_PERIOD_VALUES } from 'src/app/core/constants';
import { DataStatus, FiscalCodeType, MonitoringPeriod } from 'src/app/core/enums';
import { IUserRole } from 'src/app/core/models/api/master/user-role';
import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { fiscalCodeValidator, passwordMatchValidator } from 'src/app/shared/validators';

export class UserDataEditViewModel {
  private _form: FormGroup;
  private _statusValues: KeyValue<DataStatus, string>[] = DATA_STATUS_VALUES;
  private _fiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[] = FISCAL_CODE_TYPE_VALUES;
  private _monitoringPeriodValues: KeyValue<MonitoringPeriod, string>[] = MONITORING_PERIOD_VALUES;
  private _roles: Array<IUserRole> = [];
  private _associations: Array<IUserDataReduced> = [];
  private _recruitmentPlans: Array<IRecruitmentPlan> = [];

  readonly C_ID = 'id';
  readonly C_ROLE = 'roleName';
  readonly C_NAME = 'name';
  readonly C_SURNAME = 'surname';
  readonly C_EMAIL = 'email';
  readonly C_PASSWORD = 'password';
  readonly C_PASSWORD_VALIDATION = 'userPasswordValidation';
  readonly C_CONTACT_NAME = 'contactPersonName';
  readonly C_CONTACT_EMAIL = 'contactPersonEmail';
  readonly C_PHONE_NUMBER = 'phoneNumber';
  readonly C_FISCAL_CODE_TYPE = 'fiscalCodeType';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_RECRUITMENT_PLAN = 'recruitmentPlanId';
  readonly C_MONITORING_PERIOD = 'monitoringPeriodicity';
  readonly C_CREDIT_OPINION_PRICE = 'creditOpinionPrice';
  readonly C_PARTICULAR_STYLES = 'particularStyles';
  readonly C_HEX_PRIMARY_COLOR = 'primaryColor';
  readonly C_HEX_SECONDARY_COLOR = 'secondaryColor';
  readonly C_STATUS = 'status';
  readonly C_ASSOCIATION = 'associationId';
  readonly C_SECONDARY_LOGO = 'secondaryLogo';
  readonly C_REPORT_LOGO = 'reportLogo';
  readonly C_SECONDARY_LOGO_FILE = 'secondaryLogoFile';
  readonly C_REPORT_LOGO_FILE = 'reportLogoFile';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(),
      [this.C_ROLE]: new FormControl('', [Validators.required]),
      [this.C_EMAIL]: new FormControl('', [Validators.required, Validators.email]),
      [this.C_NAME]: new FormControl('', [Validators.required]),
      [this.C_SURNAME]: new FormControl('', [Validators.required]),
      [this.C_PASSWORD]: new FormControl('', [Validators.required, PasswordValidators.format]),
      [this.C_PASSWORD_VALIDATION]: new FormControl('', [Validators.required, PasswordValidators.format]),
      [this.C_CONTACT_NAME]: new FormControl('', []),
      [this.C_CONTACT_EMAIL]: new FormControl('', [Validators.email]),
      [this.C_PHONE_NUMBER]: new FormControl('', []),
      [this.C_FISCAL_CODE_TYPE]: new FormControl(''),
      [this.C_FISCAL_CODE]: new FormControl(''),
      [this.C_BUSINESS_NAME]: new FormControl(''),
      [this.C_PARTICULAR_STYLES]: new FormControl('', []),
      [this.C_HEX_PRIMARY_COLOR]: new FormControl('', []),
      [this.C_HEX_SECONDARY_COLOR]: new FormControl('', []),
      [this.C_REPORT_LOGO]: new FormControl('', []),
      [this.C_SECONDARY_LOGO_FILE]: new FormControl('', []),
      [this.C_REPORT_LOGO_FILE]: new FormControl('', []),
      [this.C_SECONDARY_LOGO]: new FormControl('', []),
      [this.C_RECRUITMENT_PLAN]: new FormControl('', []),
      [this.C_MONITORING_PERIOD]: new FormControl('', []),
      [this.C_CREDIT_OPINION_PRICE]: new FormControl('', []),
      [this.C_ASSOCIATION]: new FormControl('', []),
      [this.C_STATUS]: new FormControl('', [])
    }, {
      validators: [
        passwordMatchValidator(this.C_PASSWORD, this.C_PASSWORD_VALIDATION),
        fiscalCodeValidator(this.C_FISCAL_CODE_TYPE, this.C_FISCAL_CODE)
      ]
    });
  }

  get fiscalCodeTypeValues(): KeyValue<FiscalCodeType, string>[] {
    return this._fiscalCodeTypeValues;
  }
  set fiscalCodeTypeValues(userFiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[]
  ) {
    this._fiscalCodeTypeValues = userFiscalCodeTypeValues;
  }

  get statusValues(): KeyValue<DataStatus, string>[] {
    return this._statusValues;
  }
  set statusValues(statusValues: KeyValue<DataStatus, string>[]) {
    this._statusValues = statusValues;
  }

  get monitoringPeriodValues(): KeyValue<MonitoringPeriod, string>[] {
    return this._monitoringPeriodValues;
  }
  set monitoringPeriodValues(monitoringPeriodValues: KeyValue<MonitoringPeriod, string>[]) {
    this._monitoringPeriodValues = monitoringPeriodValues;
  }

  get roles(): Array<IUserRole> {
    return this._roles;
  }
  set roles(value: Array<IUserRole>) {
    this._roles = value;
  }

  get associations(): Array<IUserDataReduced> {
    return this._associations;
  }
  set associations(value: Array<IUserDataReduced>) {
    this._associations = value;
  }

  get recruitmentPlans(): Array<IRecruitmentPlan> {
    return this._recruitmentPlans;
  }
  set recruitmentPlans(recruitmentPlans: Array<IRecruitmentPlan>) {
    this._recruitmentPlans = recruitmentPlans;
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get userRole(): string | null | undefined {
    return this._form.get(this.C_ROLE)?.value;
  }
  set userRole(value: string | null | undefined) {
    this._form.get(this.C_ROLE)?.setValue(value);
  }

  get userName(): string | null | undefined {
    return this._form.get(this.C_NAME)?.value;
  }
  set userName(value: string | null | undefined) {
    this._form.get(this.C_NAME)?.setValue(value);
  }

  get userSurname(): string | null | undefined {
    return this._form.get(this.C_SURNAME)?.value;
  }
  set userSurname(value: string | null | undefined) {
    this._form.get(this.C_SURNAME)?.setValue(value);
  }

  get userId(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set userId(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get userEmail(): string | null | undefined {
    return this._form.get(this.C_EMAIL)?.value;
  }
  set userEmail(value: string | null | undefined) {
    this._form.get(this.C_EMAIL)?.setValue(value);
  }

  get userPassword(): string | null | undefined {
    return this._form.get(this.C_PASSWORD)?.value;
  }
  set userPassword(value: string | null | undefined) {
    this._form.get(this.C_PASSWORD)?.setValue(value);
  }

  get userPasswordValidation(): string | null | undefined {
    return this._form.get(this.C_PASSWORD_VALIDATION)?.value;
  }
  set userPasswordValidation(value: string | null | undefined) {
    this._form.get(this.C_PASSWORD_VALIDATION)?.setValue(value);
  }

  get userContactName(): string | null | undefined {
    return this._form.get(this.C_CONTACT_NAME)?.value;
  }
  set userContactName(value: string | null | undefined) {
    this._form.get(this.C_CONTACT_NAME)?.setValue(value);
  }

  get userContactEmail(): string | null | undefined {
    return this._form.get(this.C_CONTACT_EMAIL)?.value;
  }
  set userContactEmail(value: string | null | undefined) {
    this._form.get(this.C_CONTACT_EMAIL)?.setValue(value);
  }

  get phoneNumber(): string | null | undefined {
    return this._form.get(this.C_PHONE_NUMBER)?.value;
  }
  set phoneNumber(value: string | null | undefined) {
    this._form.get(this.C_PHONE_NUMBER)?.setValue(value);
  }

  get userFiscalCodeType(): FiscalCodeType | null | undefined {
    return this._form.get(this.C_FISCAL_CODE_TYPE)?.value;
  }
  set userFiscalCodeType(value: FiscalCodeType | null | undefined) {
    this._form.get(this.C_FISCAL_CODE_TYPE)?.setValue(value);
  }

  get userFiscalCode(): string | null | undefined {
    return this._form.get(this.C_FISCAL_CODE)?.value;
  }
  set userFiscalCode(value: string | null | undefined) {
    this._form.get(this.C_FISCAL_CODE)?.setValue(value);
  }

  get businessName(): string | null | undefined {
    return this._form.get(this.C_BUSINESS_NAME)?.value;
  }
  set businessName(value: string | null | undefined) {
    this._form.get(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get status(): DataStatus | null | undefined {
    return this._form.get(this.C_STATUS)?.value;
  }
  set status(value: DataStatus | null | undefined) {
    this._form.get(this.C_STATUS)?.setValue(value);
  }

  get recruitmentPlan(): number | null | undefined {
    return this._form.get(this.C_RECRUITMENT_PLAN)?.value;
  }
  set recruitmentPlan(value: number | null | undefined) {
    this._form.get(this.C_RECRUITMENT_PLAN)?.setValue(value);
  }

  get monitoringPeriod(): number | null | undefined {
    return this._form.get(this.C_MONITORING_PERIOD)?.value;
  }
  set monitoringPeriod(value: number | null | undefined) {
    this._form.get(this.C_MONITORING_PERIOD)?.setValue(value);
  }

  get creditOpinionPrice(): number | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_PRICE)?.value;
  }
  set creditOpinionPrice(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_PRICE)?.setValue(value);
  }

  get association(): number | null | undefined {
    return this._form.get(this.C_ASSOCIATION)?.value;
  }
  set association(value: number | null | undefined) {
    this._form.get(this.C_ASSOCIATION)?.setValue(value);
  }

  get particularStyles(): boolean | null | undefined {
    return this._form.get(this.C_PARTICULAR_STYLES)?.value;
  }
  set particularStyles(value: boolean | null | undefined) {
    this._form.get(this.C_PARTICULAR_STYLES)?.setValue(value);
  }

  get hexPrimaryColor(): string | null | undefined {
    return this._form.get(this.C_HEX_PRIMARY_COLOR)?.value;
  }
  set hexPrimaryColor(value: string | null | undefined) {
    this._form.get(this.C_HEX_PRIMARY_COLOR)?.setValue(value);
  }

  get hexSecondaryColor(): string | null | undefined {
    return this._form.get(this.C_HEX_SECONDARY_COLOR)?.value;
  }
  set hexSecondaryColor(value: string | null | undefined) {
    this._form.get(this.C_HEX_SECONDARY_COLOR)?.setValue(value);
  }

  get secondaryLogoFile(): any | null | undefined {
    return this._form.get(this.C_SECONDARY_LOGO_FILE)?.value;
  }
  set secondaryLogoFile(value: any | null | undefined) {
    this._form.get(this.C_SECONDARY_LOGO_FILE)?.setValue(value);
  }

  get reportLogoFile(): any | null | undefined {
    return this._form.get(this.C_REPORT_LOGO_FILE)?.value;
  }
  set reportLogoFile(value: any | null | undefined) {
    this._form.get(this.C_REPORT_LOGO_FILE)?.setValue(value);
  }

  get secondaryLogo(): string | null | undefined {
    return this._form.get(this.C_SECONDARY_LOGO)?.value;
  }
  set secondaryLogo(value: string | null | undefined) {
    this._form.get(this.C_SECONDARY_LOGO)?.setValue(value);
  }

  get reportLogo(): string | null | undefined {
    return this._form.get(this.C_REPORT_LOGO)?.value;
  }
  set reportLogo(value: string | null | undefined) {
    this._form.get(this.C_REPORT_LOGO)?.setValue(value);
  }
}
