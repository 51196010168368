import { IDebtorCreditGranted } from 'src/app/core/models/api/monitoring';
import { IDebtorCreditGrantedViewModel } from '../models';


export abstract class DebtorCreditGrantedMapper {
  static mapForSearch(
    source: IDebtorCreditGranted
  ): IDebtorCreditGrantedViewModel {
    return {
      id: source.id,
      fiscalCode: source.fiscalCode,
      businessName: source.businessName,
      ownIdentifier: source.ownIdentifier,
      requestedAmount: source.requestedAmount,
      monitoringDate: source.monitoringDate,
      grantedAmount: source.grantedAmount
    } as IDebtorCreditGrantedViewModel;
  }
}
