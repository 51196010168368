import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HowdenAuthService, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { UserRole } from 'src/app/core/enums';
import { DebtorContract } from 'src/app/core/services/api/debtor';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { DebtorEditDialogComponent } from '../../dialogs/debtor-edit-dialog/pages/debtor-edit-dialog.component';
import { IDebtor } from './../../../../../core/models/api/monitoring/debtor/debtor';

import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { DebtorMapper } from '../../mappers';
import { DebtorSearchViewModel, IDebtorViewModel } from '../../models';

@Component({
  selector: 'howden-debtor-search',
  templateUrl: './debtor-search.component.html',
  styleUrls: ['./debtor-search.component.scss']
})
export class DebtorSearchComponent implements OnInit, OnDestroy {
  model = new DebtorSearchViewModel();

  isClientProfile = false;
  canAddDebtor = false;

  private _subscriptions = new SubSink();

  constructor(
    private authSrv: HowdenAuthService,
    private dialog: MatDialog,
    private debtorSrv: DebtorContract,
    private userDataSrv: UserDataContract,
    private uiBlockerSrv: UIBlockerContract
  ) { }

  ngOnInit(): void {
    this.isClientProfile = this.authSrv.isUserAuthorized([
      UserRole.Association,
      UserRole.AssociationMember,
      UserRole.IndependentClient
    ]) && !this.authSrv.isSuperAdmin;

    if (this.isClientProfile) {
      this.updateInfo();
    } else {
      this.userDataSrv.getClients(true).subscribe((data) => {
        this.model.dataClients = data;
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onUserSelectChange(monitorableClient: IUserDataReduced) {
    this.model.userId = monitorableClient.userId;

    this.model.filterForm.get(this.model.C_FISCAL_CODE)?.setValue(null);
    this.model.filterForm.get(this.model.C_BUSINESS_NAME)?.setValue(null);
    this.model.filterForm.get(this.model.C_OWN_IDENTIFIER)?.setValue(null);
    this.model.filterForm.get(this.model.C_NOT_MONITORABLE)?.setValue(false);

    this.onFilterChanges();
  }

  onFilterChanges(): void {
    this.model.searchRequest.pageNumber = 0;
    this.model.updateServerSideFilters();
    this.refresh();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onNew(): void {
    const inputData = { id: null };
    this.showEditDialog(inputData);
  }

  onRowClicked(event: IDebtorViewModel): void {
    const inputData = { id: event.id };
    this.showEditDialog(inputData);
  }

  private refresh(): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.debtorSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IDebtor>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) =>
          DebtorMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }

  private showEditDialog(inputData: any) {
    const dialogRef = this.dialog.open(DebtorEditDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => {
      if (this.isClientProfile) {
        this.updateInfo();
      } else {
        this.refresh();
      }
    });
  }

  private updateInfo() {
    this.debtorSrv.getCounter().subscribe((data) => {
      this.model.debtorCounter = data;
      this.model.counterInfo = `${data.actualNumber} / ${data.maxNumber}`;
      this.model.counterMsg = data.message ?? '';
      this.canAddDebtor = data.canAddDebtor;
      this.refresh();
    });
  }
}
