<form [formGroup]="model.form">
  <div class="howden-dialog-content">
    <div mat-dialog-title class="margin-bottom-0">
      <div class="title-text" i18n="@@app.user-data.history.dialog.title">Plan de contratación</div>
      <div class="subtitle-text"></div>
    </div>
    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4" >
          <mat-label i18n="@@app.user-data.history.dialog.recruitmentPlan">Plan contratado</mat-label>
          <mat-select
            (selectionChange)="onChangeRecruitmentPlan(true)"
            [formControlName]="model.C_RECRUITMENT_PLAN"
            [howdenControlError]="recruitmentPlanError"
          >
            <mat-option></mat-option>
            <mat-option
              *ngFor="let item of model.recruitmentPlans | howdenArraySort : 'description'"
              [value]="item.id"
            >
              {{ item.description }}
            </mat-option>
          </mat-select>
          <mat-error #recruitmentPlanError></mat-error>
        </mat-form-field>
      <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.user-data.history.dialog.startDate">Fecha Inicio</mat-label>
          <input matInput
            [matDatepicker]="startDatePicker"
            [formControlName]="model.C_START_DATE"
            [howdenControlError]="startDateError"
            [min]="minDate"
          />
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error #startDateError></mat-error>
        </mat-form-field>
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.user-data.history.dialog.endDate">Fecha Fin</mat-label>
          <input matInput
            [matDatepicker]="endDatePicker"
            [formControlName]="model.C_END_DATE"
            [howdenControlError]="endDateError"
            [min]="minDate"
          />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <mat-error #endDateError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex f-gap--10px margin-bottom-10">
        <div class="f-basis--4"></div>
        <div class="f-basis--8 small-letter">
          <mat-error *ngIf="this.model.form.errors?.['dateRange']" i18n="@@app.recruitment-plan.edit.dateRange.error">
            La fecha de inicio no puede ser mayor que la fecha fin.
          </mat-error>
          <mat-error *ngIf="this.model.form.errors?.['startDateInitMonth']" i18n="@@recruitment-plan.edit.startDate.initMonth.error">
            La fecha de inicio debe coincidir con inicio de mes.
          </mat-error>
          <mat-error *ngIf="this.model.form.errors?.['startDateFuture']" i18n="@@recruitment-plan.edit.startDate.future.error">
            La fecha de inicio no puede ser anterior a hoy.
          </mat-error>
          <mat-error *ngIf="this.model.form.errors?.['endDateEndMonth']" i18n="@@recruitment-plan.edit.endDate.endMonth.error">
            La fecha de finalización debe coincidir con fin de mes.
          </mat-error>
          <mat-error *ngIf="this.model.form.errors?.['endDateFuture']" i18n="@@recruitment-plan.edit.endDate.endMonth.error">
            La fecha de finalización no puede ser anterior a hoy.
          </mat-error>
        </div>
      </div>
      <div class="flex f-gap--10px">
        <div class="f-basis--4">&nbsp;</div>
        <div class="f-basis--4" *ngIf="!hasMonitoring"></div>
        <mat-form-field class="f-basis--4" *ngIf="hasMonitoring" >
          <mat-label i18n="@@app.user-data.history.dialog.monitorigPeriod">Periodo monitorización</mat-label>
          <mat-select
            [formControlName]="model.C_MONITORING_PERIODICITY"
            [howdenControlError]="monitoringPeriodError"
          >
            <mat-option></mat-option>
            <mat-option
              *ngFor="let item of model.monitoringPeriodValues | howdenArraySort : 'value'"
              [value]="item.key"
            >
              {{ item.value }}
            </mat-option>
          </mat-select>
          <mat-error #monitoringPeriodError></mat-error>
        </mat-form-field>
        <div class="f-basis--4" *ngIf="!hasFlexPlan || isAssociationMember"></div>
        <howden-decimal
          *ngIf="hasFlexPlan && !isAssociationMember"
          class="f-basis--4"
          [formControlName]="model.C_CREDIT_OPINION_PRICE"
          i18n-label="@@app.user-data.history.dialog.creditOpinionPrice"
          label="Precio opinión crédito"
        ></howden-decimal>

      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>
      <button type="button" color="primary" mat-raised-button [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
