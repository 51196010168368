import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidCif, isValidDni, isValidNie } from 'nif-dni-nie-cif-validation';
import { FiscalCodeType } from 'src/app/core/enums';

export function fiscalCodeValidator(fiscalCodeTypeFieldName: string, fiscalCodeFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fiscalCodeType = control.get(fiscalCodeTypeFieldName)?.value as FiscalCodeType;
    const fiscalCode = control.get(fiscalCodeFieldName)?.value;

    if (!fiscalCodeType || !fiscalCode) {
      return null;
    }

    let isValid = false;

    switch (fiscalCodeType) {
      case FiscalCodeType.Cif:
        isValid = isValidCif(fiscalCode);
        break;
      case FiscalCodeType.Nif:
        isValid = isValidDni(fiscalCode);
        break;
      case FiscalCodeType.Nie:
        isValid = isValidNie(fiscalCode);
        break;
      default:
        isValid = true;
        break;
    }

    return isValid ? null : { 'fiscalCodeInvalid': true };
  };
}
