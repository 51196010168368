import { FormControl, FormGroup, Validators } from '@angular/forms';

export class CreditOpinionAttachmentDialogViewModel {
  private _form: FormGroup;

  readonly C_ID = 'id';
  readonly C_CREDIT_OPINION_ID = 'creditOpinionId';
  readonly C_DESCRIPCION = 'description';
  readonly C_FILENAME = 'fileName';
  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, []),
      [this.C_CREDIT_OPINION_ID]: new FormControl(null, [Validators.required]),
      [this.C_DESCRIPCION]: new FormControl('', []),
      [this.C_FILENAME]: new FormControl('', []),
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get creditOpinionId(): number | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_ID)?.value;
  }
  set creditOpinionId(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_ID)?.setValue(value);
  }

  get description(): string | null | undefined {
    return this._form.get(this.C_DESCRIPCION)?.value;
  }
  set description(value: string | null | undefined) {
    this._form.get(this.C_DESCRIPCION)?.setValue(value);
  }

  get fileName(): string | null | undefined {
    return this._form.get(this.C_FILENAME)?.value;
  }
  set fileName(value: string | null | undefined) {
    this._form.get(this.C_FILENAME)?.setValue(value);
  }

  get file(): any | null | undefined {
    return this._form.get(this.C_FILE)?.value;
  }
  set file(value: any | null | undefined) {
    this._form.get(this.C_FILE)?.setValue(value);
  }
}
