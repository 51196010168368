import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { DashboardPeriod } from 'src/app/core/enums';
import { IDashboardBusinessEvolution, IDashboardBusinessFilter, IDashboardCompanyAssess } from 'src/app/core/models/api/dashboard';
import { DashboardContract } from './dashboard.contract';

@Injectable({
  providedIn: 'root'
})
export class DashboardService implements DashboardContract {
  apiName = API_PATH_APPS.API_CREDIT_PORTAL;
  basePath = 'api/dashboard';

  constructor(
    private httpClient: HowdenHttpClientService
  ) { }

  getCreditOpinionHistory(period: DashboardPeriod): Observable<Array<IDashboardCompanyAssess>> {
    return this.httpClient.get<Array<IDashboardCompanyAssess>>(
      this.apiName,
      `${this.basePath}/credit-opinion-history/${period}`
    );
  }

  getBusinessEvolution(request: IDashboardBusinessFilter): Observable<IDashboardBusinessEvolution> {
    return this.httpClient.post<IDashboardBusinessEvolution>(
      this.apiName,
      `${this.basePath}/business-evolution`,
      request
    );
  }
}
