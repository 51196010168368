import { KeyValue } from '@angular/common';

import { CreditOpinionSituation } from '../enums';

export const CREDIT_OPINION_SITUATION_VALUES: KeyValue<
  CreditOpinionSituation,
  string
>[] = [
  {
    key: CreditOpinionSituation.Pending,
    value: $localize`:@@app.route.credit-opinion.situation.enums.pending:Pendiente`
  },
  {
    key: CreditOpinionSituation.Answered,
    value: $localize`:@@app.route.credit-opinion.situation.enums.answered:Respondida`
  },
  {
    key: CreditOpinionSituation.Rejected,
    value: $localize`:@@app.route.credit-opinion.situation.enums.rejected:Rechazada`
  }
] as KeyValue<CreditOpinionSituation, string>[];
