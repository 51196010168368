import { IDebtorCreditGranted } from 'src/app/core/models/api/monitoring';
import { DebtorCreditGrantedEditViewModel } from '../models';


export abstract class DebtorCreditGrantedEditMapper {
  static mapForEdit(source: IDebtorCreditGranted, target: DebtorCreditGrantedEditViewModel): void {
    target.id = source.id;
    target.debtorId = source.debtorId;
    target.userId = source.userId;
    target.fiscalCodeType = source.fiscalCodeType;
    target.fiscalCode = source.fiscalCode;
    target.countryId = source.countryId;
    target.businessName = source.businessName;
    target.requestedAmount = source.requestedAmount;
    target.monitoringDate = source.monitoringDate;
    target.lastGrantedAmount = source.lastGrantedAmount;
    target.grantedAmount = source.grantedAmount;
  }

  static mapForUpdate(source: DebtorCreditGrantedEditViewModel): IDebtorCreditGranted {
    return {
      id: source.id,
      debtorId: source.debtorId,
      userId: source.userId,
      fiscalCodeType: source.fiscalCodeType,
      fiscalCode: source.fiscalCode,
      countryId: source.countryId,
      businessName: source.businessName,
      requestedAmount: source.requestedAmount,
      monitoringDate: source.monitoringDate,
      lastGrantedAmount: source.lastGrantedAmount,
      grantedAmount: source.grantedAmount
    } as IDebtorCreditGranted;
  }
}
