import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HowdenUserService } from '@howdeniberia/core-front';
import { EChartsOption } from 'echarts';
import { IStatistic } from 'src/app/core/models/api/core/statistic';

@Component({
  selector: 'howden-dashboard-line-chart',
  templateUrl: './dashboard-line-chart.component.html',
  styleUrls: ['./dashboard-line-chart.component.scss']
})
export class DashboardLineChartComponent implements OnChanges {
  @Input() requestedAmount: Array<IStatistic> = [];
  @Input() grantedAmount: Array<IStatistic> = [];
  @Input() valueFormat = '1.0-2';

  options: EChartsOption = {};

  constructor(private _decimalPipe: DecimalPipe, private userSrv: HowdenUserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const textColor = this.userSrv.selectedTheme === 'dark' ? '#ffffff' : '#173f35';

    const colors = this.userSrv.selectedTheme === 'dark' ? ['#FFFFFF', '#809590'] : ['#173f35', '#97a9a4'];

    this.options = {
      color: colors,
      tooltip: {
        trigger: 'none',
        axisPointer: {
          type: 'cross'
        }
      },
      legend: {
        textStyle: {
          color: textColor
        }
      },
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0, 1],
          filterMode: 'filter',
          moveOnMouseMove: true,
          moveOnMouseWheel: true
        }
      ],
      grid: {
        left: '2%',
        right: '2%',
        bottom: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisPointer: {
            label: {
              formatter: (params: any) => 'Crédito solicitado' + (params.seriesData.length ? '：' + this._decimalPipe.transform(params.seriesData[0].data, this.valueFormat) : '')
            }
          },
          // prettier-ignore
          data: this.requestedAmount.map((x) => x.description)
        },
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              type: 'dashed',
              shadowColor: '#7ce0d3'
            }
          },
          axisPointer: {
            label: {
              formatter: (params: any) => 'Crédito concedido' + (params.seriesData.length ? '：' + this._decimalPipe.transform(params.seriesData[0].data, this.valueFormat) : '')
            }
          },
          // prettier-ignore
          data: this.grantedAmount.map((x) => x.description)
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: (value: any): any => this._decimalPipe.transform(value, this.valueFormat)
          },
          axisPointer: {
            label: {
              formatter: (params: any): any => this._decimalPipe.transform(params.value, this.valueFormat)
            }
          }
        }
      ],
      series: [
        {
          name: 'Crédito solicitado',
          type: 'line',
          xAxisIndex: 1,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: this.requestedAmount.map((x) => x.value)
        },
        {
          name: 'Crédito concedido',
          type: 'line',
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: this.grantedAmount.map((x) => x.value)
        }
      ]
    };
  }
}
