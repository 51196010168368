import { DATA_STATUS_VALUES } from 'src/app/core/constants';
import { DataStatus } from 'src/app/core/enums';
import { IUserExtended } from 'src/app/core/models/api/user-data';
import { IUserDataViewModel, UserDataEditViewModel } from '../models';

export abstract class UserDataMapper {
  static mapForEdit(source: IUserExtended, target: UserDataEditViewModel): void {
    target.id = source.id;
    target.userRole = source.roleName;
    target.userName = source.name;
    target.userSurname = source.surname;
    target.userEmail = source.email;
    target.userContactName = source.contactPersonName;
    target.userContactEmail = source.contactPersonEmail;
    target.phoneNumber = source.phoneNumber;
    target.userFiscalCodeType = source.fiscalCodeType;
    target.userFiscalCode = source.fiscalCode;
    target.businessName = source.businessName;
    target.particularStyles = source.particularStyles;
    target.hexPrimaryColor = source.primaryColor;
    target.hexSecondaryColor = source.secondaryColor;
    target.status = source.status;
    target.association = source.associationId;
    target.secondaryLogo = source.secondaryLogo;
    target.reportLogo = source.reportLogo;
  }

  static mapForSearch(source: IUserExtended): IUserDataViewModel {
    let userInfo = '';
    if (source.businessName) {
      userInfo = `${source.businessName} [${source.fiscalCode}]`;
    } else {
      userInfo = `${source.name} ${source.surname}`;
    }

    let contact = '';
    if (source.contactPersonName) {
      contact = source.contactPersonName;
    }
    if (source.contactPersonEmail) {
      contact = `${contact} (${source.contactPersonEmail})`;
    }
    let association = '';
    if (source.associationId) {
      association = `${source.associationBusinessName} [${source.associationFiscalCode}]`;
    }

    return {
      id: source.id,
      email: source.email,
      roleDescription: source.roleDescription,
      userInfo: userInfo,
      association: association,
      contact: contact,
      status: UserDataMapper.mapStatus(source.status)
    } as IUserDataViewModel;
  }

  private static mapStatus(status?: DataStatus | null) {
    if (typeof status === 'undefined' || status === null) {
      return '';
    }

    return DATA_STATUS_VALUES.find((x) => x.key === status)?.value;
  }
}
