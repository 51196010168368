import { KeyValue } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig
} from '@howdeniberia/core-front';
import { MONTH_VALUES } from 'src/app/core/constants';
import { Month } from 'src/app/core/enums';
import { IDashboardBusinessFilter, IDashboardBusinessUser } from 'src/app/core/models/api/dashboard';
import { IDashboardBusinessKpi } from './../../../core/models/api/dashboard/business-evolution/dashboard-business-kpi';


export class BusinessEvolutionViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IDashboardBusinessFilter;
  private _monthsValues: KeyValue<Month, string>[] = MONTH_VALUES;
  private _actualKpis: IDashboardBusinessKpi;
  private _totalKpis: IDashboardBusinessKpi;

  readonly C_YEAR = 'year';
  readonly C_MONTH = 'month';

  constructor() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    this._filterForm = new FormGroup({
      [this.C_MONTH]: new FormControl(month, [Validators.required]),
      [this.C_YEAR]: new FormControl(year, [Validators.required])
    });

    this._searchRequest = {
      year: year,
      month: month
    } as IDashboardBusinessFilter;

    this._actualKpis = {
      requestsMonthly: 0,
      requestsAccumulated: 0,
      requestsExercise: 0,
      requestsYear: 0,
      incomeMonthly: 0,
      incomeAccumulated: 0,
      incomeExercise: 0
    } as IDashboardBusinessKpi;

    this._totalKpis = {
      requestsMonthly: 0,
      requestsAccumulated: 0,
      requestsExercise: 0,
      requestsYear: 0,
      incomeMonthly: 0,
      incomeAccumulated: 0,
      incomeExercise: 0
    } as IDashboardBusinessKpi;
  }

  dataDashboardBusinessUser: IDashboardBusinessUser[] = [];
  columnsDashboardBusinessUser: IHowdenColumnConfig[] = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.dashboard.business-evolution.fiscalCode.column:ID Fiscal`,
      canSort: true,
      width: '10%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.dashboard.business-evolution.businessName.column:Razón social`,
      canSort: true,
      width: '25%'
    },
    {
      fieldName: 'association',
      fieldHeader: $localize`:@@app.dashboard.business-evolution.association.column:Asociación`,
      canSort: true,
      width: '15%'
    },
    {
      fieldName: 'recruitmentPlan',
      fieldHeader: $localize`:@@app.dashboard.business-evolution.recruitmentPlan.column:Plan contratado`,
      canSort: true,
      width: '15%'
    },
    {
      fieldName: 'quotaPlan',
      fieldHeader: $localize`:@@app.dashboard.business-evolution.quotaPlan.column:Cuota plan`,
      canSort: true,
      width: '10%',
      pipeToApply: ColumnPipeTypes.CURRENCY,
      aligment: ColumnAligment.RIGHT
    },
    {
      fieldName: 'extraAmount',
      fieldHeader: $localize`:@@app.dashboard.business-evolution.extraAmount.column:Importe extra`,
      canSort: true,
      width: '10%',
      pipeToApply: ColumnPipeTypes.CURRENCY,
      aligment: ColumnAligment.RIGHT
    },
    {
      fieldName: 'totalInvoice',
      fieldHeader: $localize`:@@app.dashboard.business-evolution.totalInvoice.column:Total a facturar`,
      canSort: true,
      width: '10%',
      pipeToApply: ColumnPipeTypes.CURRENCY,
      aligment: ColumnAligment.RIGHT
    }
  ];

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._filterForm.get(controlName)!;
  }

  get monthsValues(): KeyValue<Month, string>[] {
    return this._monthsValues;
  }

  get actualKpis(): IDashboardBusinessKpi {
    return this._actualKpis;
  }
  set actualKpis(value: IDashboardBusinessKpi) {
    this._actualKpis = value;
  }
  get resetActualKpis(): IDashboardBusinessKpi {
    this._actualKpis = {
      requestsMonthly: 0,
      requestsAccumulated: 0,
      requestsExercise: 0,
      requestsYear: 0,
      incomeMonthly: 0,
      incomeAccumulated: 0,
      incomeExercise: 0
    } as IDashboardBusinessKpi;

    return this.actualKpis;
  }

  get totalKpis(): IDashboardBusinessKpi {
    return this._totalKpis;
  }
  set totalKpis(value: IDashboardBusinessKpi) {
    this._totalKpis = value;
  }
  get resetTotalKpis(): IDashboardBusinessKpi {
    this._totalKpis = {
      requestsMonthly: 0,
      requestsAccumulated: 0,
      requestsExercise: 0,
      requestsYear: 0,
      incomeMonthly: 0,
      incomeAccumulated: 0,
      incomeExercise: 0
    } as IDashboardBusinessKpi;

    return this.totalKpis;
  }

  get searchRequest(): IDashboardBusinessFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }
  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get year(): number | null | undefined {
    return this.getControl(this.C_YEAR)?.value;
  }
  set year(value: number | null | undefined) {
    this.getControl(this.C_YEAR)?.setValue(value);
  }

  get month(): number | null | undefined {
    return this.getControl(this.C_MONTH)?.value;
  }
  set month(value: number | null | undefined) {
    this.getControl(this.C_MONTH)?.setValue(value);
  }

  updateServerSideFilters(): void {
    this.searchRequest.year = this.year;
    this.searchRequest.month = this.month;
  }
}
