import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IRecruitmentPlan, IRecruitmentPlanFilter } from 'src/app/core/models/api/recruitment-plan';
import { RecruitmentPlanContract } from './recruitment-plan.contract';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentPlanService implements RecruitmentPlanContract {
  apiName = API_PATH_APPS.API_CREDIT_PORTAL;
  basePath = 'api/recruitment-plan';

  constructor(private httpClient: HowdenHttpClientService) { }

  search(request: IRecruitmentPlanFilter): Observable<Array<IRecruitmentPlan>> {
    return this.httpClient.post<Array<IRecruitmentPlan>>(this.apiName, `${this.basePath}/search`, request);
  }

  get(id: string): Observable<IRecruitmentPlan> {
    return this.httpClient.get<IRecruitmentPlan>(this.apiName, `${this.basePath}/${id}`);
  }

  save(request: FormData): Observable<IResponse<string>> {
    return this.httpClient.post<IResponse<string>>(this.apiName, this.basePath, request);
  }

  downloadContractTemplate(id: string): Observable<Blob> {
    return this.httpClient.getBlob(this.apiName, `${this.basePath}/download-contract-template/${id}`, {
      reportProgress: true,
      responseType: 'blob'
    });
  }
}
