import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { UserDataEditHistoryViewModel } from './../../models/user-data-edit-history.viewmodel';

import { UserDataHistoryContract } from 'src/app/core/services/api/user-data-history';
import { ConfirmationDialogContract, UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UserDataHistoryDialogComponent } from '../../dialogs/user-data-history-dialog/pages/user-data-history-dialog/user-data-history-dialog.component';
import { UserDataHistoryMapper } from '../../mappers';
import { IUserDataHistoryViewModel } from '../../models';

@Component({
  selector: 'howden-user-data-history',
  templateUrl: './user-data-history.component.html',
  styleUrls: ['./user-data-history.component.scss']
})
export class UserDataHistoryComponent implements OnInit, OnDestroy {
  model = new UserDataEditHistoryViewModel();

  @Input() userId: string | null | undefined = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: UIBlockerContract,
    private confirmDialogSrv: ConfirmationDialogContract,
    private userDataHistorySrv: UserDataHistoryContract
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onNew(): void {
    const inputData = { userId: this.userId, id: null };
    this.showEditDialog(inputData);
  }

  onRowClicked(event: IUserDataHistoryViewModel): void {
    if (event.isLow === undefined || event.isLow === null || event.isLow === false) {
      const inputData = { userId: this.userId, id: event.id };
      this.showEditDialog(inputData);
    }
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'delete') {
      this.delete(event);
    }
  }

  private showEditDialog(inputData: any) {
    const dialogRef = this.dialog.open(UserDataHistoryDialogComponent, {
      minWidth: '700px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(_ => this.refresh());
  }

  private refresh(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.userDataHistorySrv.search(this.userId as string)
    ]).subscribe(([exercises]) => {
      this.model.data = UserDataHistoryMapper.mapForSearch(exercises);

      this.uiBlockerSrv.unblock();
    });
  }

  private delete(event: IButtonActionData): void {
    const historyId = event.row.id;
    const title: string = $localize`: @@app.user-data.edit.history.delete.title:Borrado de Histórico`;
    const question: string = $localize`: @@app.user-data.edit.history.delete.subtitle: Se borrará el histórico del usuario, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`: @@app.user-data.edit.history.delete.confirm.subtitle:Finalizado Correctamente`;

          this.uiBlockerSrv.block();
          this.userDataHistorySrv.delete(historyId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }
}
