import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, HowdenAlertService, IDialogResult } from '@howdeniberia/core-front';
import moment from 'moment';
import { forkJoin, tap } from 'rxjs';
import { RecruitmentPlan } from 'src/app/core/enums';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { UserDataHistoryContract } from 'src/app/core/services/api/user-data-history';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { UserDataHistoryMapper } from '../../../../mappers';
import { UserDataHistoryDialogViewModel } from '../../models';

@Component({
  selector: 'howden-user-data-history-dialog',
  templateUrl: './user-data-history-dialog.component.html',
  styleUrls: ['./user-data-history-dialog.component.scss']
})
export class UserDataHistoryDialogComponent implements OnInit, OnDestroy {
  model = new UserDataHistoryDialogViewModel();

  isAssociationMember = false;
  hasFlexPlan = false;
  hasMonitoring = false;

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<UserDataHistoryDialogViewModel>,
    private uiBlockerSrv: UIBlockerContract,
    private alertSrv: HowdenAlertService,
    private userDataSrv: UserDataContract,
    private userDataHistorySrv: UserDataHistoryContract
  ) { }

  ngOnInit(): void {
    if (this.userDataHistoryId !== 'undefined' && this.userDataHistoryId !== null) {
      this.uiBlockerSrv.block();
      forkJoin([
        this.userDataSrv.getRecruitmentPlanInfo(this.userId as number),
        this.userDataHistorySrv.get(this.userDataHistoryId)
      ]).subscribe(([recruitmentPlanInfo, history]) => {
        this.model.recruitmentPlans = recruitmentPlanInfo.recruitmentPlans;
        this.isAssociationMember = recruitmentPlanInfo.isAssociationMember as boolean;
        UserDataHistoryMapper.mapForEdit(history, this.model);
        this.onChangeRecruitmentPlan(false); //Para que se cargue el plan si ya hay uno seleccionado
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.uiBlockerSrv.block();
      forkJoin([
        this.userDataSrv.getRecruitmentPlanInfo(this.userId as number)
      ]).subscribe(([recruitmentPlanInfo]) => {
        this.model.userId = this.userId;
        this.model.recruitmentPlans = recruitmentPlanInfo.recruitmentPlans;
        this.isAssociationMember = recruitmentPlanInfo.isAssociationMember as boolean;
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onChangeRecruitmentPlan(loadPlanDates: boolean): void {
    if (this.model.recruitmentPlan) {
      const planSelected = this.model.recruitmentPlans.find(x => x.id === this.model.recruitmentPlan);

      const actualDate = new Date();
      actualDate.setHours(0, 0, 0, 0);

      if (loadPlanDates === true) {
        const planDate = moment(planSelected?.startDate).toDate();
        if (planDate < actualDate) {
          //Empieza el día uno del mes siguiente
          const startDate = new Date();
          startDate.setMonth(startDate.getMonth() + 1);
          startDate.setDate(1);
          this.model.starDate = startDate;
        } else {
          this.model.starDate = planSelected?.startDate;
        }

        this.model.endDate = planSelected?.endDate;
      } else if (this.model.starDate !== undefined && this.model.starDate !== null) {
        const planDate = moment(this.model.starDate).toDate();
        if (planDate <= actualDate) {
          //No se podrá cambiar ni la fecha de inicio ni el plan
          this.model.form.get(this.model.C_START_DATE)?.disable();
          this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.disable();
          this.model.form.get(this.model.C_MONITORING_PERIODICITY)?.disable();
          this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.disable();
        }
      }

      if (!planSelected?.onlyMonitoring && planSelected?.id === RecruitmentPlan.FlexPlan) {
        this.hasFlexPlan = true;
      } else {
        this.hasFlexPlan = false;
      }

      if (!planSelected?.onlyCreditOpinion) {
        this.hasMonitoring = true;
      } else {
        this.hasMonitoring = false;
      }
    } else {
      this.hasFlexPlan = false;
      this.hasMonitoring = false;
    }

    if (!this.hasFlexPlan) {
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.setValue(null);
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.clearValidators();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.updateValueAndValidity();
    }

    if (this.hasMonitoring) {
      this.model.form.get(this.model.C_MONITORING_PERIODICITY)?.setValidators([Validators.required]);
    } else {
      this.model.form.get(this.model.C_MONITORING_PERIODICITY)?.setValue(null);
      this.model.form.get(this.model.C_MONITORING_PERIODICITY)?.clearValidators();
    }
    this.model.form.get(this.model.C_MONITORING_PERIODICITY)?.updateValueAndValidity();
  }

  onAccept(): void {
    const request = UserDataHistoryMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.userDataHistorySrv
      .save(request)
      .pipe(
        tap((response) => {
          const msgResult = response.result as string;
          if (msgResult) {
            const title: string = $localize`: @@app.user-data-history.save.result.warning.title:Error!`;
            this.alertSrv.error(title, msgResult);
          } else {
            const title: string = $localize`: @@app.user-data.save.result.ok.title:Éxito!`;
            const message: string = $localize`: @@app.user-data-history.save.result.ok.message:Plan guardado correctamente`;
            this.alertSrv.success(title, message);
            this.closeDialog(DialogResultTypes.OK);
          }
          this.uiBlockerSrv.unblock();
        })
      )
      .subscribe();
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private get userDataHistoryId(): string | null {
    return this.inputData.id as string | null;
  }
  private get userId(): number | null {
    return this.inputData.userId as number | null;
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
