import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DebtorCreditGrantedSearchComponent } from 'src/app/features/monitoring/debtor-credit-granted/pages/debtor-credit-granted-search/debtor-credit-granted-search.component';

@Injectable({
  providedIn: 'root'
})
export class DebtorCreditGrantedCanDeactivateGuard implements CanDeactivate<DebtorCreditGrantedSearchComponent> {
  canDeactivate(component: DebtorCreditGrantedSearchComponent): boolean {
    //Comprobamos si tiene que enviar el correo notificando que ha habido cambios en los créditos concedidos
    component.checkUpdatedDebtorsToNotify();

    return true;
  }
}
