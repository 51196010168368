<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <div class="flex f-gap--10px margin-bottom-50">
   <a mat-raised-button class="f-basis--4 support-button"
   i18n="@@app.support.emergencies"
   href="mailto:{{emailAddress}}?subject=[Servicio de Información-Urgencias]">
   <mat-icon>bolt</mat-icon><br/>
   Urgencias
  </a>

    <a mat-raised-button class="f-basis--4 support-button"
   i18n="@@app.support.consultations"
   href="mailto:{{emailAddress}}?subject=[Servicio de Información-Consultas]">
   <mat-icon>contact_support</mat-icon><br/>
   Consultas
  </a>

    <a mat-raised-button class="f-basis--4 support-button"
   i18n="@@app.support.errors"
   href="mailto:{{emailAddress}}?subject=[Servicio de Información-Reporte de error]">
   <mat-icon>bug_report</mat-icon><br/>
   Reportar error
  </a>

  </div>
  <div class="flex margin-left-50 margin-top-40">
    <h1 i18n="@@app.support.contact-data.title">Datos de contacto:</h1>
  </div>
  <div class="flex margin-left-50 margin-top-10">
      <span i18n="@@app.support.contact-data.person">Santiago Martínez</span>
  </div>
  <div class="flex margin-left-50 margin-top-10">
    <a href="mailto:{{emailAddress}}"  >
      {{emailAddress}}
    </a>
  </div>
  <div class="flex margin-left-50 margin-top-10">
    <span i18n="@@app.support.contact-data.address">Paseo de Recoletos 37, 4º Planta, Madrid</span>
  </div>
</div>
