<form [formGroup]="model.form">
  <div class="howden-dialog-content">
    <div mat-dialog-title class="margin-bottom-0">
      <div class="title-text" i18n="@@app.credit-opinion.attachment.dialog.title">Adjuntos Opinión Crédito</div>
      <div class="subtitle-text"></div>
    </div>
    <mat-dialog-content>
      <div class="flex f-gap--10px">
       <mat-form-field class="f-basis--12">
        <mat-label i18n="@@app.credit-opinion.attachment.dialog.description">Descripción</mat-label>
        <input
          matInput
          [formControlName]="model.C_DESCRIPCION"
          [howdenControlError]="descriptionError"
          maxlength="256"
        />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>
      </div>
      <div class="flex f-gap--10px margin-bottom-10">
        <div class="f-basis--12" *ngIf="!creditOpinionAttachId">
          <mat-label i18n="@@app.credit-opinion.attachment.dialog.file">
          Archivo
          </mat-label>
          <howden-file-upload
            #file
            (filesSelected)="onFileSelected()"
          >
        </howden-file-upload>
        </div>
        <mat-form-field class="f-basis--12" *ngIf="creditOpinionAttachId">
        <mat-label i18n="@@app.credit-opinion.attachment.dialog.filename">Nombre Archivo</mat-label>
        <input
          matInput
          [formControlName]="model.C_FILENAME"
          maxlength="100"
        />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>
      <button type="button" color="primary" mat-raised-button [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
