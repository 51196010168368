import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IButtonActionData } from '@howdeniberia/core-front';
import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { RecruitmentPlanContract } from 'src/app/core/services/api/recruitment-plan';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { RecruitmentPlanEditDialogComponent } from '../../dialogs/recruitment-plan-edit-dialog/pages/recruitment-plan-edit-dialog.component';
import { RecruitmentPlanMapper } from '../../mappers';
import { IRecruitmentPlanViewModel, RecruitmentPlanSearchViewModel } from '../../models';

@Component({
  selector: 'howden-recruitment-plan-search',
  templateUrl: './recruitment-plan-search.component.html',
  styleUrls: ['./recruitment-plan-search.component.scss']
})
export class RecruitmentPlanSearchComponent implements OnInit, OnDestroy {
  model = new RecruitmentPlanSearchViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private recruitmentPlanSrv: RecruitmentPlanContract,
    private userDataSrv: UserDataContract,
    private uiBlockerSrv: UIBlockerContract
  ) { }

  ngOnInit(): void {
    this.userDataSrv.getAssociations().subscribe((data) => this.model.associations = data);
    this.refresh();
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onNew(): void {
    const inputData = { id: null };
    this.showEditDialog(inputData);
  }

  onRowClicked(event: IRecruitmentPlanViewModel): void {
    const inputData = { id: event.id };
    this.showEditDialog(inputData);
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'download') {
      this.onDownload(event);
    }
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }

  private refresh(): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.recruitmentPlanSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: Array<IRecruitmentPlan>) => {
        this.model.length = searchResult.length;
        this.model.data = searchResult.map((source) =>
          RecruitmentPlanMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }

  private showEditDialog(inputData: any) {
    const dialogRef = this.dialog.open(RecruitmentPlanEditDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  private onDownload(event: IButtonActionData) {
    this.uiBlockerSrv.block();

    const recruimentPlanId = event.row.id;

    this.recruitmentPlanSrv.downloadContractTemplate(recruimentPlanId).subscribe(
      {
        next: (blob: Blob) => {
          FileUtils.downloadFile(
            event.row.contractName,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }
}
