import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HowdenUserService } from '@howdeniberia/core-front';
import { EChartsOption } from 'echarts';
import { IStatistic } from 'src/app/core/models/api/core/statistic';

@Component({
  selector: 'howden-dashboard-bar-chart',
  templateUrl: './dashboard-bar-chart.component.html',
  styleUrls: ['./dashboard-bar-chart.component.scss']
})
export class DashboardBarChartComponent implements OnChanges {
  @Input() data: Array<IStatistic> = [];
  @Input() valueFormat = '1.0-2';

  options: EChartsOption = {};

  constructor(private _decimalPipe: DecimalPipe, private userSrv: HowdenUserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const textColor = this.userSrv.selectedTheme === 'dark' ? '#ffffff' : '#173F35';
    const barColor = this.userSrv.selectedTheme === 'dark' ? '#ffffff' : '#173F35';
    if (
      changes['data'].currentValue
      && changes['data'].currentValue.length > 0
    ) {
      this.options = {
        color: [
          barColor
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params: any) => (
            params[0].name
            + ' - <strong>'
            + this._decimalPipe.transform(params[0].data, this.valueFormat)
            + ' </strong>'
          )
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [
          {
            data: this.data.map((x) => x.description),
            axisTick: { alignWithLabel: true },
            axisLabel: {
              color: textColor,
              fontSize: 10,
              width: 100,
              overflow: 'truncate',
              rotate: 30
            },
            splitLine: { show: false }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: textColor,
              fontSize: 10,
              formatter: (params: any): any => this._decimalPipe.transform(params, this.valueFormat)
            },
            splitLine: { show: false }
          }
        ],
        series: [
          {
            type: 'line',
            smooth: true,
            data: this.data.map((x) => x.value)
          }
        ]
      };
    }
  }
}
