import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HowdenAlertService } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { RecruitmentPlan, SignatureStatus, UserRequestStatus } from 'src/app/core/enums';
import { IRecruitmentPlanFilter } from 'src/app/core/models/api/recruitment-plan';
import { RecruitmentPlanContract } from 'src/app/core/services/api/recruitment-plan';
import { UserRequestContract } from 'src/app/core/services/api/user-request';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UserRequestMapper } from '../../mappers';
import { UserRequestEditViewModel } from '../../models';

@Component({
  selector: 'howden-user-request-edit',
  templateUrl: './user-request-edit.component.html',
  styleUrls: ['./user-request-edit.component.scss']
})
export class UserRequestEditComponent implements OnInit, OnDestroy {
  model = new UserRequestEditViewModel();
  isNew = true;
  isAnonymous = false;
  canUpdate = true;

  private _subscriptions = new SubSink();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private uiBlockerSrv: UIBlockerContract,
    private recruitmentPlanSrv: RecruitmentPlanContract,
    private userRequestSrv: UserRequestContract,
    private alertSrv: HowdenAlertService
  ) {
  }

  ngOnInit(): void {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        this.uiBlockerSrv.block();

        const id = paramMap.get('id') as string;

        if (typeof id !== 'undefined' && id !== null) {
          this.isNew = false;
          this.userRequestSrv.get(id).subscribe(data => {
            UserRequestMapper.mapForEdit(data, this.model);
            this.onEdit();
            this.getRecruitmentPlans(this.model.associationId);
          });
        } else {
          const associationId = paramMap.get('associationId') as string;

          const filter = { onlyCommon: true } as IRecruitmentPlanFilter;
          if (typeof associationId !== 'undefined' && associationId !== null) {
            filter.onlyCommon = null;
            filter.associationId = associationId;
            this.model.associationId = associationId;
          }
          forkJoin([
            this.recruitmentPlanSrv.search(filter)
          ]).pipe(
            tap(([recruitmentPlans]) => {
              this.model.recruitmentPlans = recruitmentPlans;
              this.onNew();
              this.getRecruitmentPlans(associationId);
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave() {
    const request = UserRequestMapper.mapForSave(this.model);

    this.uiBlockerSrv.block();

    this.userRequestSrv.save(request).subscribe((response) => {
      const msgResult = response.result as string;
      if (msgResult) {
        const title: string = $localize`: @@app.user-data.save.result.warning.title:¡Advertencia!`;
        this.alertSrv.error(title, msgResult);
        this.uiBlockerSrv.unblock();
      } else {
        const title: string = $localize`: @@app.user-request.save.result.ok.title:Éxito!`;
        const message: string = $localize`: @@app.user-request.save.result.ok.message:Solicitud de contratación guardada correctamente`;
        this.alertSrv.success(title, message);

        this.uiBlockerSrv.unblock();

        if (!this.isAnonymous) {
          this.goToSearch();
        }
      }
    });
  }

  goToSearch() {
    this.router.navigate(['management/user-request/search']);
  }

  get canSave() {
    if (!this.canUpdate) {
      return false;
    }

    const id = this.model.form.get(this.model.C_ID)?.value;

    if (!id || id === null) {
      return true;
    }

    if (this.model.signatureStatus !== SignatureStatus.SignPending
      && this.model.signatureStatus !== SignatureStatus.SignDone) {
      return false;
    }

    if (this.model.signatureStatus !== SignatureStatus.SignDone && this.model.status === UserRequestStatus.Accepted) {
      //Si no se firma, no se puede aceptar
      return false;
    }

    if (typeof this.model.status === 'undefined' || this.model.status === null) {
      return false;
    }

    return true;
  }

  private getRecruitmentPlans(associationId: string | null | undefined) {
    const filter = { onlyCommon: true } as IRecruitmentPlanFilter;
    if (typeof associationId !== 'undefined' && associationId !== null) {
      filter.onlyCommon = null;
      filter.associationId = associationId;
      this.model.associationId = associationId;
    }
    forkJoin([
      this.recruitmentPlanSrv.search(filter)
    ]).pipe(
      tap(([recruitmentPlans]) => {
        this.model.recruitmentPlans = recruitmentPlans;
        this.uiBlockerSrv.unblock();
      })
    ).subscribe();
  }

  private onNew() {
    this.model.form.get(this.model.C_STATUS)?.disable();
    this.model.form.get(this.model.C_SIGNATURE_STATUS)?.disable();

    this.model.status = UserRequestStatus.Pending;
    this.model.signatureStatus = SignatureStatus.SignPending;

    const path = this.activatedRoute.snapshot.routeConfig?.path as string;
    if (typeof path !== 'undefined' && path !== null) {
      //Si no viene del menú de gestión
      if (path.toLowerCase().indexOf('plan') > -1) {
        if (path.toLowerCase().indexOf('flex') > -1) {
          this.onNewAnonymus(RecruitmentPlan.FlexPlan);
        } else if (path.toLowerCase().indexOf('premium') > -1) {
          this.onNewAnonymus(RecruitmentPlan.PremiumPlan);
        } else {
          this.onNewAnonymus(RecruitmentPlan.BasicPlan);
        }
      } else if (path.toLowerCase().indexOf('asocmember') > -1) {
        this.isAnonymous = true;
      }
    }
  }

  private onNewAnonymus(recruitmentPlan: RecruitmentPlan) {
    this.isAnonymous = true;
    this.model.recruitmentPlanId = recruitmentPlan;
    this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.disable();
  }

  private onEdit() {
    this.model.form.get(this.model.C_NAME)?.disable();
    this.model.form.get(this.model.C_SURNAME)?.disable();
    this.model.form.get(this.model.C_SIGNATORY_FISCAL_CODE_TYPE)?.disable();
    this.model.form.get(this.model.C_SIGNATORY_FISCAL_CODE)?.disable();
    this.model.form.get(this.model.C_COMPANY_NAME)?.disable();
    this.model.form.get(this.model.C_COMPANY_ADDRESS)?.disable();
    this.model.form.get(this.model.C_COMPANY_FISCAL_CODE_TYPE)?.disable();
    this.model.form.get(this.model.C_COMPANY_FISCAL_CODE)?.disable();
    this.model.form.get(this.model.C_COMPANY_ACTIVITY)?.disable();
    this.model.form.get(this.model.C_RECRUITMENT_PLAN)?.disable();
    this.model.form.get(this.model.C_EMAIL)?.disable();
    this.model.form.get(this.model.C_ASSOCIATION_ID)?.disable();
    this.model.form.get(this.model.C_ASSOCIATION_DESC)?.disable();
    this.model.form.get(this.model.C_RECRUITMENT_MANAGER_USER_NAME)?.disable();
    this.model.form.get(this.model.C_SIGNATURE_STATUS)?.disable();

    if (this.model.status !== UserRequestStatus.Pending) {
      this.model.form.get(this.model.C_STATUS)?.disable();
      this.canUpdate = false;
    }
  }
}
