import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, FileUploadComponent, HowdenAlertService, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { RecruitmentPlanContract } from 'src/app/core/services/api/recruitment-plan';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { FileUtils, FormGroupUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { RecruitmentPlanEditMapper } from '../mappers';
import { RecruitmentPlanEditViewModel } from '../models';

@Component({
  selector: 'howden-recruitment-plan-edit-dialog',
  templateUrl: './recruitment-plan-edit-dialog.component.html',
  styleUrls: ['./recruitment-plan-edit-dialog.component.scss']
})
export class RecruitmentPlanEditDialogComponent implements OnInit, OnDestroy {
  model = new RecruitmentPlanEditViewModel();
  isNew = true;
  classContractFile = 'required';

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  @ViewChild('contractTemplate') contractTemplateRef!: FileUploadComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<RecruitmentPlanEditViewModel>,
    private uiBlockerSrv: UIBlockerContract,
    private alertSrv: HowdenAlertService,
    private userDataSrv: UserDataContract,
    private recruitmentPlanSrv: RecruitmentPlanContract
  ) { }

  ngOnInit(): void {
    this.uiBlockerSrv.block();
    if (this.recruitmentPlanId !== 'undefined' && this.recruitmentPlanId !== null) {
      this.isNew = false;
      this.classContractFile = '';
      forkJoin([
        this.recruitmentPlanSrv.get(this.recruitmentPlanId),
        this.userDataSrv.getAssociations()
      ]).subscribe(([recruitmentPlan, associations]) => {
        RecruitmentPlanEditMapper.mapForEdit(recruitmentPlan, this.model);
        this.model.associations = associations;
        this.checkDiferentOptions();
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.userDataSrv.getAssociations().subscribe((data) => {
        this.model.associations = data;
        this.model.form.get(this.model.C_STATUS)?.disable();
        this.model.form.get(this.model.C_CONTRACT_FILE)?.setValidators([Validators.required]);
        this.model.form.get(this.model.C_CONTRACT_FILE)?.updateValueAndValidity();
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onContractTemplateSelected(): void {
    if (this.contractTemplateRef.file?.type === 'application/pdf') {
      this.model.form.patchValue({
        [this.model.C_CONTRACT_FILE]: this.contractTemplateRef.file
      });
    } else {
      const title: string = $localize`: @@app.recruiment-plan.edit.contract-template-file.title:Plantilla de Contrato`;
      const message: string = $localize`: @@app.recruiment-plan.edit.contract-template-file.message:Debe seleccionar un archivo pdf`;
      this.alertSrv.error(title, message);
      this.model.form.get(this.model.C_CONTRACT_FILE)?.setValue(null);
      this.contractTemplateRef?.clearFiles();
    }
  }

  onAccept(): void {
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.recruitmentPlanSrv
      .save(request)
      .pipe(
        tap((response) => {
          const msgResult = response.result as string;
          if (msgResult) {
            const title: string = $localize`: @@app.recruitment-plan.save.result.warning.title:¡Advertencia!`;
            this.alertSrv.warning(title, msgResult);
          } else {
            const title: string = $localize`: @@app.recruitment-plan.save.result.ok.title:Éxito!`;
            const message: string = $localize`: @@app.recruitment-plan.save.result.ok.message:Plan de contratación guardado correctamente`;
            this.alertSrv.success(title, message);
            this.closeDialog(DialogResultTypes.OK);
          }
          this.uiBlockerSrv.unblock();
        })
      )
      .subscribe();
  }

  downloadContractTemplate() {
    this.uiBlockerSrv.block();
    this.recruitmentPlanSrv.downloadContractTemplate(this.recruitmentPlanId as string).subscribe(
      {
        next: (blob: Blob) => {
          FileUtils.downloadFile(
            this.model.contractName as string,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  checkDiferentOptions() {
    if (this.model.onlyCreditOpinion !== null && this.model.onlyCreditOpinion) {
      this.model.form.get(this.model.C_ONLY_MONITORING)?.disable();
      this.model.form.get(this.model.C_ONLY_CREDIT_OPINION)?.enable();
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.enable();
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.setValidators([Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxInt16)]);
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.updateValueAndValidity();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.enable();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.setValidators([Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]);
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.updateValueAndValidity();
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.setValue(null);
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.disable();
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.clearValidators();
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.updateValueAndValidity();
    } else if (this.model.onlyMonitoring !== null && this.model.onlyMonitoring) {
      this.model.form.get(this.model.C_ONLY_CREDIT_OPINION)?.disable();
      this.model.form.get(this.model.C_ONLY_MONITORING)?.enable();
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.setValidators([Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxInt16)]);
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.updateValueAndValidity();
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.setValue(null);
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.disable();
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.clearValidators();
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.updateValueAndValidity();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.setValue(null);
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.disable();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.clearValidators();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.updateValueAndValidity();
    } else {
      this.model.form.get(this.model.C_ONLY_CREDIT_OPINION)?.enable();
      this.model.form.get(this.model.C_ONLY_MONITORING)?.enable();
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.enable();
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.setValidators([Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxInt16)]);
      this.model.form.get(this.model.C_CREDIT_OPINION_LIMIT)?.updateValueAndValidity();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.enable();
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.setValidators([Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]);
      this.model.form.get(this.model.C_CREDIT_OPINION_PRICE)?.updateValueAndValidity();
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.enable();
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.setValidators([Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxInt16)]);
      this.model.form.get(this.model.C_MONITORING_LIMIT)?.updateValueAndValidity();
    }
  }

  private get recruitmentPlanId(): string | null {
    return this.inputData.id as string | null;
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
