import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  GENERAL_CONSTANTS,
  HowdenMainLayoutComponent,
  HowdenWithoutMenuLayoutComponent,
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  NotAuthorizedComponent
} from '@howdeniberia/core-front';
import { CreditOpinionModule } from './features/credit-opinion/credit-opinion.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { HomeComponent } from './features/home/home.component';
import { ManagementModule } from './features/management/management.module';
import { UserRequestEditComponent } from './features/management/user-request/pages/user-request-edit/user-request-edit.component';
import { MonitoringModule } from './features/monitoring/monitoring.module';
import { SupportModule } from './features/support/support.module';

export const AppRoutes = [
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    path: '',
    component: HowdenMainLayoutComponent, //Cambiar el layout aquí si es necesario
    data: { isLayout: true },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent
      },
      {
        path: '',
        loadChildren: () => CreditOpinionModule
      },
      {
        path: '',
        loadChildren: () => DashboardModule
      },
      {
        path: '',
        loadChildren: () => MonitoringModule
      },
      {
        path: '',
        loadChildren: () => ManagementModule
      },
      {
        path: '',
        loadChildren: () => SupportModule
      }
    ]
  },
  {
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    children: [
      {
        path: 'user-request/new/basicPlan',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.basicPlan.title:Contratación Servicios Información Plan Básico`
      }
    ]
  },
  {
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    children: [
      {
        path: 'user-request/new/premiumPlan',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.premiumPlan.title:Contratación Servicios Información Plan Premium`
      }
    ]
  },
  {
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    children: [
      {
        path: 'user-request/new/flexPlan',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.flexPlan.title:Contratación Servicios Información Plan Flex`
      }
    ]
  },
  {
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    children: [
      {
        path: 'user-request/asocmember/new/:associationId',
        component: UserRequestEditComponent,
        title: $localize`:@@app.route.user-request.new.association-member.title:Contratación Servicios Información Asociación`
      }
    ]
  },
  {
    path: GENERAL_CONSTANTS.NotAuthorizedUrl,
    title: $localize`:@@app.route.notauthorized: No autorizado`,
    component: NotAuthorizedComponent
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: '**', redirectTo: '404' }
] as Routes;

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
