import { NgModule } from '@angular/core';
import { CommentContract, CommentService } from 'src/app/core/services/api/comment';
import { CountryContract, CountryService } from 'src/app/core/services/api/country';
import {
  CreditOpinionContract,
  CreditOpinionService
} from 'src/app/core/services/api/credit-opinion';
import { CreditOpinionAttachmentContract, CreditOpinionAttachmentService } from 'src/app/core/services/api/credit-opinion-attachment';
import {
  CreditOpinionExerciseContract,
  CreditOpinionExerciseService
} from 'src/app/core/services/api/credit-opinion-exercise';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreditOpinionRoutingModule } from './credit-opinion-routing.module';
import { CreditOpinionAttachmentDialogComponent } from './dialogs/credit-opinion-attach-dialog/pages/credit-opinion-attachment-dialog/credit-opinion-attachment-dialog.component';
import { CreditOpinionEditDialogComponent } from './dialogs/credit-opinion-edit-dialog/pages/credit-opinion-edit-dialog.component';
import { CreditOpinionExerciseDialogComponent } from './dialogs/credit-opinion-exercise-dialog/pages/credit-opinion-exercise-dialog.component';
import { CreditOpinionOperationDialogComponent } from './dialogs/credit-opinion-operation-dialog/pages/credit-opinion-operation-dialog.component';
import { CreditOpinionAttachmentsComponent } from './pages/credit-opinion-attachments/credit-opinion-attachments.component';
import { CreditOpinionEditComponent } from './pages/credit-opinion-edit/credit-opinion-edit.component';
import { CreditOpinionExercisesComponent } from './pages/credit-opinion-exercises/credit-opinion-exercises.component';
import { CreditOpinionSearchComponent } from './pages/credit-opinion-search/credit-opinion-search.component';

@NgModule({
  imports: [
    CreditOpinionRoutingModule,
    SharedModule
  ],
  declarations: [
    CreditOpinionSearchComponent,
    CreditOpinionEditComponent,
    CreditOpinionEditDialogComponent,
    CreditOpinionExercisesComponent,
    CreditOpinionExerciseDialogComponent,
    CreditOpinionAttachmentDialogComponent,
    CreditOpinionAttachmentsComponent,
    CreditOpinionOperationDialogComponent
  ],
  providers: [
    { provide: CommentContract, useClass: CommentService },
    { provide: CreditOpinionContract, useClass: CreditOpinionService },
    { provide: CreditOpinionExerciseContract, useClass: CreditOpinionExerciseService },
    { provide: CreditOpinionAttachmentContract, useClass: CreditOpinionAttachmentService },
    { provide: CountryContract, useClass: CountryService }
  ]
})
export class CreditOpinionModule { }
