import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig,
  ITableEventData
} from '@howdeniberia/core-front';

import { IDebtorCounter, IDebtorFilter } from 'src/app/core/models/api/monitoring';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { IDebtorViewModel } from './debtor.viewmodel';

export class DebtorSearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IDebtorFilter;
  private _debtorCounter: IDebtorCounter;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_COUNTER_INFO = 'counterInfo';
  readonly C_COUNTER_MSG = 'counterMsg';
  readonly C_USER_ID = 'userId';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_OWN_IDENTIFIER = 'ownIdentifier';
  readonly C_NOT_MONITORABLE = 'notMonitorable';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_COUNTER_INFO]: new FormControl('', []),
      [this.C_COUNTER_MSG]: new FormControl('', []),
      [this.C_USER_ID]: new FormControl(null, [Validators.required]),
      [this.C_FISCAL_CODE]: new FormControl('', []),
      [this.C_BUSINESS_NAME]: new FormControl('', []),
      [this.C_OWN_IDENTIFIER]: new FormControl('', []),
      [this.C_NOT_MONITORABLE]: new FormControl(false, [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IDebtorFilter;

    this._debtorCounter = {
      maxNumber: 0,
      actualNumber: 0,
      canAddDebtor: false,
      message: ''
    } as IDebtorCounter;
  }

  dataClients: IUserDataReduced[] = [];
  columnsClients: IHowdenColumnConfig[] = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.debtor.search.client.fiscalCode.column:ID Fiscal`,
      canSort: true,
      width: '25%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.debtor.search.client.businessName.column:Razón social`,
      canSort: true,
      width: '60%'
    }
  ];

  data: IDebtorViewModel[] = [];
  columns: IHowdenColumnConfig[] = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.debtor.search.fiscalCode.column:ID Fiscal`,
      canSort: true,
      width: '20%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.debtor.search.businessName.column:Razón social`,
      canSort: true,
      width: '25%'
    },
    {
      fieldName: 'ownIdentifier',
      fieldHeader: $localize`:@@app.debtor.search.ownIdentifier.column:Identificador interno`,
      canSort: true,
      width: '22%'
    },
    {
      fieldName: 'requestedAmount',
      fieldHeader: $localize`:@@app.debtor.search.requestedAmount.column:Solicitado`,
      canSort: true,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.CURRENCY,
      width: '15%'
    },
    {
      fieldName: 'monitorable',
      fieldHeader: $localize`:@@app.debtor.search.monitorable.column:Activo`,
      pipeToApply: ColumnPipeTypes.CHECK,
      aligment: ColumnAligment.CENTER
    }
  ];

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._filterForm.get(controlName)!;
  }

  get length(): number {
    return this._length;
  }
  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get searchRequest(): IDebtorFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }
  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get debtorCounter(): IDebtorCounter {
    return this._debtorCounter;
  }
  set debtorCounter(value: IDebtorCounter) {
    this._debtorCounter = value;
  }

  get counterInfo(): string | null | undefined {
    return this.getControl(this.C_COUNTER_INFO)?.value;
  }
  set counterInfo(value: string | null | undefined) {
    this.getControl(this.C_COUNTER_INFO)?.setValue(value);
  }

  get counterMsg(): string | null | undefined {
    return this.getControl(this.C_COUNTER_MSG)?.value;
  }
  set counterMsg(value: string | null | undefined) {
    this.getControl(this.C_COUNTER_MSG)?.setValue(value);
  }

  get userId(): number | null | undefined {
    return this.getControl(this.C_USER_ID)?.value;
  }
  set userId(value: number | null | undefined) {
    this.getControl(this.C_USER_ID)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this.getControl(this.C_FISCAL_CODE)?.value;
  }
  set fiscalCode(value: string | null | undefined) {
    this.getControl(this.C_FISCAL_CODE)?.setValue(value);
  }

  get businessName(): string | null | undefined {
    return this.getControl(this.C_BUSINESS_NAME)?.value;
  }
  set businessName(value: string | null | undefined) {
    this.getControl(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get ownIdentifier(): string | null | undefined {
    return this.getControl(this.C_OWN_IDENTIFIER)?.value;
  }
  set ownIdentifier(value: string | null | undefined) {
    this.getControl(this.C_OWN_IDENTIFIER)?.setValue(value);
  }

  get notMonitorable(): boolean | null | undefined {
    return this.getControl(this.C_NOT_MONITORABLE)?.value;
  }
  set notMonitorable(value: boolean | null | undefined) {
    this.getControl(this.C_NOT_MONITORABLE)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.userId = this.userId;
    this.searchRequest.fiscalCode = this.fiscalCode;
    this.searchRequest.businessName = this.businessName;
    this.searchRequest.ownIdentifier = this.ownIdentifier;
    this.searchRequest.notMonitorable = this.notMonitorable;
  }
}
