import { KeyValue } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  StringValidators
} from '@howdeniberia/core-front';
import { CREDIT_OPINION_SITUATION_VALUES, FISCAL_CODE_TYPE_VALUES, GRADE_VALUES } from 'src/app/core/constants';
import { CreditOpinionSituation, FiscalCodeType, Grade } from 'src/app/core/enums';
import { ICountry } from 'src/app/core/models/api/country';
import { IComment } from 'src/app/core/models/api/master/comment';
import { Limits } from 'src/app/shared/utils';
import { creditGrantedLimitMatchValidator, creditGrantedRequestedMatchValidator, fiscalCodeValidator } from 'src/app/shared/validators';

export class CreditOpinionEditViewModel {
  private _form: FormGroup;

  private _countries: Array<ICountry> = [];
  private _fiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[] = FISCAL_CODE_TYPE_VALUES;
  private _statusValues: KeyValue<CreditOpinionSituation, string>[] = CREDIT_OPINION_SITUATION_VALUES;
  private _gradeValues: KeyValue<Grade, string>[] = GRADE_VALUES;

  private _annualAccountsValues: Array<IComment> = [];
  private _evaluationActivityValues: Array<IComment> = [];
  private _longTermDebtEvaluationValues: Array<IComment> = [];
  private _shortTermDebtEvaluationValues: Array<IComment> = [];

  readonly C_CREDIT_OPINION_ID = 'id';

  readonly C_USER_REQUEST_ID = 'userRequestId';
  readonly C_USER_REQUEST_BUSSINESS_NAME = 'userRequestBussinessName';
  readonly C_USER_REQUEST_EMAIL = 'userRequestEmail';
  readonly C_USER_REQUEST_CONTACT = 'userRequestContact';
  readonly C_USER_REQUEST_ASSOCIATION = 'userRequestAssociation';
  readonly C_REQUEST_DATE = 'requestDate';
  readonly C_RESPONSE_DATE = 'responseDate';
  readonly C_USER_RESPONSE_NAME_SURNAME = 'userResponseNameSurname';
  readonly C_USER_RESPONSE_EMAIL = 'userResponseEmail';
  readonly C_COMPANY_ASSESS_FISCAL_CODE_TYPE = 'companyAssessFiscalCodeType';
  readonly C_COMPANY_ASSESS_FISCAL_CODE = 'companyAssessFiscalCode';
  readonly C_COMPANY_ASSESS_COUNTRY_ID = 'companyAssessCountryId';
  readonly C_COMPANY_ASSESS_NAME = 'companyAssessName';
  readonly C_REQUESTED_AMOUNT = 'requestedAmount';
  readonly C_LAST_GRANTED_AMOUNT = 'lastGrantedAmount';
  readonly C_GRANTED_AMOUNT = 'grantedAmount';
  readonly C_COMPANY_ASSESS_CONST_YEAR = 'companyAssessConstYear';
  readonly C_COMPANY_ASSESS_ADDRESS = 'companyAssessAddress';
  readonly C_CREDIT_LIMIT = 'creditLimit';

  readonly C_RAI = 'rai';
  readonly C_UNPAID_BILLS = 'unpaid';
  readonly C_JUDICIAL_INCIDENTS = 'judicialIncidents';
  readonly C_CONCURSAL_PROCEDURES = 'insolvencyProceduresOthers';
  readonly C_ADMINISTRATIVE_CLAIMS = 'administrativeClaims';

  readonly C_OBSERVATIONS = 'observations';

  readonly C_EVALUATION_ACTIVITY = 'evaluationActivity';
  readonly C_ANNUAL_ACCOUNTS = 'annualAccounts';
  readonly C_LONG_TERM_DEBT_EVALUATION = 'longTermDebtEvaluation';
  readonly C_SHORT_TERM_DEBT_EVALUATION = 'shortTermDebtEvaluation';

  readonly C_SITUATION = 'situation';
  readonly C_GRADE = 'grade';

  constructor() {
    this._form = new FormGroup({
      [this.C_CREDIT_OPINION_ID]: new FormControl(null, []),
      [this.C_USER_REQUEST_ID]: new FormControl(null, []),
      [this.C_USER_REQUEST_BUSSINESS_NAME]: new FormControl('', []),
      [this.C_USER_REQUEST_EMAIL]: new FormControl('', []),
      [this.C_USER_RESPONSE_NAME_SURNAME]: new FormControl('', []),
      [this.C_USER_RESPONSE_EMAIL]: new FormControl('', []),
      [this.C_USER_REQUEST_CONTACT]: new FormControl('', []),
      [this.C_USER_REQUEST_ASSOCIATION]: new FormControl(null, []),
      [this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE]: new FormControl(null, [Validators.required]),
      [this.C_COMPANY_ASSESS_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_ASSESS_COUNTRY_ID]: new FormControl(null, []),
      [this.C_COMPANY_ASSESS_NAME]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_COMPANY_ASSESS_CONST_YEAR]: new FormControl(null, [Validators.required, Validators.min(1900), Validators.max(9999)]),
      [this.C_COMPANY_ASSESS_ADDRESS]: new FormControl('', [Validators.required]),
      [this.C_REQUEST_DATE]: new FormControl(null, []),
      [this.C_RESPONSE_DATE]: new FormControl(null, []),
      [this.C_REQUESTED_AMOUNT]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]),
      [this.C_LAST_GRANTED_AMOUNT]: new FormControl(null, []),
      [this.C_GRANTED_AMOUNT]: new FormControl(null, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxDecimal)]),
      [this.C_CREDIT_LIMIT]: new FormControl(null, [Validators.required, Validators.min(Limits.zero), Validators.max(Limits.maxDecimal)]),
      [this.C_OBSERVATIONS]: new FormControl('', []),
      [this.C_RAI]: new FormControl(null, []),
      [this.C_UNPAID_BILLS]: new FormControl(null, []),
      [this.C_JUDICIAL_INCIDENTS]: new FormControl(null, []),
      [this.C_CONCURSAL_PROCEDURES]: new FormControl(null, []),
      [this.C_ADMINISTRATIVE_CLAIMS]: new FormControl(null, []),
      [this.C_EVALUATION_ACTIVITY]: new FormControl(null, [Validators.required]),
      [this.C_ANNUAL_ACCOUNTS]: new FormControl(null, [Validators.required]),
      [this.C_LONG_TERM_DEBT_EVALUATION]: new FormControl(null, []),
      [this.C_SHORT_TERM_DEBT_EVALUATION]: new FormControl(null, []),
      [this.C_SITUATION]: new FormControl(null, []),
      [this.C_GRADE]: new FormControl(null, [Validators.required])
    }, {
      validators: [
        fiscalCodeValidator(this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE, this.C_COMPANY_ASSESS_FISCAL_CODE),
        creditGrantedRequestedMatchValidator(this.C_GRANTED_AMOUNT, this.C_REQUESTED_AMOUNT),
        creditGrantedLimitMatchValidator(this.C_GRANTED_AMOUNT, this.C_CREDIT_LIMIT)
      ]
    });
  }

  get countries(): Array<ICountry> {
    return this._countries;
  }
  set countries(value: Array<ICountry>) {
    this._countries = value;
  }

  get fiscalCodeTypeValues(): KeyValue<FiscalCodeType, string>[] {
    return this._fiscalCodeTypeValues;
  }
  set fiscalCodeTypeValues(
    fiscalCodeTypeValues: KeyValue<FiscalCodeType, string>[]
  ) {
    this._fiscalCodeTypeValues = fiscalCodeTypeValues;
  }

  get statusValues(): KeyValue<CreditOpinionSituation, string>[] {
    return this._statusValues;
  }
  set statusValues(
    creditOpinionStatusValues: KeyValue<CreditOpinionSituation, string>[]
  ) {
    this._statusValues = creditOpinionStatusValues;
  }

  get gradeValues(): KeyValue<Grade, string>[] {
    return this._gradeValues;
  }
  set gradeValues(gradeValues: KeyValue<Grade, string>[]
  ) {
    this._gradeValues = gradeValues;
  }

  get evaluationActivityValues(): Array<IComment> {
    return this._evaluationActivityValues;
  }
  set evaluationActivityValues(evaluationActivity: Array<IComment>) {
    this._evaluationActivityValues = evaluationActivity;
  }

  get annualAccountsValues(): Array<IComment> {
    return this._annualAccountsValues;
  }
  set annualAccountsValues(annualAccounts: Array<IComment>) {
    this._annualAccountsValues = annualAccounts;
  }

  get longTermDebtEvaluationValues(): Array<IComment> {
    return this._longTermDebtEvaluationValues;
  }
  set longTermDebtEvaluationValues(longTermDebtEvaluation: Array<IComment>) {
    this._longTermDebtEvaluationValues = longTermDebtEvaluation;
  }

  get shortTermDebtEvaluationValues(): Array<IComment> {
    return this._shortTermDebtEvaluationValues;
  }
  set shortTermDebtEvaluationValues(shortTermDebtEvaluation: Array<IComment>) {
    this._shortTermDebtEvaluationValues = shortTermDebtEvaluation;
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_ID)?.setValue(value);
  }

  get userRequestId(): number | null | undefined {
    return this._form.get(this.C_USER_REQUEST_ID)?.value;
  }
  set userRequestId(value: number | null | undefined) {
    this._form.get(this.C_USER_REQUEST_ID)?.setValue(value);
  }

  get userRequestBussinessName(): string | null | undefined {
    return this._form.get(this.C_USER_REQUEST_BUSSINESS_NAME)?.value;
  }
  set userRequestBussinessName(value: string | null | undefined) {
    this._form.get(this.C_USER_REQUEST_BUSSINESS_NAME)?.setValue(value);
  }

  get userRequestEmail(): string | null | undefined {
    return this._form.get(this.C_USER_REQUEST_EMAIL)?.value;
  }
  set userRequestEmail(value: string | null | undefined) {
    this._form.get(this.C_USER_REQUEST_EMAIL)?.setValue(value);
  }

  get userRequestContact(): string | null | undefined {
    return this._form.get(this.C_USER_REQUEST_CONTACT)?.value;
  }
  set userRequestContact(value: string | null | undefined) {
    this._form.get(this.C_USER_REQUEST_CONTACT)?.setValue(value);
  }

  get userRequestAssociation(): string | null | undefined {
    return this._form.get(this.C_USER_REQUEST_ASSOCIATION)?.value;
  }
  set userRequestAssociation(value: string | null | undefined) {
    this._form.get(this.C_USER_REQUEST_ASSOCIATION)?.setValue(value);
  }

  get companyAssessFiscalCodeType(): FiscalCodeType | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE)?.value;
  }
  set companyAssessFiscalCodeType(value: FiscalCodeType | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE_TYPE)?.setValue(value);
  }

  get companyAssessFiscalCode(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE)?.value;
  }
  set companyAssessFiscalCode(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_FISCAL_CODE)?.setValue(value);
  }

  get companyAssessCountryId(): number | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_COUNTRY_ID)?.value;
  }
  set companyAssessCountryId(value: number | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_COUNTRY_ID)?.setValue(value);
  }

  get companyAssessName(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_NAME)?.value;
  }
  set companyAssessName(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_NAME)?.setValue(value);
  }

  get requestDate(): Date | null | undefined {
    return this._form.get(this.C_REQUEST_DATE)?.value;
  }
  set requestDate(value: Date | null | undefined) {
    this._form.get(this.C_REQUEST_DATE)?.setValue(value);
  }

  get userResponseNameSurname(): string | null | undefined {
    return this._form.get(this.C_USER_RESPONSE_NAME_SURNAME)?.value;
  }
  set userResponseNameSurname(value: string | null | undefined) {
    this._form.get(this.C_USER_RESPONSE_NAME_SURNAME)?.setValue(value);
  }

  get userResponseEmail(): string | null | undefined {
    return this._form.get(this.C_USER_RESPONSE_EMAIL)?.value;
  }
  set userResponseEmail(value: string | null | undefined) {
    this._form.get(this.C_USER_RESPONSE_EMAIL)?.setValue(value);
  }

  get responseDate(): Date | null | undefined {
    return this._form.get(this.C_RESPONSE_DATE)?.value;
  }
  set responseDate(value: Date | null | undefined) {
    this._form.get(this.C_RESPONSE_DATE)?.setValue(value);
  }

  get requestedAmount(): number | null | undefined {
    return this._form.get(this.C_REQUESTED_AMOUNT)?.value;
  }
  set requestedAmount(value: number | null | undefined) {
    this._form.get(this.C_REQUESTED_AMOUNT)?.setValue(value);
  }

  get lastGrantedAmount(): string | null | undefined {
    return this._form.get(this.C_LAST_GRANTED_AMOUNT)?.value;
  }
  set lastGrantedAmount(value: string | null | undefined) {
    this._form.get(this.C_LAST_GRANTED_AMOUNT)?.setValue(value);
  }

  get grantedAmount(): number | null | undefined {
    return this._form.get(this.C_GRANTED_AMOUNT)?.value;
  }
  set grantedAmount(value: number | null | undefined) {
    this._form.get(this.C_GRANTED_AMOUNT)?.setValue(value);
  }

  get companyAssessConstYear(): number | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_CONST_YEAR)?.value;
  }
  set companyAssessConstYear(value: number | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_CONST_YEAR)?.setValue(value);
  }

  get companyAssessAddress(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ASSESS_ADDRESS)?.value;
  }
  set companyAssessAddress(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ASSESS_ADDRESS)?.setValue(value);
  }

  get creditLimit(): number | null | undefined {
    return this._form.get(this.C_CREDIT_LIMIT)?.value;
  }
  set creditLimit(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_LIMIT)?.setValue(value);
  }

  get rai(): boolean | null | undefined {
    return this._form.get(this.C_RAI)?.value;
  }
  set rai(value: boolean | null | undefined) {
    this._form.get(this.C_RAI)?.setValue(value);
  }

  get unpaid(): boolean | null | undefined {
    return this._form.get(this.C_UNPAID_BILLS)?.value;
  }
  set unpaid(value: boolean | null | undefined) {
    this._form.get(this.C_UNPAID_BILLS)?.setValue(value);
  }

  get judicialIncidents(): boolean | null | undefined {
    return this._form.get(this.C_JUDICIAL_INCIDENTS)?.value;
  }
  set judicialIncidents(value: boolean | null | undefined) {
    this._form.get(this.C_JUDICIAL_INCIDENTS)?.setValue(value);
  }

  get insolvencyProceduresOthers(): boolean | null | undefined {
    return this._form.get(this.C_CONCURSAL_PROCEDURES)?.value;
  }
  set insolvencyProceduresOthers(value: boolean | null | undefined) {
    this._form.get(this.C_CONCURSAL_PROCEDURES)?.setValue(value);
  }

  get administrativeClaims(): boolean | null | undefined {
    return this._form.get(this.C_ADMINISTRATIVE_CLAIMS)?.value;
  }
  set administrativeClaims(value: boolean | null | undefined) {
    this._form.get(this.C_ADMINISTRATIVE_CLAIMS)?.setValue(value);
  }

  get observations(): string | null | undefined {
    return this._form.get(this.C_OBSERVATIONS)?.value;
  }
  set observations(value: string | null | undefined) {
    this._form.get(this.C_OBSERVATIONS)?.setValue(value);
  }

  get evaluationActivity(): number | null | undefined {
    return this._form.get(this.C_EVALUATION_ACTIVITY)?.value;
  }
  set evaluationActivity(value: number | null | undefined) {
    this._form.get(this.C_EVALUATION_ACTIVITY)?.setValue(value);
  }

  get annualAccounts(): number | null | undefined {
    return this._form.get(this.C_ANNUAL_ACCOUNTS)?.value;
  }
  set annualAccounts(value: number | null | undefined) {
    this._form.get(this.C_ANNUAL_ACCOUNTS)?.setValue(value);
  }

  get longTermDebtEvaluation(): number | null | undefined {
    return this._form.get(this.C_LONG_TERM_DEBT_EVALUATION)?.value;
  }
  set longTermDebtEvaluation(value: number | null | undefined) {
    this._form.get(this.C_LONG_TERM_DEBT_EVALUATION)?.setValue(value);
  }

  get shortTermDebtEvaluation(): number | null | undefined {
    return this._form.get(this.C_SHORT_TERM_DEBT_EVALUATION)?.value;
  }
  set shortTermDebtEvaluation(value: number | null | undefined) {
    this._form.get(this.C_SHORT_TERM_DEBT_EVALUATION)?.setValue(value);
  }

  get situation(): CreditOpinionSituation | null | undefined {
    return this._form.get(this.C_SITUATION)?.value;
  }
  set situation(value: CreditOpinionSituation | null | undefined) {
    this._form.get(this.C_SITUATION)?.setValue(value);
  }

  get grade(): Grade | null | undefined {
    return this._form.get(this.C_GRADE)?.value;
  }
  set grade(value: Grade | null | undefined) {
    this._form.get(this.C_GRADE)?.setValue(value);
  }
}
