import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HowdenTableComponent } from '@howdeniberia/core-front';
import { DashboardPeriod } from 'src/app/core/enums';
import { IStatistic } from 'src/app/core/models/api/core/statistic';
import { IDashboardCompanyAssess } from 'src/app/core/models/api/dashboard';
import { DashboardContract } from 'src/app/core/services/api/dashboard';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreditOpinionHistoryViewModel } from '../../models';

@Component({
  selector: 'howden-credit-opinion-history',
  templateUrl: './credit-opinion-history.component.html',
  styleUrls: ['./credit-opinion-history.component.scss']
})
export class CreditOpinionHistoryComponent implements OnInit, OnDestroy {
  model = new CreditOpinionHistoryViewModel();

  infoLoaded = false;
  companySelected = '';
  requestAmountByDateToDashboard: Array<IStatistic> = [];
  grantedAmountByDateToDashboard: Array<IStatistic> = [];

  @ViewChild('companiesTable') companiesTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    private dashboardDataSrv: DashboardContract,
    private uiBlockerSrv: UIBlockerContract
  ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onPeriodChange(period: DashboardPeriod): void {
    this.model.periodSelected = period;
    this.refresh();
  }

  onRowClicked(event: IDashboardCompanyAssess): void {
    const fiscalCode = event.fiscalCode as string;

    this.companySelected = event.businessName;

    const requestedAmounts: IStatistic[] = [];
    const grantedAmounts: IStatistic[] = [];

    //Recuperamos las opiniones de crédito de esta empresa
    const data = this.model.dashboardData.filter(x => x.fiscalCode === fiscalCode)[0];
    data.creditOpinions.forEach(x => {
      requestedAmounts.push(
        {
          id: x.responseDateDesc,
          label: x.responseDateDesc,
          description: x.responseDateDesc,
          value: x.requestedAmount
        }
      );

      grantedAmounts.push(
        {
          id: x.responseDateDesc,
          label: x.responseDateDesc,
          description: x.responseDateDesc,
          value: x.grantedAmount
        }
      );
    });

    this.requestAmountByDateToDashboard = requestedAmounts;
    this.grantedAmountByDateToDashboard = grantedAmounts;
  }

  private refresh(): void {
    this.infoLoaded = false;

    if (this.model.periodSelected !== null && this.model.periodSelected !== undefined) {
      this.uiBlockerSrv.block();

      this.dashboardDataSrv.getCreditOpinionHistory(this.model.periodSelected).subscribe({
        next: (result: Array<IDashboardCompanyAssess>) => {
          this.model.dashboardData = result;
          if (result.length > 0) {
            this.infoLoaded = true;
            const firstRow = this.model.dashboardData[0];
            //Seleccionamos la primera fila
            this.companiesTable.setHighlightRow(firstRow, undefined);
            //Cargamos gráfico de primera fila
            this.onRowClicked(firstRow);
          }
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      });
    }
  }
}
