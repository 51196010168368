import { KeyValue } from '@angular/common';

import { FiscalCodeType } from '../enums';

export const FISCAL_CODE_TYPE_VALUES: KeyValue<FiscalCodeType, string>[] = [
  {
    key: FiscalCodeType.Cif,
    value: $localize`:@@app.route.credit-opinion.fiscal-code-type.enums.cif:CIF`
  },
  {
    key: FiscalCodeType.Free,
    //Para los servicios de información se solicitó que el libre se identificase como extranjero
    value: $localize`:@@app.route.credit-opinion.fiscal-code-type.enums.foreign:Extranjero`
  },
  {
    key: FiscalCodeType.Nie,
    value: $localize`:@@app.route.credit-opinion.fiscal-code-type.enums.nie:NIE`
  },
  {
    key: FiscalCodeType.Nif,
    value: $localize`:@@app.route.credit-opinion.fiscal-code-type.enums.nif:NIF`
  }
] as KeyValue<FiscalCodeType, string>[];
