import { FormControl, FormGroup, Validators } from '@angular/forms';

export class ContractCheckViewModel {
  private _form: FormGroup;

  readonly C_CLIENT_NAME = 'clientName';
  readonly C_FISCAL_CODE = 'clientFiscalCode';
  readonly C_COMPANY_NAME = 'companyName';
  readonly C_COMPANY_ADDRESS = 'companyAddress';
  readonly C_COMPANY_FISCAL_CODE = 'companyFiscalCode';
  readonly C_COMPANY_ACTIVITY = 'companyActivity';
  readonly C_PRICE_NUMBER = 'priceNumber';
  readonly C_PRICE_LETTER = 'priceLetter';
  readonly C_CONTRACT_FILE = 'contractFile';
  readonly C_CONTRACT_NAME = 'contractName';

  constructor() {
    this._form = new FormGroup({
      [this.C_CLIENT_NAME]: new FormControl('', [Validators.required]),
      [this.C_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_NAME]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_ADDRESS]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_FISCAL_CODE]: new FormControl('', [Validators.required]),
      [this.C_COMPANY_ACTIVITY]: new FormControl('', [Validators.required]),
      [this.C_PRICE_NUMBER]: new FormControl('', []),
      [this.C_PRICE_LETTER]: new FormControl('', []),
      [this.C_CONTRACT_NAME]: new FormControl('', []),
      [this.C_CONTRACT_FILE]: new FormControl(null, [Validators.required])
    });
  }

  get form(): FormGroup {
    return this._form;
  }

  get clientName(): string | null | undefined {
    return this._form.get(this.C_CLIENT_NAME)?.value;
  }
  set clientName(value: string | null | undefined) {
    this._form.get(this.C_CLIENT_NAME)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this._form.get(this.C_FISCAL_CODE)?.value;
  }
  set fiscalCode(value: string | null | undefined) {
    this._form.get(this.C_FISCAL_CODE)?.setValue(value);
  }

  get companyName(): string | null | undefined {
    return this._form.get(this.C_COMPANY_NAME)?.value;
  }
  set companyName(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_NAME)?.setValue(value);
  }

  get companyAddress(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ADDRESS)?.value;
  }
  set companyAddress(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ADDRESS)?.setValue(value);
  }

  get companyFiscalCode(): string | null | undefined {
    return this._form.get(this.C_COMPANY_FISCAL_CODE)?.value;
  }
  set companyFiscalCode(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_FISCAL_CODE)?.setValue(value);
  }

  get companyActivity(): string | null | undefined {
    return this._form.get(this.C_COMPANY_ACTIVITY)?.value;
  }
  set companyActivity(value: string | null | undefined) {
    this._form.get(this.C_COMPANY_ACTIVITY)?.setValue(value);
  }

  get priceLetter(): string | null | undefined {
    return this._form.get(this.C_PRICE_LETTER)?.value;
  }
  set priceLetter(value: string | null | undefined) {
    this._form.get(this.C_PRICE_LETTER)?.setValue(value);
  }

  get priceNumber(): number | null | undefined {
    return this._form.get(this.C_PRICE_NUMBER)?.value;
  }
  set priceNumber(value: number | null | undefined) {
    this._form.get(this.C_PRICE_NUMBER)?.setValue(value);
  }

  get contractFile(): any | null | undefined {
    return this._form.get(this.C_CONTRACT_FILE)?.value;
  }
  set contractFile(value: any | null | undefined) {
    this._form.get(this.C_CONTRACT_FILE)?.setValue(value);
  }

  get contractName(): string | null | undefined {
    return this._form.get(this.C_CONTRACT_NAME)?.value;
  }
  set contractName(value: string | null | undefined) {
    this._form.get(this.C_CONTRACT_NAME)?.setValue(value);
  }
}
