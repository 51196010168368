import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { CreditOpinionSituation, FiscalCodeType } from 'src/app/core/enums';
import { CountryContract } from 'src/app/core/services/api/country/country.contract';
import { CreditOpinionContract } from 'src/app/core/services/api/credit-opinion';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreditOpinionEditReducedMapper } from '../mappers';
import { CreditOpinionEditReducedViewModel } from '../models';

@Component({
  selector: 'howden-credit-opinion-edit-dialog',
  templateUrl: './credit-opinion-edit-dialog.component.html',
  styleUrls: ['./credit-opinion-edit-dialog.component.scss']
})
export class CreditOpinionEditDialogComponent implements OnInit, OnDestroy {
  model = new CreditOpinionEditReducedViewModel();
  isNew = true;
  canSave = true;
  needCountry = false;

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<CreditOpinionEditReducedViewModel>,
    private uiBlockerSrv: UIBlockerContract,
    private creditOpinionSrv: CreditOpinionContract,
    private countrySrv: CountryContract
  ) { }

  ngOnInit(): void {
    if (this.creditOpinionId !== 'undefined' && this.creditOpinionId !== null) {
      this.isNew = false;
      this.uiBlockerSrv.block();
      forkJoin([
        this.creditOpinionSrv.getReduced(this.creditOpinionId),
        this.countrySrv.getCountries()
      ]).subscribe(([creditOpinion, countries]) => {
        CreditOpinionEditReducedMapper.mapForEdit(creditOpinion, this.model);
        this.model.countries = countries;
        this.onEdit();
        this.onFiscalCodeTypeChanges();
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.uiBlockerSrv.block();
      forkJoin([
        this.countrySrv.getCountries()
      ]).subscribe(([countries]) => {
        this.model.countries = countries;
        this.onFiscalCodeTypeChanges();
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    const request = CreditOpinionEditReducedMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.creditOpinionSrv.saveReduced(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private get creditOpinionId(): string | null {
    return this.inputData.id as string | null;
  }

  private onEdit() {
    this.model.form.get(this.model.C_SITUATION)?.disable();

    if (this.model.situation !== CreditOpinionSituation.Pending
      && this.model.situation !== CreditOpinionSituation.Rejected) {
      //Si está rechazada, el cliente podrá modificarla para corregir un posible dato erróneo
      this.model.form.get(this.model.C_COMPANY_ASSESS_FISCAL_CODE_TYPE)?.disable();
      this.model.form.get(this.model.C_COMPANY_ASSESS_FISCAL_CODE)?.disable();
      this.model.form.get(this.model.C_COMPANY_ASSESS_NAME)?.disable();
      this.model.form.get(this.model.C_REQUESTED_AMOUNT)?.disable();
      this.canSave = false;
    }
    this.adaptCountry(this.model.companyAssessFiscalCodeType);
  }

  private onFiscalCodeTypeChanges() {
    this._subscriptions.sink = this.model.form.get(this.model.C_COMPANY_ASSESS_FISCAL_CODE_TYPE)?.valueChanges.subscribe((value) => {
      this.adaptCountry(value);
    });
  }

  private adaptCountry(value: FiscalCodeType | null | undefined) {
    if (value === FiscalCodeType.Free) {
      if (this.canSave) {
        this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.enable();
      } else {
        this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.disable();
      }
      this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.setValidators([Validators.required]);
      this.needCountry = true;
    } else {
      this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.setValue(null);
      this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.disable();
      this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.setValidators([]);
      this.needCountry = false;
    }

    this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.updateValueAndValidity();
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
