import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { DebtorContract, DebtorService } from 'src/app/core/services/api/debtor';
import { MonitoringContract } from 'src/app/core/services/api/monitoring/monitoring.contract';
import { MonitoringService } from 'src/app/core/services/api/monitoring/monitoring.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { DebtorSearchComponent } from '../monitoring/debtor/pages/debtor-search/debtor-search.component';
import { DebtorCreditGrantedEditDialogComponent } from './debtor-credit-granted/dialogs/pages/debtor-credit-granted-edit-dialog/debtor-credit-granted-edit-dialog.component';
import { DebtorCreditGrantedSearchComponent } from './debtor-credit-granted/pages/debtor-credit-granted-search/debtor-credit-granted-search.component';
import { DebtorMonitoringComponent } from './debtor-monitoring/pages/debtor-monitoring.component';
import { DebtorEditDialogComponent } from './debtor/dialogs/debtor-edit-dialog/pages/debtor-edit-dialog.component';
import { MonitoringRoutingModule } from './monitoring-routing.module';

@NgModule({
  imports: [MonitoringRoutingModule, SharedModule],
  declarations: [
    DebtorEditDialogComponent,
    DebtorSearchComponent,
    DebtorMonitoringComponent,
    DebtorCreditGrantedSearchComponent,
    DebtorCreditGrantedEditDialogComponent
  ],
  providers: [
    { provide: DebtorContract, useClass: DebtorService },
    { provide: MonitoringContract, useClass: MonitoringService },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' }
  ]
})
export class MonitoringModule { }
