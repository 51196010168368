import { IRecruitmentPlan } from 'src/app/core/models/api/recruitment-plan';
import { RecruitmentPlanEditViewModel } from '../models';

export abstract class RecruitmentPlanEditMapper {
  static mapForEdit(source: IRecruitmentPlan, target: RecruitmentPlanEditViewModel): void {
    target.id = source.id;
    target.description = source.description;
    target.startDate = source.startDate;
    target.endDate = source.endDate;
    target.contractName = source.contractName;
    target.creditOpinionLimit = source.creditOpinionLimit;
    target.onlyCreditOpinion = source.onlyCreditOpinion;
    target.monitoringLimit = source.monitoringLimit;
    target.onlyMonitoring = source.onlyMonitoring;
    target.monthlyPrice = source.monthlyPrice;
    target.creditOpinionPrice = source.creditOpinionPrice;
    target.association = source.associationId;
    target.observations = source.observations;
    target.status = source.status;
  }
}
