import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IRecruitmentPlanFilter } from 'src/app/core/models/api/recruitment-plan';
import { IUserExtended } from 'src/app/core/models/api/user-data';
import { RecruitmentPlanContract } from 'src/app/core/services/api/recruitment-plan';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { UserDataMapper } from '../../mappers/user-data.mapper';
import { IUserDataViewModel, UserDataSearchViewModel } from '../../models';

@Component({
  selector: 'howden-user-data-search',
  templateUrl: './user-data-search.component.html',
  styleUrls: ['./user-data-search.component.scss']
})
export class UserDataSearchComponent implements OnInit, OnDestroy {
  model = new UserDataSearchViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private uiBlockerSrv: UIBlockerContract,
    private userDataSrv: UserDataContract,
    private recruitmentPlanSrv: RecruitmentPlanContract
  ) {
  }

  ngOnInit(): void {
    forkJoin([
      this.userDataSrv.getRoles(),
      this.recruitmentPlanSrv.search({} as IRecruitmentPlanFilter)
    ]).pipe(
      tap(([roles, recruitmentPlans]) => {
        this.model.roles = roles;
        this.model.recruitmentPlans = recruitmentPlans;
        this.refresh();
        this.onChanges();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onRowClicked(event: IUserDataViewModel): void {
    this.router.navigate(['management/user-data/edit', event.id]);
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }

  private refresh(): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.userDataSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IUserExtended>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) => UserDataMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }
}
