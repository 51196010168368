import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportIssuesComponent } from './pages/report-issues/report-issues.component';

export const SupportRoutes = [
  {
    path: 'support',
    title: $localize`:@@app.route.support:Soporte`,
    component: ReportIssuesComponent,
    data: {
      icon: 'support_agent',
      linkText: $localize`:@@app.route.support:Soporte`
    }
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(SupportRoutes)],
  exports: [RouterModule]
})
export class SupportRoutingModule { }
