<div class="flex f-fd--column">
  <div class="flex f-gap--10px">
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.recruitment-plan.search.description">Descripción</mat-label>
        <input  matInput
          [formControlName]="model.C_DESCRIPTION"
          [howdenControlError]="descriptionError"
          maxlength="50"
        />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.recruitment-plan.search.association">Asociación</mat-label>
        <mat-select
          [formControlName]="model.C_ASSOCIATION"
          [howdenControlError]="associationError"
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.associations | howdenArraySort : 'businessName'"
            [value]="item.userId"
          >
            {{ item.businessName + " [" + item.fiscalCode + "]" }}
          </mat-option>
        </mat-select>
        <mat-error #associationError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.recruitment-plan.search.observations">Observaciones</mat-label>
        <input matInput
          [formControlName]="model.C_OBSERVATIONS"
          [howdenControlError]="businessNameError"
          maxlength="60"
        />
        <mat-error #businessNameError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.recruitment-plan.search.status">Estado</mat-label>
        <mat-select [formControlName]="model.C_STATUS" [howdenControlError]="statusValuesError">
          <mat-option></mat-option>
          <mat-option
            *ngFor="let item of model.statusValues | howdenArraySort : 'value'"
            [value]="item.key"
          >
            {{ item.value }}
          </mat-option>
        </mat-select>
        <mat-error #statusValuesError></mat-error>
      </mat-form-field>
      <div class="f-basis--2 margin-top-20 margin-left-15">
        <button class="new-button" type="button" mat-raised-button color="primary" (click)="onNew()">
          <span i18n="@@app.recruitment-plan.search.new-recruitment-plan">Nuevo plan</span>
        </button>
      </div>
    </div>
  </form>
  <div>
    <howden-table
      class="small-font"
      keyField="id"
      paginable="local"
      sortable="local"
      filterable="server"
      [clickableRow]="true"
      [cols]="model.columns"
      [data]="model.data"
      [pageSize]="25"
      [buttons]="model.buttons"
      (rowClicked)="onRowClicked($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
