import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { CreditOpinionExerciseContract } from 'src/app/core/services/api/credit-opinion-exercise';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreditOpinionExerciseMapper } from '../../../mappers';
import { CreditOpinionExerciseDialogViewModel } from '../models';

@Component({
  selector: 'howden-credit-opinion-exercise-dialog',
  templateUrl: './credit-opinion-exercise-dialog.component.html',
  styleUrls: ['./credit-opinion-exercise-dialog.component.scss']
})
export class CreditOpinionExerciseDialogComponent implements OnInit, OnDestroy {
  model = new CreditOpinionExerciseDialogViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<CreditOpinionExerciseDialogViewModel>,
    private uiBlockerSrv: UIBlockerContract,
    private creditOpinionExerciseSrv: CreditOpinionExerciseContract
  ) { }

  ngOnInit(): void {
    if (this.creditOpinionExerciseId !== 'undefined' && this.creditOpinionExerciseId !== null) {
      this.uiBlockerSrv.block();
      forkJoin([
        this.creditOpinionExerciseSrv.get(this.creditOpinionExerciseId)
      ]).subscribe(([exercise]) => {
        CreditOpinionExerciseMapper.mapForEdit(exercise, this.model);
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.model.creditOpinionId = this.creditOpinionId;
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    const request = CreditOpinionExerciseMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.creditOpinionExerciseSrv.save(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private get creditOpinionExerciseId(): string | null {
    return this.inputData.id as string | null;
  }
  private get creditOpinionId(): number | null {
    return this.inputData.creditOpinionId as number | null;
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
