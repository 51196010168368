import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, HowdenAlertService, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { CountryContract } from 'src/app/core/services/api/country';
import { MonitoringContract } from 'src/app/core/services/api/monitoring';
import { LocalStorageContract } from 'src/app/core/services/storage';
import { UIBlockerContract } from 'src/app/core/services/ui';
import { Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { DebtorCreditGrantedEditMapper } from '../../mappers';
import { DebtorCreditGrantedEditViewModel } from '../../models';

@Component({
  selector: 'howden-debtor-credit-granted-edit-dialog',
  templateUrl: './debtor-credit-granted-edit-dialog.component.html',
  styleUrls: ['./debtor-credit-granted-edit-dialog.component.scss']
})
export class DebtorCreditGrantedEditDialogComponent implements OnInit, OnDestroy {
  model = new DebtorCreditGrantedEditViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<DebtorCreditGrantedEditViewModel>,
    private uiBlockerSrv: UIBlockerContract,
    private monitoringSrv: MonitoringContract,
    private countrySrv: CountryContract,
    private alertSrv: HowdenAlertService,
    private localStorageSrv: LocalStorageContract
  ) { }

  ngOnInit(): void {
    if (this.monitoringId !== 'undefined' && this.monitoringId !== null) {
      this.uiBlockerSrv.block();
      forkJoin([
        this.monitoringSrv.getDebtorCreditGranted(this.monitoringId),
        this.countrySrv.getCountries()
      ]).subscribe(([debtor, countries]) => {
        DebtorCreditGrantedEditMapper.mapForEdit(debtor, this.model);
        this.model.countries = countries;
        this.onEdit();
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept(): void {
    this.uiBlockerSrv.block();
    const request = DebtorCreditGrantedEditMapper.mapForUpdate(this.model);

    this.monitoringSrv
      .saveDebtorCreditGranted(request)
      .pipe(
        tap((response) => {
          const result = response.result as boolean;
          if (result) {
            const title: string = $localize`: @@app.debtor.save.result.ok.title:Éxito!`;
            const message: string = $localize`: @@app.debtor.save.result.ok.message:Crédito concedido guardado correctamente`;
            this.alertSrv.success(title, message);

            //Guardamos el deudor como actualizado
            this.localStorageSrv.addDebtorCreditGrantedUpdateId(this.model.debtorId as number);
          } else {
            const title: string = $localize`: @@app.debtor.save.result.warning.title:¡Advertencia!`;
            const message: string = $localize`: @@app.debtor.save.result.warning.message:Crédito concedido no guardado:`;
            this.alertSrv.warning(title, message);
          }
          this.uiBlockerSrv.unblock();
          this.closeDialog(DialogResultTypes.OK);
        })
      )
      .subscribe();
  }

  onCancel(): void {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private get monitoringId(): string | null {
    return this.inputData.id as string | null;
  }

  private onEdit() {
    this.model.form.get(this.model.C_FISCAL_CODE)?.disable();
    this.model.form.get(this.model.C_COUNTRY_ID)?.disable();
    this.model.form.get(this.model.C_BUSINESS_NAME)?.disable();
    this.model.form.get(this.model.C_REQUESTED_AMOUNT)?.disable();
    this.model.form.get(this.model.C_MONITORING_DATE)?.disable();
    this.model.form.get(this.model.C_LAST_GRANTED_AMOUNT)?.disable();
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
