import { ICreditOpinionAttachment } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionAttachmentDialogViewModel } from '../dialogs/credit-opinion-attach-dialog/models';
import { ICreditOpinionAttachmentViewModel } from '../models';

export abstract class CreditOpinionAttachmentMapper {
  static mapForEdit(source: ICreditOpinionAttachment, target: CreditOpinionAttachmentDialogViewModel): void {
    target.id = source.id;
    target.creditOpinionId = source.creditOpinionId;
    target.description = source.description;
    target.fileName = source.fileName;
  }

  static mapForSearch(source: Array<ICreditOpinionAttachment>): Array<ICreditOpinionAttachmentViewModel> {
    return source.map(t => ({
      id: t.id,
      creditOpinionId: t.creditOpinionId,
      description: t.description,
      fileName: t.fileName,
      uploadDate: t.uploadDate
    } as ICreditOpinionAttachmentViewModel));
  }
}
