import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IUserRole } from 'src/app/core/models/api/master';
import { IUserDataFilter, IUserDataReduced, IUserExtended, IUserRecruitmentPlanInfo } from 'src/app/core/models/api/user-data';

export abstract class UserDataContract {
  abstract getRoles(): Observable<Array<IUserRole>>;
  abstract getAssociations(): Observable<Array<IUserDataReduced>>;
  abstract getClients(onlyMonitorables: boolean): Observable<Array<IUserDataReduced>>;
  abstract save(request: FormData): Observable<IResponse<string>>;
  abstract search(request: IUserDataFilter): Observable<IPageOf<IUserExtended>>;
  abstract get(id: string): Observable<IUserExtended>;
  abstract getLoggedClientInfo(): Observable<IUserExtended>;
  abstract getRecruitmentPlanInfo(id: number): Observable<IUserRecruitmentPlanInfo>;
  abstract downloadSecondaryLogo(id: number): Observable<Blob>;
  abstract downloadReportLogo(id: number): Observable<Blob>;
}
