import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IUserRequest, IUserRequestFilter } from 'src/app/core/models/api/user-request';
import { UserRequestContract } from './user-request.contract';

@Injectable({
  providedIn: 'root'
})
export class UserRequestService implements UserRequestContract {
  apiName = API_PATH_APPS.API_CREDIT_PORTAL;
  basePath = 'api/user-request';
  constructor(private httpClient: HowdenHttpClientService) { }

  save(request: IUserRequest): Observable<IResponse<string>> {
    return this.httpClient.post<IResponse<string>>(this.apiName, this.basePath, request);
  }

  search(request: IUserRequestFilter): Observable<IPageOf<IUserRequest>> {
    return this.httpClient.post<IPageOf<IUserRequest>>(this.apiName, `${this.basePath}/search`, request);
  }

  get(id: string): Observable<IUserRequest> {
    return this.httpClient.get<IUserRequest>(this.apiName, `${this.basePath}/${id}`);
  }
}
