import { KeyValue } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DATA_STATUS_VALUES } from 'src/app/core/constants';
import { DataStatus } from 'src/app/core/enums';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { Limits } from 'src/app/shared/utils';
import { dateRangeValidator, endDateMonthMatchValidator, startDateMonthMatchValidator } from 'src/app/shared/validators';

export class RecruitmentPlanEditViewModel {
  private _form: FormGroup;
  private _statusValues: KeyValue<DataStatus, string>[] = DATA_STATUS_VALUES;
  private _associations: Array<IUserDataReduced> = [];

  readonly C_ID = 'id';
  readonly C_DESCRIPTION = 'description';
  readonly C_START_DATE = 'startDate';
  readonly C_END_DATE = 'endDate';
  readonly C_CONTRACT_NAME = 'contractName';
  readonly C_CONTRACT_FILE = 'contractFile';
  readonly C_CREDIT_OPINION_LIMIT = 'creditOpinionLimit';
  readonly C_ONLY_CREDIT_OPINION = 'onlyCreditOpinion';
  readonly C_MONITORING_LIMIT = 'monitoringLimit';
  readonly C_ONLY_MONITORING = 'onlyMonitoring';
  readonly C_MONTHLY_PRICE = 'monthlyPrice';
  readonly C_CREDIT_OPINION_PRICE = 'creditOpinionPrice';
  readonly C_ASSOCIATION = 'associationId';
  readonly C_OBSERVATIONS = 'observations';
  readonly C_STATUS = 'status';

  constructor() {
    //Como fecha de inicio ponemos el día 1 del mes siguiente
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() + 1);
    startDate.setDate(1);

    this._form = new FormGroup({
      [this.C_ID]: new FormControl(),
      [this.C_DESCRIPTION]: new FormControl('', [Validators.required]),
      [this.C_START_DATE]: new FormControl(startDate, [Validators.required]),
      [this.C_END_DATE]: new FormControl('', []),
      [this.C_CONTRACT_NAME]: new FormControl('', []),
      [this.C_CONTRACT_FILE]: new FormControl(null, []),
      [this.C_CREDIT_OPINION_LIMIT]: new FormControl(null, [Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxInt16)]),
      [this.C_ONLY_CREDIT_OPINION]: new FormControl(false),
      [this.C_MONITORING_LIMIT]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxInt16)]),
      [this.C_ONLY_MONITORING]: new FormControl(false),
      [this.C_MONTHLY_PRICE]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]),
      [this.C_CREDIT_OPINION_PRICE]: new FormControl(null, [Validators.required, Validators.min(Limits.greaterThanZero), Validators.max(Limits.maxDecimal)]),
      [this.C_ASSOCIATION]: new FormControl(null),
      [this.C_OBSERVATIONS]: new FormControl(''),
      [this.C_STATUS]: new FormControl(DataStatus.Active, [Validators.required])
    }, {
      validators: [
        startDateMonthMatchValidator(this.C_START_DATE),
        endDateMonthMatchValidator(this.C_END_DATE),
        dateRangeValidator(this.C_START_DATE, this.C_END_DATE)
      ]
    });
  }

  get statusValues(): KeyValue<DataStatus, string>[] {
    return this._statusValues;
  }

  get associations(): Array<IUserDataReduced> {
    return this._associations;
  }
  set associations(value: Array<IUserDataReduced>) {
    this._associations = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }
  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get description(): string | null | undefined {
    return this._form.get(this.C_DESCRIPTION)?.value;
  }
  set description(value: string | null | undefined) {
    this._form.get(this.C_DESCRIPTION)?.setValue(value);
  }

  get startDate(): Date | null | undefined {
    return this._form.get(this.C_START_DATE)?.value;
  }
  set startDate(value: Date | null | undefined) {
    this._form.get(this.C_START_DATE)?.setValue(value);
  }

  get endDate(): Date | null | undefined {
    return this._form.get(this.C_END_DATE)?.value;
  }
  set endDate(value: Date | null | undefined) {
    this._form.get(this.C_END_DATE)?.setValue(value);
  }

  get contractFile(): any | null | undefined {
    return this._form.get(this.C_CONTRACT_FILE)?.value;
  }
  set contractFile(value: any | null | undefined) {
    this._form.get(this.C_CONTRACT_FILE)?.setValue(value);
  }

  get contractName(): string | null | undefined {
    return this._form.get(this.C_CONTRACT_NAME)?.value;
  }
  set contractName(value: string | null | undefined) {
    this._form.get(this.C_CONTRACT_NAME)?.setValue(value);
  }


  get creditOpinionLimit(): number | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_LIMIT)?.value;
  }
  set creditOpinionLimit(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_LIMIT)?.setValue(value);
  }

  get onlyCreditOpinion(): boolean | null | undefined {
    return this._form.get(this.C_ONLY_CREDIT_OPINION)?.value;
  }
  set onlyCreditOpinion(value: boolean | null | undefined) {
    this._form.get(this.C_ONLY_CREDIT_OPINION)?.setValue(value);
  }

  get monitoringLimit(): number | null | undefined {
    return this._form.get(this.C_MONITORING_LIMIT)?.value;
  }
  set monitoringLimit(value: number | null | undefined) {
    this._form.get(this.C_MONITORING_LIMIT)?.setValue(value);
  }

  get onlyMonitoring(): boolean | null | undefined {
    return this._form.get(this.C_ONLY_MONITORING)?.value;
  }
  set onlyMonitoring(value: boolean | null | undefined) {
    this._form.get(this.C_ONLY_MONITORING)?.setValue(value);
  }

  get monthlyPrice(): number | null | undefined {
    return this._form.get(this.C_MONTHLY_PRICE)?.value;
  }
  set monthlyPrice(value: number | null | undefined) {
    this._form.get(this.C_MONTHLY_PRICE)?.setValue(value);
  }

  get creditOpinionPrice(): number | null | undefined {
    return this._form.get(this.C_CREDIT_OPINION_PRICE)?.value;
  }
  set creditOpinionPrice(value: number | null | undefined) {
    this._form.get(this.C_CREDIT_OPINION_PRICE)?.setValue(value);
  }

  get association(): number | null | undefined {
    return this._form.get(this.C_ASSOCIATION)?.value;
  }
  set association(value: number | null | undefined) {
    this._form.get(this.C_ASSOCIATION)?.setValue(value);
  }

  get observations(): string | null | undefined {
    return this._form.get(this.C_OBSERVATIONS)?.value;
  }
  set observations(value: string | null | undefined) {
    this._form.get(this.C_OBSERVATIONS)?.setValue(value);
  }

  get status(): DataStatus | null | undefined {
    return this._form.get(this.C_STATUS)?.value;
  }
  set status(value: DataStatus | null | undefined) {
    this._form.get(this.C_STATUS)?.setValue(value);
  }
}
