import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function startDateMonthMatchValidator(startDateFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (moment.isMoment(control.get(startDateFieldName)?.value)) {
      const startDate = moment(control.get(startDateFieldName)?.value).toDate();
      //La fecha de inicio no puede ser anterior a la actual
      const actualDate = new Date();
      actualDate.setHours(0, 0, 0, 0);
      if (startDate < actualDate) {
        return { startDateFuture: true };
      }

      //La fecha de inicio debe coincidir con el inicio de mes
      return startDate.getDate() === 1 ? null : { startDateInitMonth: true };
    }
    return null;
  };
}

export function endDateMonthMatchValidator(endDateFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (moment.isMoment(control.get(endDateFieldName)?.value)) {
      const endDate = moment(control.get(endDateFieldName)?.value).toDate();
      //La fecha de finalización no puede ser anterior a la actual
      const actualDate = new Date();
      actualDate.setHours(0, 0, 0, 0);
      if (endDate < actualDate) {
        return { endDateFuture: true };
      }

      //La fecha de finalización debe coincidir con fin de mes
      const lastDayMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);

      if (endDate.getDate() !== lastDayMonth.getDate()) {
        return { endDateEndMonth: true };
      }
    }
    return null;
  };
}


export function dateRangeValidator(startDateFieldName: string, endDateFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.get(endDateFieldName)?.value === '') {
      return null;
    }
    //La fecha de finalización no puede ser anterior a la de inicio
    const startDate = control.get(startDateFieldName)?.value as Date;
    const endDate = control.get(endDateFieldName)?.value as Date;

    return (endDate < startDate) ? { dateRange: true } : null;
  };
}
