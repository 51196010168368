<div class="flex f-fd--column">
  <div class="flex f-gap--10px">
    <div class="f-basis--12">
      <howden-page-title></howden-page-title>
    </div>
  </div>
  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <div class="f-basis--4">
        <howden-table
          #clientsTable
          class="small-font"
          keyField="userId"
          paginable="local"
          sortable="local"
          filterable="global-local"
          [pageSize]="10"
          [clickableRow]="true"
          [highlightRow]="true"
          [cols]="model.columnsClients"
          [data]="model.dataClients"
          (rowClicked)="onUserSelectChange($event)"
        >
        </howden-table>
      </div>
      <div class="f-basis--8">
        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--2">
            <mat-label i18n="@@app.debtor-credit-granted.search.fiscalCode">ID Fiscal</mat-label>
            <input matInput
              [formControlName]="model.C_FISCAL_CODE"
              [howdenControlError]="fiscalCodeCodeError"
              (input)="onFilterChanges()"
              maxlength="20"
            />
            <mat-error #fiscalCodeCodeError></mat-error>
          </mat-form-field>
          <mat-form-field class="f-basis--3">
            <mat-label i18n="@@app.debtor-credit-granted.search.businessName">Razón social</mat-label>
            <input matInput
              [formControlName]="model.C_BUSINESS_NAME"
              [howdenControlError]="businessNameError"
              (input)="onFilterChanges()"
              maxlength="60"
            />
            <mat-error #businessNameError></mat-error>
          </mat-form-field>
          <mat-form-field class="f-basis--2">
            <mat-label i18n="@@app.debtor-credit-granted.search.ownIdentifier">Identificador interno</mat-label>
            <input matInput
              [formControlName]="model.C_OWN_IDENTIFIER"
              [howdenControlError]="ownIdentifierError"
              (input)="onFilterChanges()"
              maxlength="50"
            />
            <mat-error #ownIdentifierError></mat-error>
          </mat-form-field>
          </div>
          <howden-table
            class="small-font"
            keyField="id"
            paginable="server"
            sortable="server"
            filterable="server"
            [clickableRow]="true"
            [cols]="model.columns"
            [data]="model.data"
            [currentPage]="model.searchRequest.pageNumber"
            [pageSize]="model.searchRequest.pageSize"
            [totalRows]="model.length"
            (rowClicked)="onRowClicked($event)"
            (pageChanged)="onServerSideConfigChanged($event)"
            (sortChanged)="onServerSideConfigChanged($event)"
          >
          </howden-table>
        </div>
    </div>
  </form>
</div>
