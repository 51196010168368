import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';

import { CreditOpinionExerciseContract } from 'src/app/core/services/api/credit-opinion-exercise';
import { ConfirmationDialogContract, EventEmitterContract, UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreditOpinionExerciseDialogComponent } from '../../dialogs/credit-opinion-exercise-dialog/pages/credit-opinion-exercise-dialog.component';
import { CreditOpinionExerciseMapper } from '../../mappers';
import { CreditOpinionEditExercisesViewModel, ICreditOpinionExerciseViewModel } from '../../models';

@Component({
  selector: 'howden-credit-opinion-exercises',
  templateUrl: './credit-opinion-exercises.component.html',
  styleUrls: ['./credit-opinion-exercises.component.scss']
})
export class CreditOpinionExercisesComponent implements OnInit, OnDestroy {
  model = new CreditOpinionEditExercisesViewModel();

  @Input() creditOpinionId: string | null | undefined = '';

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: UIBlockerContract,
    private confirmDialogSrv: ConfirmationDialogContract,
    private eventEmitterSrv: EventEmitterContract,
    private creditOpinionExerciseSrv: CreditOpinionExerciseContract
  ) {
  }

  ngOnInit() {
    //Nos suscribmos al evento que nos indica si la opinión es modificable o no
    this._subscriptions.sink = this.eventEmitterSrv.editableCreditOpinionEmit.subscribe((value) => {
      this.model.isEditable = value;
    });


    this.refresh();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onNew(): void {
    const inputData = { creditOpinionId: this.creditOpinionId, id: null };
    this.showEditDialog(inputData);
  }

  onRowClicked(event: ICreditOpinionExerciseViewModel): void {
    const inputData = { creditOpinionId: this.creditOpinionId, id: event.id };
    this.showEditDialog(inputData);
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'delete') {
      this.delete(event);
    }
  }

  private showEditDialog(inputData: any) {
    const dialogRef = this.dialog.open(CreditOpinionExerciseDialogComponent, {
      minWidth: '700px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(_ => this.refresh());
  }

  private refresh(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.creditOpinionExerciseSrv.search(this.creditOpinionId as string)
    ]).subscribe(([exercises]) => {
      this.model.data = CreditOpinionExerciseMapper.mapForSearch(exercises);
      if (this.model.data.length === 0) {
        this.model.form.get(this.model.C_LACK_EXERCISE_INFO)?.enable();
      } else {
        this.model.form.get(this.model.C_LACK_EXERCISE_INFO)?.disable();
      }

      this.uiBlockerSrv.unblock();
    });
  }

  private delete(event: IButtonActionData): void {
    const exerciseId = event.row.id;
    const title: string = $localize`: @@app.credit-opinion.edit.exercises.delete.title:Borrado de Ejercicio`;
    const question: string = $localize`: @@app.credit-opinion.edit.exercises.delete.subtitle: Se borrará el ejercicio, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`: @@app.credit-opinion.edit.exercises.delete.confirm.subtitle:Finalizado Correctamente`;

          this.uiBlockerSrv.block();
          this.creditOpinionExerciseSrv.delete(exerciseId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }
}
