import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IUserDataHistoryViewModel } from '.';


export class UserDataEditHistoryViewModel {
  data: IUserDataHistoryViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'startDate',
      fieldHeader: $localize`:@@app.user-data.edit.history.startDate.column:Fecha inicio`,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true,
      width: '12%'
    },
    {
      fieldName: 'endDate',
      fieldHeader: $localize`:@@app.user-data.edit.history.endDate.column:Fecha fin`,
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true,
      width: '12%'
    },
    {
      fieldName: 'recruitmentPlanDesc',
      fieldHeader: $localize`:@@app.user-data.edit.history.recruitmentPlan.column:Plan`,
      canSort: true,
      width: '30%'
    },
    {
      fieldName: 'monitoringPeriodicity',
      fieldHeader: $localize`:@@app.user-data.edit.history.monitoringPeriodicity.column:Periodo monitorización`,
      canSort: true,
      width: '15%'
    },
    {
      fieldName: 'creditOpinionPrice',
      fieldHeader: $localize`:@@app.user-data.edit.history.creditOpinionPrice.column:Precio opinión`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.CURRENCY,
      width: '15%'
    },
    {
      fieldName: 'isLow',
      fieldHeader: $localize`:@@app.user-data.edit.history.isLow.column:Es baja`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.YESNO,
      width: '10%'
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete_forever',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.user-data.edit.history.actions.delete.tooltip:Eliminar`,
    isDisabled: (row: any): boolean =>
      row.deleteIsDisabled === true
  }];
}
