<div class="flex f-fd--column">
   <div>
    <howden-page-title></howden-page-title>
  </div>
  <div class="flex f-gap--10px ">
    <div  class="f-basis--1">
      <h3 i18n="@@app.dashboard.credit-opinion-history.period">
        Período
      </h3>
    </div>
    <div class="f-basis--11 margin-top-15">
      <div class="dateRangeContainer" (click)="onPeriodChange(model.OneMonth_Period)" >
        <div class="dateRangeSelection" [class.active]="model.periodSelected === model.OneMonth_Period">
          <div class="dateRangeText"
            [title]="'Mostrar información último mes'"
            i18n-title="@@app.dashboard.credit-opinion-history.dateRange.lastMonth.title"
          >
            <span i18n="@@app.dashboard.credit-opinion-history.dateRange.lastMonth">1 M</span>
          </div>
        </div>
      </div>
      <div class="dateRangeContainer" (click)="onPeriodChange(model.SixMonths_Period)" >
        <div class="dateRangeSelection" [class.active]="model.periodSelected === model.SixMonths_Period">
          <div class="dateRangeText"
            [title]="'Mostrar información de los últimos 6 meses'"
            i18n-title="@@app.dashboard.credit-opinion-history.dateRange.lastSixMonth.title"
          >
            <span i18n="@@app.dashboard.credit-opinion-history.dateRange.lastSixMonth">6 M</span>
          </div>
        </div>
      </div>
      <div class="dateRangeContainer" (click)="onPeriodChange(model.OneYear_Period)" >
        <div class="dateRangeSelection" [class.active]="model.periodSelected === model.OneYear_Period">
          <div class="dateRangeText"
            [title]="'Mostrar información del último año'"
            i18n-title="@@app.dashboard.credit-opinion-history.dateRange.lastYear.title"
          >
            <span i18n="@@app.dashboard.credit-opinion-history.dateRange.lastYear">1 A</span>
          </div>
        </div>
      </div>
      <div class="dateRangeContainer" (click)="onPeriodChange(model.FiveYears_Period)" >
        <div class="dateRangeSelection" [class.active]="model.periodSelected === model.FiveYears_Period">
          <div class="dateRangeText"
            [title]="'Mostrar información de los últimos 5 años'"
            i18n-title="@@app.dashboard.credit-opinion-history.dateRange.lastFiveYear.title"
          >
            <span i18n="@@app.dashboard.credit-opinion-history.dateRange.lastFiveYear">5 A</span>
          </div>
        </div>
      </div>
      <div class="dateRangeContainer" (click)="onPeriodChange(model.Max_Period)" >
        <div class="dateRangeSelection" [class.active]="model.periodSelected === model.Max_Period">
          <div class="dateRangeText marginRight"
            [title]="'Mostrar toda la información'"
            i18n-title="@@app.dashboard.credit-opinion-history.dateRange.all.title"
          >
            <span i18n="@@app.dashboard.credit-opinion-history.dateRange.all">Máx</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex f-gap--10px ">
    <div class="f-basis--4">
      <howden-table
        #companiesTable
        class="small-font"
        paginable="local"
        sortable="local"
        filterable="global-local"
        [pageSize]="10"
        [clickableRow]="true"
        [highlightRow]="true"
        [cols]="model.dashboardColumns"
        [data]="model.dashboardData"
        (rowClicked)="onRowClicked($event)"
      >
      </howden-table>
    </div>

    <div class="f-basis--8">
      <div class="flex f-gap--10px ">
        <div  class="f-basis--12">
          <div class="flex f-jc--center">
            <span i18n="@@app.dashboard.credit-opinion-history.evolution">Evolución</span>
          </div>
          <div class="flex f-jc--center">
            <howden-label-value
              class="client-selected"
              value="{{companySelected}}"
            ></howden-label-value>
          </div>
        </div>
      </div>
      <div class="flex f-gap--10px ">
        <div class="f-basis--12 margin-top-15">
          <howden-dashboard-line-chart
            *ngIf="infoLoaded"
            [requestedAmount]="requestAmountByDateToDashboard"
            [grantedAmount]="grantedAmountByDateToDashboard"
          >
          </howden-dashboard-line-chart>
        </div>
      </div>
    </div>
  </div>
</div>
