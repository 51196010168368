import { EnvironmentInjector, NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from 'src/app/core/enums';
import { MonitoringOptionGuard } from 'src/app/core/guards/can-activate';
import { DebtorCreditGrantedCanDeactivateGuard } from 'src/app/core/guards/can-deactivate';
import { MenuHelperService } from 'src/app/core/helpers';
import { DebtorCreditGrantedSearchComponent } from './debtor-credit-granted/pages/debtor-credit-granted-search/debtor-credit-granted-search.component';
import { DebtorMonitoringComponent } from './debtor-monitoring/pages/debtor-monitoring.component';
import { DebtorSearchComponent } from './debtor/pages/debtor-search/debtor-search.component';

export const MonitoringRoutes = [
  {
    canActivate: [MonitoringOptionGuard],
    path: 'monitoring',
    title: $localize`:@@app.route.monitoring:Monitorización`,
    data: {
      isVisible: async function (ei: EnvironmentInjector) {
        return await ei.runInContext(async () => {
          const menuHelper = inject(MenuHelperService);
          const isVisible = await menuHelper.checkMonitoringOption();
          return isVisible;
        });
      },
      icon: 'show_chart',
      linkText: $localize`:@@app.route.monitoring:Monitorización`
    },
    children: [
      {
        path: 'debtor-search',
        component: DebtorSearchComponent,
        title: $localize`:@@app.route.monitoring.debtor.search.title:Mis deudores`,
        data: {
          linkText: $localize`:@@app.route.monitoring.debtor.search:Mis deudores`,
          roles: [UserRole.SuperAdmin, UserRole.Association, UserRole.AssociationMember, UserRole.IndependentClient]
        }
      },
      {
        path: 'debtor-credit-granted-search',
        component: DebtorCreditGrantedSearchComponent,
        canDeactivate: [DebtorCreditGrantedCanDeactivateGuard],
        title: $localize`:@@app.route.monitoring.debtor-credit-granted-search.search:Establecer Crédito`,
        data: {
          linkText: $localize`:@@app.route.monitoring.debtor-credit-granted-search.search:Establecer Crédito`,
          roles: [UserRole.SuperAdmin, UserRole.Administrator, UserRole.Evaluator]
        }
      },
      {
        path: 'debtor-monitoring',
        component: DebtorMonitoringComponent,
        title: $localize`:@@app.route.monitoring.register:Seguimiento`,
        data: {
          linkText: $localize`:@@app.route.monitoring.register:Seguimiento`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(MonitoringRoutes)],
  exports: [RouterModule]
})
export class MonitoringRoutingModule { }
