import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DialogResultTypes,
  HowdenAlertService, IDialogResult
} from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { CREDIT_OPINION_SITUATION_VALUES } from 'src/app/core/constants';
import { CreditOpinionSituation } from 'src/app/core/enums';
import { CommentContract } from 'src/app/core/services/api/comment';
import { CountryContract } from 'src/app/core/services/api/country';
import { CreditOpinionContract } from 'src/app/core/services/api/credit-opinion';
import { ConfirmationDialogContract, EventEmitterContract, UIBlockerContract } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { CreditOpinionMapper } from '../../mappers';
import { CreditOpinionEditViewModel } from '../../models';
import { CreditOpinionExercisesComponent } from '../credit-opinion-exercises/credit-opinion-exercises.component';
import { CommentType } from './../../../../core/enums/comment.enum';

@Component({
  selector: 'howden-credit-opinion-edit',
  templateUrl: './credit-opinion-edit.component.html',
  styleUrls: ['./credit-opinion-edit.component.scss']
})
export class CreditOpinionEditComponent implements OnInit, OnDestroy {
  model = new CreditOpinionEditViewModel();
  canUpdate = true;
  canReject = false;
  canReactivate = false;
  creditOpinionId = '';

  @ViewChild('exercises') exercisesComponent!: CreditOpinionExercisesComponent;

  private _subscriptions = new SubSink();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private uiBlockerSrv: UIBlockerContract,
    private eventEmitterSrv: EventEmitterContract,
    private confirmDialogSrv: ConfirmationDialogContract,
    private alertSrv: HowdenAlertService,
    private creditOpinionSrv: CreditOpinionContract,
    private commentSrv: CommentContract,
    private countrySrv: CountryContract
  ) {
  }

  ngOnInit(): void {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        this.creditOpinionId = paramMap.get('id') as string;

        this.uiBlockerSrv.block();

        forkJoin([
          this.commentSrv.getComments(CommentType.EvaluationActivity),
          this.commentSrv.getComments(CommentType.AnnualAccounts),
          this.commentSrv.getComments(CommentType.LongTermDebtEvaluation),
          this.commentSrv.getComments(CommentType.ShortTermDebtEvaluation),
          this.countrySrv.getCountries()
        ]).pipe(
          tap(([evaluationActivities, annualAccounts, longTermDebtEvaluations, shortTermDebtEvaluations, countries]) => {
            this.model.evaluationActivityValues = evaluationActivities;
            this.model.annualAccountsValues = annualAccounts;
            this.model.longTermDebtEvaluationValues = longTermDebtEvaluations;
            this.model.shortTermDebtEvaluationValues = shortTermDebtEvaluations;
            this.model.countries = countries;

            if (typeof this.creditOpinionId !== 'undefined' && this.creditOpinionId !== null) {
              this.creditOpinionSrv.get(this.creditOpinionId).subscribe(data => {
                CreditOpinionMapper.mapForEdit(data, this.model);
                this.onEdit(data.lackExerciseInfo);
                this.uiBlockerSrv.unblock();
              });
            } else {
              this.uiBlockerSrv.unblock();
              const title: string = $localize`: @@app.credit-opinion.edit.result.error.title:Error`;
              const message: string = $localize`: @@app.credit-opinion.edit.result.error.message:No se puede cargar la información de la solicitud, no existe id`;
              this.alertSrv.error(title, message);
            }
          })
        ).subscribe();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave() {
    const request = CreditOpinionMapper.mapForSave(this.model);
    request.lackExerciseInfo = this.exercisesComponent.model.lackExerciseInfo;

    this.uiBlockerSrv.block();

    this.creditOpinionSrv.save(request).subscribe((response) => {
      const result = response.result as boolean;
      if (result) {
        const title: string = $localize`: @@app.credit-opinion.save.result.ok.title:Éxito!`;
        const message: string = $localize`: @@app.credit-opinion.save.result.ok.message:Opinión de crédito guardada correctamente`;
        this.alertSrv.success(title, message);

        this.uiBlockerSrv.unblock();

        this.goToSearch();
      }
    });
  }

  onReject() {
    const title: string = $localize`: @@app.credit-opinion.edit.reject.title:Rechazar Opinión de Crédito `;
    const question: string = $localize`: @@app.credit-opinion.edit.reject.subtitle: Se rechazará la solicitud de opinión de crédito, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`: @@app.credit-opinion.edit.reject.confirm.subtitle:Rechazada Correctamente`;

          this.uiBlockerSrv.block();

          this.creditOpinionSrv.reject(this.creditOpinionId).pipe(
            tap((data) => {
              this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                CreditOpinionMapper.mapForEdit(data, this.model);
                this.onEdit(data.lackExerciseInfo);
                this.uiBlockerSrv.unblock();
              });
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  onReactivate() {
    const title: string = $localize`: @@app.credit-opinion.edit.reactivate.title:Reactivar Opinión de Crédito `;
    const question: string = $localize`: @@app.credit-opinion.edit.reactivate.subtitle: Se reactivará la solicitud de opinión de crédito, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openWarning(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`: @@app.credit-opinion.edit.reactivate.confirm.subtitle:Activada Correctamente`;

          this.uiBlockerSrv.block();

          this.creditOpinionSrv.reactivate(this.creditOpinionId).pipe(
            tap((data) => {
              this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                CreditOpinionMapper.mapForEdit(data, this.model);
                this.onEdit(data.lackExerciseInfo);
                this.uiBlockerSrv.unblock();
              });
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  goToSearch() {
    this.router.navigate(['credit-opinion']);
  }

  get hasExerciseInfo() {
    if (this.exercisesComponent.model.lackExerciseInfo) {
      return true;
    } else if (this.exercisesComponent.model.data.length > 0) {
      return true;
    }
    return false;
  }

  private onEdit(lackExerciseInfo: boolean | null | undefined) {
    this.exercisesComponent.model.lackExerciseInfo = lackExerciseInfo;

    this.model.form.get(this.model.C_USER_REQUEST_BUSSINESS_NAME)?.disable();
    this.model.form.get(this.model.C_USER_REQUEST_EMAIL)?.disable();
    this.model.form.get(this.model.C_USER_REQUEST_CONTACT)?.disable();
    this.model.form.get(this.model.C_USER_REQUEST_ASSOCIATION)?.disable();
    this.model.form.get(this.model.C_USER_RESPONSE_NAME_SURNAME)?.disable();
    this.model.form.get(this.model.C_USER_RESPONSE_EMAIL)?.disable();

    this.model.form.get(this.model.C_COMPANY_ASSESS_FISCAL_CODE_TYPE)?.disable();
    this.model.form.get(this.model.C_COMPANY_ASSESS_FISCAL_CODE)?.disable();
    this.model.form.get(this.model.C_COMPANY_ASSESS_COUNTRY_ID)?.disable();

    this.model.form.get(this.model.C_LAST_GRANTED_AMOUNT)?.disable();

    if (this.model.situation === CreditOpinionSituation.Pending) {
      //Actualizamos la edición para los componentes hijos
      this.eventEmitterSrv.setEditableCreditOpinion(true);

      this.canUpdate = true;
      this.canReject = true;
      this.canReactivate = false;
      //La opinión sólo se podrá cambiar a estado respondido
      this.model.statusValues = CREDIT_OPINION_SITUATION_VALUES
        .filter(x => x.key === CreditOpinionSituation.Pending
          || x.key === CreditOpinionSituation.Answered);

      this.model.form.get(this.model.C_COMPANY_ASSESS_NAME)?.enable();
      this.model.form.get(this.model.C_REQUESTED_AMOUNT)?.enable();
      this.model.form.get(this.model.C_GRANTED_AMOUNT)?.enable();
      this.model.form.get(this.model.C_COMPANY_ASSESS_CONST_YEAR)?.enable();
      this.model.form.get(this.model.C_COMPANY_ASSESS_ADDRESS)?.enable();
      this.model.form.get(this.model.C_CREDIT_LIMIT)?.enable();
      this.model.form.get(this.model.C_RAI)?.enable();
      this.model.form.get(this.model.C_UNPAID_BILLS)?.enable();
      this.model.form.get(this.model.C_JUDICIAL_INCIDENTS)?.enable();
      this.model.form.get(this.model.C_CONCURSAL_PROCEDURES)?.enable();
      this.model.form.get(this.model.C_ADMINISTRATIVE_CLAIMS)?.enable();
      this.model.form.get(this.model.C_OBSERVATIONS)?.enable();
      this.model.form.get(this.model.C_EVALUATION_ACTIVITY)?.enable();
      this.model.form.get(this.model.C_ANNUAL_ACCOUNTS)?.enable();
      this.model.form.get(this.model.C_LONG_TERM_DEBT_EVALUATION)?.enable();
      this.model.form.get(this.model.C_SHORT_TERM_DEBT_EVALUATION)?.enable();
      this.model.form.get(this.model.C_GRADE)?.enable();
      this.model.form.get(this.model.C_SITUATION)?.enable();
    } else {
      //Actualizamos la edición para los componentes hijos
      this.eventEmitterSrv.setEditableCreditOpinion(false);

      if (this.model.situation === CreditOpinionSituation.Rejected) {
        this.canReactivate = true;
        this.canReject = false;
      }
      this.model.statusValues = CREDIT_OPINION_SITUATION_VALUES;
      //Si la opinión está respondida, el cliente ya puede descargar el pdf,
      //por lo tanto en respondida tampoco se puede modificar
      this.model.form.get(this.model.C_COMPANY_ASSESS_NAME)?.disable();
      this.model.form.get(this.model.C_REQUESTED_AMOUNT)?.disable();
      this.model.form.get(this.model.C_GRANTED_AMOUNT)?.disable();
      this.model.form.get(this.model.C_COMPANY_ASSESS_CONST_YEAR)?.disable();
      this.model.form.get(this.model.C_COMPANY_ASSESS_ADDRESS)?.disable();
      this.model.form.get(this.model.C_CREDIT_LIMIT)?.disable();
      this.model.form.get(this.model.C_RAI)?.disable();
      this.model.form.get(this.model.C_UNPAID_BILLS)?.disable();
      this.model.form.get(this.model.C_JUDICIAL_INCIDENTS)?.disable();
      this.model.form.get(this.model.C_CONCURSAL_PROCEDURES)?.disable();
      this.model.form.get(this.model.C_ADMINISTRATIVE_CLAIMS)?.disable();
      this.model.form.get(this.model.C_OBSERVATIONS)?.disable();
      this.model.form.get(this.model.C_EVALUATION_ACTIVITY)?.disable();
      this.model.form.get(this.model.C_ANNUAL_ACCOUNTS)?.disable();
      this.model.form.get(this.model.C_LONG_TERM_DEBT_EVALUATION)?.disable();
      this.model.form.get(this.model.C_SHORT_TERM_DEBT_EVALUATION)?.disable();
      this.model.form.get(this.model.C_GRADE)?.disable();
      this.model.form.get(this.model.C_SITUATION)?.disable();

      this.canUpdate = false;
    }
  }
}
